import { useHistory } from "react-router-dom";
import { Segment } from "semantic-ui-react";

export function ClickableSegment({ url, onClick, children }) {
    const history = useHistory();

    return (
        <Segment
            onClick={() => (onClick ? onClick() : history.push(url))}
            style={onClick ? { cursor: "pointer" } : null}>
            {children}
        </Segment>
    );
}
