import React, { createContext, memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Card, CardContent, Grid, GridColumn, GridRow } from "semantic-ui-react";

export function Chat({ messages, autoScroll, children, className }) {
    const messagesEndRef = useRef(null);

    useEffect(() => {
        console.log("Chat: new messages", messages, autoScroll, messagesEndRef.current);
        if (autoScroll === true) {
            console.log("Scrolling chat", messagesEndRef.current);
            setTimeout(function () {
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 200);
        }
    }, [autoScroll, messages, messagesEndRef]);

    return (
        <Grid columns={1} style={{ paddingTop: "0", margin: "0 -0.5rem" }}>
            {children}
            <GridRow style={{ maxHeight: 0, padding: 0 }}>
                <GridColumn>
                    <div id="chat-end" ref={messagesEndRef} />
                </GridColumn>
            </GridRow>
        </Grid>
    );
}

export function ChatInput(props) {
    return createPortal(
        <div id="bottombar" className={props.className}>
            {props.children}
        </div>,
        document.getElementById("content-wrapper")
    );
}

export function ChatRowCenterInfo(props) {
    return (
        <GridRow>
            <GridColumn style={{}} textAlign="center">
                {props.children}
            </GridColumn>
        </GridRow>
    );
}

export function ChatRowLeft(props) {
    return (
        <GridRow className={props.className ? props.className + " chat-bubble" : "chat-bubble"}>
            <GridColumn style={{ paddingLeft: "0.5rem" }}>
                <span style={{ display: "flex" }} ref={props.rowRef}>
                    {props.children}
                </span>
            </GridColumn>
        </GridRow>
    );
}

export function ChatRowRight(props) {
    return (
        <GridRow className={props.className ? props.className + " chat-bubble" : "chat-bubble"}>
            <GridColumn style={{ paddingRight: "0.5rem" }}>
                <span style={{ display: "flex", justifyContent: "flex-end" }}>{props.children}</span>
            </GridColumn>
        </GridRow>
    );
}

export function ChatIconLeft(props) {
    return <div className="chat-icon-left">{props.children}</div>;
}

export function ChatMessageLeft(props) {
    return (
        <Card style={{ marginTop: 0, marginBottom: 0 }} className={"chat-message-left"}>
            <CardContent>{props.children}</CardContent>
        </Card>
    );
}
export function ChatIconRight(props) {
    return <div className="chat-icon-right">{props.children}</div>;
}

export function ChatMessageRight(props) {
    return (
        <Card style={{ marginTop: 0, marginBottom: 0 }} className={"chat-message-right"}>
            <CardContent>{props.children}</CardContent>
        </Card>
    );
}

export function ChatWaitingIndiciator() {
    return <div className="dot-flashing"></div>;
}

export const AdvancingChatContext = createContext(null);

export const AdvancingChat = memo(
    ({
        chatId,
        messages,
        setAllMessagesLoaded,
        render,
        loading,
        useArtificialDelay,
        setCurrentMessageIndex,
        readOnly,
    }) => {
        const INITIAL_MESSAGE_STATE = {
            currentMessage: null,
            loadedMessages: [],
            loadedMessageIndex: -1,
            typing: false,
        };
        const [messageState, setMessageState] = useState(INITIAL_MESSAGE_STATE);
        const lastLoadTime = useRef(null);

        // A new chat ID means a new chat has been loaded
        useEffect(() => {
            console.log("AdvancingChat: resetting message state");
            setMessageState(INITIAL_MESSAGE_STATE);
            if (setAllMessagesLoaded) {
                setAllMessagesLoaded(false);
            }
            lastLoadTime.current = null;
        }, [chatId]);

        useEffect(() => {
            if (!messages || messages.length === 0) {
                return;
            }

            // First we need to figure out where to resume queueing messages
            console.log("AdvancingChat: new messages on queue", messages);
            let startIndex = messageState.loadedMessageIndex + 1;

            if (startIndex >= messages.length || readOnly) {
                console.log("AdvancingChat: messages shrank. Adjusting.");
                setMessageState({
                    loadedMessageIndex: messages.length - 1,
                    currentMessage: messages[messages.length - 1],
                    loadedMessages: messages,
                    typing: false,
                });
                return;
            }

            // Find the last message from the user
            // if the user responded already, let's start after that
            if (messageState.loadedMessages.length === 0) {
                let last_user_message = messages.length - 1;
                for (; last_user_message >= 0; last_user_message--) {
                    if (messages[last_user_message].from_user) {
                        startIndex = last_user_message + 1;
                        break;
                    }
                }
            }

            // play the messages starting at startIndex
            advanceLoadedMessageIndex(startIndex);
        }, [messages]);

        useEffect(() => {
            const loadedMessageIndex = messageState.loadedMessageIndex;
            console.log("AdvancingChat: on loadMessageIdx change", messageState);
            setCurrentMessageIndex(loadedMessageIndex);

            // nothing to load yet
            if (loadedMessageIndex === -1 || messages[loadedMessageIndex] === messageState.currentMessage) {
                return;
            }

            // let's set the new current message
            let newCurrentMessage = messages[loadedMessageIndex];
            lastLoadTime.current = new Date().getTime();

            // mark it as loaded
            let newLoadedMessages = messages.slice(0, loadedMessageIndex + 1);
            setMessageState((prevState) => ({
                ...prevState,
                currentMessage: newCurrentMessage,
                loadedMessages: newLoadedMessages,
                typing: loadedMessageIndex < messages.length - 1,
            }));

            // we've loaded everything
            if (loadedMessageIndex >= messages.length - 1) {
                if (setAllMessagesLoaded) {
                    setAllMessagesLoaded(true);
                }
            } else {
                // Queue the next message
                queueNextAdvancement(loadedMessageIndex + 1);
            }
        }, [messageState.loadedMessageIndex]);

        function queueNextAdvancement(loadedMessageIndex) {
            console.log(
                "AdvancingChat: queueNextAdvancement. loadedMessageIndex",
                loadedMessageIndex,
                lastLoadTime.current
            );
            if (loadedMessageIndex >= messages.length) {
                console.log("AdvancingChat: loadedMessageIndex got too high.", loadedMessageIndex);
                return;
            }

            let delay = 1500;
            let timeSinceLastMessage = new Date().getTime() - lastLoadTime.current;

            console.log(
                "AdvancingChat: queueNextAdvancement. time since last message",
                timeSinceLastMessage,
                loadedMessageIndex
            );

            if (
                (loadedMessageIndex + 1 < messages.length && messages[loadedMessageIndex].from_user) ||
                timeSinceLastMessage > 500 ||
                !useArtificialDelay
            ) {
                console.log(
                    "AdvancingChat: queueNextAdvancement: advance without delay",
                    timeSinceLastMessage,
                    loadedMessageIndex
                );
                advanceLoadedMessageIndex(loadedMessageIndex);

                return;
            } else if (lastLoadTime.current && timeSinceLastMessage < 250) {
                delay = 1500;
            }

            setMessageState((prevState) => ({ ...prevState, typing: true }));
            if (setAllMessagesLoaded) {
                setAllMessagesLoaded(false);
            }
            console.log("PromptChat: queued next message index", loadedMessageIndex);
            setTimeout(() => {
                console.log("PromptChat: queueing next message index", loadedMessageIndex);
                advanceLoadedMessageIndex(loadedMessageIndex);
            }, delay);
        }

        function advanceLoadedMessageIndex(loadedMessageIndex) {
            console.log("AdvancingChat: advancing message index", loadedMessageIndex);
            setMessageState((prevState) => ({ ...prevState, loadedMessageIndex: loadedMessageIndex }));
        }

        useEffect(() => {
            console.log("AdvancingChat: message state ", messageState);
        }, [messageState]);

        return (
            <AdvancingChatContext.Provider value={messageState}>
                {messages.map((message, idx) => (
                    <React.Fragment key={"message-wrapper-" + idx}>
                        {idx <= messageState.loadedMessageIndex && render(message, idx, messages)}
                    </React.Fragment>
                ))}

                {(messageState.typing || loading || messages[messages.length - 1].tag === "task") && !readOnly && (
                    <ChatRowLeft key={"typing"} className="chat-bubble-last">
                        <ChatMessageLeft>
                            <ChatWaitingIndiciator />
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}
            </AdvancingChatContext.Provider>
        );
    }
);
