import { Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Checkbox, Segment } from "semantic-ui-react";
import CharacterSelector from "../components/CharacterSelector";
import { Column } from "../components/Flex";
import LoadableButton from "../components/LoadableButton";
import useAPI from "../hooks/useAPI";
import { strings } from "../utils/i18n.utils";
import { useLanguage } from "../hooks/useLanguage";

const ActivitySettings = ({ activity }) => {
    const [autoSelectExerciseTypes, setAutoSelectExerciseTypes] = useState(activity.enabled_exercise_types === null);
    const { nativeLanguage } = useLanguage();

    const [updateActivityAPI, callUpdateActivityAPI] = useAPI({ loading: false }); // Inside your component
    const [hasMounted, setHasMounted] = useState(false);

    // Effect to set hasMounted to true after the component mounts
    useEffect(() => {
        setHasMounted(true);
    }, []);

    function updateSupportedType(type, enabled) {
        if (activity.enabled_exercise_types === null) {
            activity.enabled_exercise_types = [];
        }

        if (enabled) {
            activity.enabled_exercise_types.push(type);
        } else {
            activity.enabled_exercise_types = activity.enabled_exercise_types.filter((t) => t !== type);
        }

        if (activity.enabled_exercise_types.length === 0) {
            activity.enabled_exercise_types = null;
        }

        callUpdateActivityAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
            enabled_exercise_types: activity.enabled_exercise_types,
            auto_select_exercise_types: autoSelectExerciseTypes,
        });
    }

    function updateEnableNativeExplanations(enabled) {
        activity.enable_native_explanations = enabled;

        callUpdateActivityAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
            enable_native_explanations: activity.enable_native_explanations,
        });
    }

    function updateCharacterId(characterId) {
        activity.character_id = characterId;
        callUpdateActivityAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
            character_id: characterId,
        });
    }

    useEffect(() => {
        if (hasMounted) {
            callUpdateActivityAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
                enabled_exercise_types: autoSelectExerciseTypes ? null : activity.enabled_exercise_types,
                auto_select_exercise_types: autoSelectExerciseTypes,
            });
        }
    }, [autoSelectExerciseTypes]);

    function deleteData() {
        callUpdateActivityAPI("DELETE", `/api/activities/${activity.id}/data`);
    }

    const exerciseTypes = [
        ["multiple_choice", strings.exercise_type_multiple_choice],
        ["cloze", strings.exercise_type_cloze],
        ["transcribe", strings.exercise_type_transcribe],
        ["question_mc", strings.exercise_type_question_multiple_choice],
        ["question", strings.exercise_type_question],
    ];

    return (
        <Segment>
            {!["role_play", "review", "talk"].includes(activity.type) && activity.character && (
                <p>
                    {strings.discussion_partner}:{" "}
                    <CharacterSelector selectedCharacterId={activity.character_id} setCharacterId={updateCharacterId} />
                </p>
            )}
            <Column>
                <Checkbox
                    toggle
                    label={"Auto select exercise types based on level"}
                    checked={autoSelectExerciseTypes}
                    onChange={(e, { checked }) => setAutoSelectExerciseTypes(checked)}
                />
                {autoSelectExerciseTypes === false &&
                    exerciseTypes.map((value, index) => (
                        <Checkbox
                            toggle
                            label={value[1]}
                            checked={activity.enabled_exercise_types?.includes(value[0])}
                            onChange={(e, { checked }) => updateSupportedType(value[0], checked)}
                        />
                    ))}
                <Checkbox
                    toggle
                    label={"Explanation in native language"}
                    checked={activity.enable_native_explanations}
                    onChange={(e, { checked }) => updateEnableNativeExplanations(checked)}
                />
                <LoadableButton
                    content={strings.activity_delete_data}
                    icon={<Trash />}
                    onClick={deleteData}
                    loading={updateActivityAPI.loading}
                />
            </Column>
        </Segment>
    );
};
export default ActivitySettings;
