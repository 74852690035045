import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { ClickableIcon } from "./ClickableIcon";
import { strings } from "../utils/i18n.utils";
import { useAudio } from "../hooks/useAudio";
import { Play, Stop } from "@phosphor-icons/react";

export function AudioPlayButton({
    url,
    autoPlay,
    setPlayingAudio,
    buttonStyle = "flat",
    size,
    includeText = false,
    slowPlay = false,
}) {
    const { isPlaying, queueAudioToPlay, stop, playingAudioUrl } = useAudio();
    const [internalIsPlaying, setInternalIsPlaying] = useState(false);

    useEffect(() => {
        if (autoPlay === true) {
            queueAudioToPlay(url);
        }
    }, [autoPlay]);

    useEffect(() => {
        if (playingAudioUrl === url) {
            setInternalIsPlaying(isPlaying);
            setPlayingAudio && setPlayingAudio(isPlaying);
        }
    }, [isPlaying]);

    function internalPlay() {
        queueAudioToPlay(url, true, slowPlay ? 0.5 : 1.0);
    }

    function internalStop() {
        stop();
    }

    return (
        <>
            {buttonStyle === "button" && (
                <Button
                    compact
                    size={size}
                    onClick={() => (internalIsPlaying ? internalStop() : internalPlay())}
                    icon={internalIsPlaying ? <Stop /> : <Play />}
                    content={
                        includeText
                            ? internalIsPlaying
                                ? strings.phrase_stop
                                : slowPlay
                                ? strings.phrase_play_slow
                                : strings.phrase_play
                            : null
                    }
                />
            )}

            {buttonStyle === "flat" && (
                <span className="phrase-button" onClick={() => (internalIsPlaying ? internalStop() : internalPlay())}>
                    <ClickableIcon icon={internalIsPlaying ? <Stop /> : <Play />} />{" "}
                    {internalIsPlaying
                        ? strings.phrase_stop
                        : slowPlay
                        ? strings.phrase_play_slow
                        : strings.phrase_play}
                </span>
            )}
        </>
    );
}
