import { Container, Message } from "semantic-ui-react";
import useNotifications from "../hooks/useNotifications";

export function Notifications() {
    const { notifications, removeNotification } = useNotifications();

    return (
        <Container className="notifications">
            {notifications &&
                [...notifications.keys()].map((id) => (
                    <div className="message-wrapper">
                        <Message
                            onDismiss={notifications.get(id).header === null ? null : () => removeNotification(id)}>
                            {notifications.get(id).header && (
                                <Message.Header>{notifications.get(id).header}</Message.Header>
                            )}
                            <Message.Content>{notifications.get(id).message}</Message.Content>
                        </Message>
                    </div>
                ))}
        </Container>
    );
}
