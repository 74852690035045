import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAPI from "../hooks/useAPI";
import { PromptChat } from "./Prompt";
import { ZataLoader } from "./ZataLoader";
import { Chat } from "./Chat";
import useTitle from "../hooks/useTitle";
import { Button, Segment } from "semantic-ui-react";
import { ContentWithRight } from "./Flex";
import { Activity } from "./ActivityDetails";
import { useQuery } from "../hooks/useQuery";

export const ActivitySessionEvaluation = ({ activitySession }) => {
    const [evaluationAPI, callEvaluationAPI] = useAPI({ loading: false });

    const [evaluation, setEvaluation] = useState(activitySession.evaluation);

    function loadEvaluation() {
        callEvaluationAPI("GET", `/api/activity_sessions/${activitySession.id}/evaluation?regenerate=true`);
    }

    useEffect(() => {
        if (evaluationAPI.response) {
            setEvaluation(evaluationAPI.response);
        }
    }, [evaluationAPI.response]);

    return (
        <Segment>
            {evaluation && (
                <ContentWithRight right={evaluation && <div className="StatisticNumber">{evaluation.score} / 10</div>}>
                    {evaluation.summary}
                </ContentWithRight>
            )}

            <Button onClick={loadEvaluation} content="Evaluate" disabled={evaluationAPI.loading} />
        </Segment>
    );
};

export const ActivityRecommendations = ({ activitySession }) => {
    const [recommendationsAPI, callRecommendationsAPI] = useAPI();

    function loadRecommendations(regenerate) {
        callRecommendationsAPI(
            "GET",
            `/api/activity_sessions/${activitySession.id}/recommendations?regenerate=${regenerate}`
        );
    }

    useEffect(() => {
        loadRecommendations(false);
    }, []);

    function ignoreRecommendation(recommendation) {
        recommendationsAPI.setResponse(recommendationsAPI.response.filter((r) => r.id !== recommendation.id));
    }

    return (
        <>
            {recommendationsAPI.response &&
                recommendationsAPI.response.map((recommendation) => (
                    <Activity activity={recommendation} key={recommendation.id} onArchive={ignoreRecommendation} />
                ))}

            <Button
                onClick={() => loadRecommendations(true)}
                content="Recommend"
                disabled={recommendationsAPI.loading}
            />
        </>
    );
};

export const ActivitySession = ({}) => {
    const { activitySessionId } = useParams();
    const [activitySessionAPI, callActivitySessionAPI] = useAPI();

    const { setNavigationTitle } = useTitle();
    const query = useQuery();

    useEffect(() => {
        callActivitySessionAPI("GET", `/api/activity_sessions/${activitySessionId}`);
    }, [activitySessionId]);

    useEffect(() => {
        if (activitySessionAPI.response) {
            const activity = activitySessionAPI.response.activity;

            setNavigationTitle(activity.name_native ? activity.name_native : activity.name);
        }
    }, [activitySessionAPI.response]);

    return (
        <div>
            {activitySessionAPI.loading && <ZataLoader />}

            {!activitySessionAPI.loading && activitySessionAPI.response && (
                <>
                    <Chat autoScroll={true} messages={[1]}>
                        {activitySessionAPI.response.prompts.map((prompt) => (
                            <PromptChat
                                prompt={prompt}
                                activity={activitySessionAPI.response.activity}
                                messages={prompt.messages}
                                promptContext={{
                                    unknownWordIds: [],
                                }}
                                setPromptContext={() => null}
                                key={prompt.id}
                                readOnly={true}
                                loading={false}
                                showDiffs={false}
                                autoPlayAudio={false}
                                completed={true}
                                inFocus={false}
                                transcribing={false}
                                scrollToEnd={() => null}
                                sendCommandAPI={() => null}
                            />
                        ))}
                    </Chat>

                    {query.has("edit") && (
                        <>
                            <ActivitySessionEvaluation activitySession={activitySessionAPI.response} />
                            <ActivityRecommendations activitySession={activitySessionAPI.response} />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
