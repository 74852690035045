import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Flashcard } from "../components/ActivityDetails";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import Phrase from "../components/Phrase";
import { Label, Segment } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import SkillStrength from "../components/SkillStrength";

const Skill = () => {
    const { skillId } = useParams();
    const [skillAPI, callSkillAPI] = useAPI();
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        callSkillAPI("GET", `/api/skills/${skillId}?native_language=${nativeLanguage}`);
    }, []);

    if (skillAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <div>
            <h1>{skillAPI.response?.name_native}</h1>
            <Segment>
                {skillAPI.response.score !== null && (
                    <Label size="tiny" basic className="skill-strength-label">
                        {skillAPI.response.score !== null && (
                            <SkillStrength strength={(5 * skillAPI.response.score) / 100} numOfBars={5} />
                        )}
                        {skillAPI.response.score >= 75 && strings.skill_strength_strong}
                        {skillAPI.response.score >= 25 && skillAPI.response.score < 75 && strings.skill_strength_fair}
                        {skillAPI.response.score !== null &&
                            skillAPI.response.score >= 0 &&
                            skillAPI.response.score < 25 &&
                            strings.skill_strength_weak}
                        {skillAPI.response.score === null && "Unknown"}
                    </Label>
                )}

                <h2>{strings.vocabulary}</h2>
                {skillAPI.response.vocab?.map((phrase, index) => (
                    <>
                        <Phrase phrase={phrase} translationLanguage={nativeLanguage} inline={true} />
                        {index < skillAPI.response.vocab.length - 1 ? ", " : ""}
                    </>
                ))}
            </Segment>

            {skillAPI.response?.flashcards?.map((flashcard) => (
                <Flashcard flashcard={flashcard} phrase={flashcard.phrase} />
            ))}
            {skillAPI.response?.flashcards.length === 0 && <Segment>No exercises found</Segment>}
        </div>
    );
};

export default Skill;
