import { Button, Segment } from "semantic-ui-react";
import { Row, StackableRow } from "./Flex";
import { Trash } from "@phosphor-icons/react";
import { currentLocation } from "../utils/url.utils";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { strings } from "../utils/i18n.utils";
import LoadableButton from "./LoadableButton";
import useAPI from "../hooks/useAPI";
import { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLanguage";
import ActivityDeleteDialog from "./ActivityDeleteDialog";

const ArchivedActivity = ({ activity, updateActivity, removeActivityFromList }) => {
    const [updateAPI, callUpdateAPI] = useAPI();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { nativeLanguage } = useLanguage();

    function restoreActivity() {
        callUpdateAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
            archived: !activity.archived,
            unarchive_activity_collection: activity.archived,
        });
    }

    useEffect(() => {
        if (updateAPI.response) {
            updateActivity(updateAPI.response);
        }
    }, [updateAPI.response]);

    return (
        <Segment>
            <StackableRow>
                <Link to={`/activities/${activity.id}?back=${currentLocation()}`} style={{ flexGrow: 1 }}>
                    {activity.emoji} {activity.name_native ? activity.name_native : activity.name}
                </Link>
                <div style={{ flexGrow: 0, width: "unset", display: "flex", alignItems: "center" }}>
                    {activity.archived && (
                        <>
                            <Button icon={<Trash />} compact onClick={() => setShowDeleteDialog(true)}></Button>
                            <LoadableButton
                                primary
                                compact
                                onClick={restoreActivity}
                                content={strings.restore}
                                loading={updateAPI.loading}
                            />
                        </>
                    )}
                    {!activity.archived && (
                        <LoadableButton
                            primary
                            compact
                            onClick={restoreActivity}
                            content={strings.archive}
                            loading={updateAPI.loading}
                        />
                    )}
                </div>

                <ActivityDeleteDialog
                    activity={activity}
                    setOpen={setShowDeleteDialog}
                    isOpen={showDeleteDialog}
                    onDelete={removeActivityFromList}
                />
            </StackableRow>
        </Segment>
    );
};

export default ArchivedActivity;
