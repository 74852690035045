import { useWhisper } from "../hooks/useWhisper";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Button, Icon, Loader } from "semantic-ui-react";
import { useAudio } from "../hooks/useAudio";
import { strings } from "../utils/i18n.utils";
import { ClickableIcon } from "./ClickableIcon";
import "./SpeechInputBar.css";
import { Microphone, Pause, SpeakerHigh } from "@phosphor-icons/react";
import { SendIcon } from "./SvgIcons";
import useNotifications from "../hooks/useNotifications";

export const SpeechInputBar = ({ onSpeechSubmit, currentMessage, autoTranscribe, appIsReady, noTextFound }) => {
    const [started, setStarted] = useState(false);
    const audioCtxRef = useRef(null);
    const audioStreamRef = useRef(null);
    const { isPlaying } = useAudio();
    const recordingRef = useRef(false);
    const visualizationView = useInView();
    const [analyzer, setAnalyzer] = useState(null);
    const AUDIO_ELEMENTS = 4;
    const { addNotification } = useNotifications();

    const onTranscribe = async (blob) => {
        const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });

        onSpeechSubmit(base64);

        // you must return result from your server in Transcript format
        return {
            blob,
            text: "",
        };
    };

    const { recording, transcribing, pauseRecording, startRecording, stopRecording } = useWhisper({
        onTranscribe,
        nonStop: autoTranscribe,
        stopTimeout: 1500,
        removeSilence: true,
    });

    useEffect(() => {
        console.log("SpeechInputBar: init");

        return () => {
            console.log("SpeechInputBar: shutting down", audioCtxRef.current, audioStreamRef.current);
            recordingRef.current = false;
            stopRecording();
            closeAudioContext();
        };
    }, []);

    useEffect(() => {
        recordingRef.current = recording;

        if (recording) {
            setStarted(true);
        }
    }, [recording]);

    useEffect(() => {
        if (analyzer) {
            startVisualization();
        }
    }, [analyzer]);

    function closeAudioContext() {
        if (audioCtxRef.current?.state === "running") {
            try {
                audioCtxRef.current?.close();
                audioStreamRef.current?.getAudioTracks().forEach((track) => {
                    track.stop();
                });
            } catch (e) {
                console.log("SpeechInputBar: error stopping recording", e);
            }
        }
    }

    useEffect(() => {
        recordingRef.current = recording;
        if (!recording && visualizationView.inView) {
            const visualMainElement = visualizationView.entry.target;
            let i;
            for (i = 0; i < AUDIO_ELEMENTS; ++i) {
                const visualElement = visualMainElement.children[i];

                const elmStyles = visualElement.style;
                elmStyles.transform = `scaleY( 0.33 )`;
            }
        }
    }, [recording, visualizationView.inView]);

    async function startVisualization(analyzer) {
        console.log("SpeechInputBar: starting visualization", analyzer);
        const frequencyData = new Uint8Array(analyzer.frequencyBinCount);

        renderFrame(analyzer, frequencyData);
    }

    const renderFrame = (analyzer, frequencyData) => {
        analyzer.getByteFrequencyData(frequencyData);

        if (!recordingRef.current) {
            // we're back to keyboard mode
            console.log("SpeechInputBar: renderFrame: not recording", recordingRef.current);
            closeAudioContext();
            return;
        }

        processFrame(frequencyData);

        setTimeout(() => {
            requestAnimationFrame(() => renderFrame(analyzer, frequencyData));
        }, 1000 / 30);
    };

    const processFrame = (data) => {
        const values = Object.values(data);
        const visualMainElement = document.querySelector(".audio-visualization");
        if (!visualMainElement || visualMainElement.children.length === 0) {
            console.log("Missing visual main element.");
            return;
        }
        let i;
        for (i = 0; i < AUDIO_ELEMENTS; ++i) {
            const visualElement = visualMainElement.children[i];
            const value = Math.max(values[i] / 255, 0.33);
            const elmStyles = visualElement.style;
            elmStyles.transform = `scaleY( ${value} )`;
        }
    };

    useEffect(() => {
        console.log(
            "SpeechInputBar: useEffect",
            "isPlaying",
            isPlaying,
            "inView",
            visualizationView.inView,
            "recording",
            recording,
            "appIsReady",
            appIsReady
        );
        // important to use isPlaying === false, because it can be undefined it we haven't rendered the audio player yet
        if (started && isPlaying === false && appIsReady && !recordingRef.current) {
            console.log("SpeechInputBar: startRecording");
            // we have to use a ref here because otherwise we can end up calling startRecording twice, e.g.:
            // - a message comes in and we start recording
            // - then another message comes in (so current Message changes)
            // - the recording state hasn't updated yet
            // - we call startRecording again
            start();
        } else if (isPlaying && recordingRef.current) {
            console.log("SpeechInputBar: pauseRecording");
            pauseRecording();
        }
    }, [isPlaying, currentMessage, appIsReady]);

    function start() {
        recordingRef.current = true;
        startRecording();
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                console.log("SpeechInputBar: new stream", stream);
                audioStreamRef.current = stream;
                const audioCtx = new AudioContext();
                audioCtxRef.current = audioCtx;
                const analyzer = audioCtx.createAnalyser();
                const source = audioCtx.createMediaStreamSource(stream);
                source.connect(analyzer);

                // How much data should we collect
                analyzer.fftSize = 2 ** 8;

                startVisualization(analyzer);
            })
            .catch((e) => {
                console.log("SpeechInputBar: error getting user media", e);
                addNotification(
                    addNotification(
                        "audio",
                        strings.error_starting_audio,
                        strings.formatString(strings.error_starting_audio_details, e.message)
                    )
                );
            });
    }

    return (
        <div className={"audio-input-bar"}>
            {transcribing && (
                <div className="audio-input-bar-message">
                    <Loader active inline inverted className="workaround" />
                </div>
            )}

            {(isPlaying || !appIsReady) && (
                <div className="audio-input-bar-message">
                    <SpeakerHigh />
                    &nbsp;
                    {strings.stt_speaking}
                </div>
            )}

            {!started && !isPlaying && appIsReady && (
                <div className="audio-input-bar-message">
                    <Button icon={<Microphone />} content={strings.stt_start} onClick={start} />
                </div>
            )}

            {started && !isPlaying && appIsReady && !recording && !transcribing && (
                <div className="audio-input-bar-message">
                    <Button icon={<Microphone />} content={strings.stt_resume} onClick={startRecording} />
                </div>
            )}

            {appIsReady && !isPlaying && !transcribing && recording && (
                <>
                    <div style={{ flexGrow: 1, textAlign: "left", marginLeft: "2rem" }}>&nbsp;</div>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <div className="audio-visualization" ref={visualizationView.ref}>
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>

                        {noTextFound && <div style={{ textAlign: "center" }}>{strings.stt_didnt_hear_anything}</div>}

                        <div style={{ display: "flex", lineHeight: "1em" }}>
                            <ClickableIcon onClick={pauseRecording} icon={<Pause weight="fill" />} />
                            &nbsp;
                            {strings.stt_listening}
                        </div>
                    </div>
                    <div style={{ flexGrow: 1, textAlign: "right", marginRight: "0.5rem" }}>
                        <Button
                            primary
                            onClick={stopRecording}
                            disabled={transcribing}
                            style={{
                                padding: "0.5rem",
                                borderRadius: "50%",
                                marginBottom: "0rem",
                                margin: 0,
                                width: "44px",
                            }}>
                            <SendIcon />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
