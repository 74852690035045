import { Loader } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";

export function ZataLoader({ message, inline = "centered" }) {
    return (
        <Loader active inline={inline} className="workaround">
            {message ? message : strings.loading}
        </Loader>
    );
}
