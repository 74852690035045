import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ZataLoader } from "../components/ZataLoader";
import { Card, CardContent, Container, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { useQuery } from "../hooks/useQuery";
import { strings } from "../utils/i18n.utils";

export const AuthProviderContext = React.createContext({
    user: null,
    userLoading: true,
    userError: null,
    setUser: () => {},
    statistics: {},
    setStatistics: () => {},
    languageSettings: {},
    setLanguageSettings: () => {},
    cookie: null,
    setCookie: () => {},
});

export default function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [cookie, setCookie] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [languageSettings, setLanguageSettings] = useState(null);
    const [userLoadCount, setUserLoadCount] = useState(0);

    const history = useHistory();

    const [{ response, loading, error, setLoading }, callAPI] = useAPI({
        doNotRedirect: true,
        showError: false,
    });

    useEffect(() => {
        if (user && languageSettings && !languageSettings.self_reported_level) {
            // history.push(`/languages/${user.current_language}/${user.current_native_language}/home`);
        }
    }, [languageSettings]);

    useEffect(() => {
        if (history.location.pathname.startsWith("/login")) {
            setLoading(false);
            return;
        }

        let url = `/api/user`;
        let paths = history.location.pathname.split("/");
        if (paths.length >= 4 && paths[1] === "languages") {
            url += `?language=${paths[2]}&native_language=${paths[3]}`;
        }

        callAPI("GET", url);
    }, [userLoadCount]);

    useEffect(() => {
        if (response) {
            setUser(response.user);
            setStatistics(response.statistics);
            setLanguageSettings(response.language_settings);

            if (response.user.accepted_terms === false) {
                history.push(
                    `/terms?redirectTo=${window.location.pathname}${encodeURIComponent(window.location.search)}`
                );
            }

            if (response.user.current_language === null) {
                history.push(
                    `/start?redirectTo=${window.location.pathname}${encodeURIComponent(window.location.search)}`
                );
            }
        } else {
            setUser(null);
        }
    }, [response]);

    useEffect(() => {
        if (user && user.current_native_language) {
            strings.setLanguage(user.current_native_language);
        }
    }, [user]);

    const contextValue = {
        user,
        userLoading: loading,
        userError: error,
        setUser: setUser,
        statistics,
        setStatistics,
        languageSettings,
        setLanguageSettings,
        cookie,
        setCookie,
    };

    if (loading)
        return (
            <AuthProviderContext.Provider value={contextValue}>
                {true && (
                    <Container
                        style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "100%",
                        }}>
                        <div
                            style={{
                                display: "flex",
                                alignContent: "",
                                justifyContent: "center",
                                flexDirection: "row",
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    height: "100%",
                                }}>
                                <div style={{ flexGrow: 4 }} />
                                <img src={`/logo.svg`} style={{ width: "5em" }} />
                            </div>
                            <div style={{ margin: "0.5rem" }} />
                            <Segment style={{ width: "unset" }}>
                                {loading && <ZataLoader />}
                                {!loading && error.message}
                            </Segment>
                        </div>
                    </Container>
                )}
            </AuthProviderContext.Provider>
        );
    return <AuthProviderContext.Provider value={contextValue}>{children}</AuthProviderContext.Provider>;
}
