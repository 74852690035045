import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export const NotificationContext = React.createContext({
    notifications: null,
    addNotification: () => {},
    removeNotification: () => {},
});

const NotificationProvider = (props) => {
    const { listen } = useHistory();
    const notificationsRef = useRef(new Map());
    const [notifications, setNotifications] = useState(new Map());

    const removeNotification = (id) => {
        if (notificationsRef.current.has(id)) {
            notificationsRef.current.delete(id);
            setNotifications(new Map(notificationsRef.current));
        }
    };

    useEffect(() => {
        const unlisten = listen((location) => {
            notificationsRef.current = new Map();
            setNotifications(new Map(notificationsRef.current));
        });
        return unlisten;
    }, [listen]);

    const addNotification = (id, header, message, timeout) => {
        let notification = {
            header: header,
            message: message,
            timeout: timeout,
        };
        notificationsRef.current.set(id, notification);
        setNotifications(new Map(notificationsRef.current));

        if (timeout && timeout > 0) {
            let debounce = setTimeout(() => {
                removeNotification(id);
            }, timeout);
            return () => clearTimeout(debounce);
        }
    };

    const contextValue = {
        notifications,
        addNotification: useCallback(
            (id, header, message, timeout) => addNotification(id, header, message, timeout),
            []
        ),
        removeNotification: useCallback((id) => removeNotification(id), []),
    };

    return <NotificationContext.Provider value={contextValue}>{props.children}</NotificationContext.Provider>;
};
export default NotificationProvider;
