import { strings } from "./i18n.utils";

export function timeSince(time, locale = "en") {
    let lang = locale || "en";

    strings.setLanguage(lang);

    switch (typeof time) {
        case "number":
            break;
        case "string":
            time = +new Date(time);
            break;
        case "object":
            if (time.constructor === Date) time = time.getTime();
            break;
        default:
            time = +new Date();
    }
    var time_formats = [
        [60, strings.seconds, 1], // 60
        [120, strings.one_minute_ago, strings.one_minute_from_now], // 60*2
        [3600, strings.minutes, 60], // 60*60, 60
        [7200, strings.one_hour_ago, strings.one_minute_from_now], // 60*60*2
        [86400, strings.hours, 3600], // 60*60*24, 60*60
        [172800, strings.yesterday, strings.tomorrow], // 60*60*24*2
        [604800, strings.days, 86400], // 60*60*24*7, 60*60*24
        [1209600, strings.last_week, strings.next_week], // 60*60*24*7*4*2
        [2419200, strings.weeks, 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, strings.last_month, strings.next_month], // 60*60*24*7*4*2
        [29030400, strings.months, 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, strings.last_year, strings.next_year], // 60*60*24*7*4*12*2
        [2903040000, strings.years, 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, strings.last_century, strings.next_century], // 60*60*24*7*4*12*100*2
        [58060800000, strings.centuries, 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

    if (seconds === 0) {
        return "Just now";
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
    }
    var i = 0,
        format;
    while ((format = time_formats[i++]))
        if (seconds < format[0]) {
            if (typeof format[2] == "string") return format[list_choice];
            else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
        }
    return time;
}
