import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";

export const AudioProviderContext = React.createContext({
    audio: null,
    play: () => null,
    stop: () => null,
    isPlaying: false,
    loadAudio: () => null,
    unloadAudio: () => null,
    queueAudioToPlay: () => null,
    playingAudioUrl: null,
});

const audio = new Audio();
audio.playbackRate = 0.5;

export default function AudioProvider({ children }) {
    const [isPlaying, setPlaying] = useState();
    const [audioLoaded, setAudioLoaded] = useState();
    const { cookie, languageSettings } = useAuth();
    const history = useHistory();
    const [playQueue, setPlayQueue] = useState([]);
    const isPlayingRef = useRef(isPlaying);
    const [playingAudioUrl, setPlayingAudioUrl] = useState();

    useEffect(() => {
        loadAudio();
        audio.addEventListener("ended", () => {
            setPlaying(false);
        });

        audio.addEventListener("pause", () => {
            setPlaying(false);
        });
    }, []);

    function unloadAudio() {
        if (audio) {
            audio.pause();
        }
    }

    function loadAudio() {
        if (!audioLoaded) {
            audio.load();
        }
        setAudioLoaded(true);
    }

    useEffect(() => {
        history.listen((location, action) => {
            stop();
        });
    }, []);

    function stop() {
        setPlaying(false);
        setPlayQueue([]);
        audio.pause();
    }

    function queueAudioToPlay(url, clearQueue = false, playbackRate = 1.0) {
        console.log("Queueing audio to play", url, clearQueue, playbackRate);
        if (languageSettings.audio_enabled === false) {
            return;
        }

        if (clearQueue) {
            stop();
            setPlayQueue([[url, playbackRate]]);
        } else {
            setPlayQueue([...playQueue, [url, playbackRate]]);
        }
    }

    useEffect(() => {
        if (playQueue.length > 0 && !isPlaying) {
            play(playQueue[0][0], playQueue[0][1]);
            setPlayQueue(playQueue.slice(1));
        }
    }, [playQueue, isPlaying]);

    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    async function play(url, playbackRate = 1.0) {
        console.log("Playing audio for url", url, playbackRate);
        audio.defaultPlaybackRate = playbackRate;
        setPlayingAudioUrl(url);

        // We cannot use the built in audio component on mobile because the cookie is not sent with the request
        if (window.Capacitor || window.location.hostname === "localhost") {
            try {
                const naturalCookie = Cookies.get("jwt_token");
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "X-Authorization": `Bearer ${cookie || naturalCookie}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok fetching audio");
                }
                const blob = await response.blob();
                // Create a local URL for the blob
                const blobUrl = URL.createObjectURL(blob);
                audio.src = blobUrl;
            } catch (error) {
                console.log("Error fetching audio", error);
            }
        } else {
            audio.src = url;
        }
        setPlaying(true);
        audio.play().catch((error) => {
            console.log("Audio error", error);
            setPlaying(false);
        });

        return audio.src;
    }

    console.log("AudioPlayer: isPlaying", isPlaying, audio.src);
    const contextValue = {
        audio,
        play,
        stop,
        isPlaying,
        loadAudio,
        unloadAudio,
        queueAudioToPlay,
        playingAudioUrl,
    };

    return <AudioProviderContext.Provider value={contextValue}>{children}</AudioProviderContext.Provider>;
}
