import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "../hooks/useLanguage";
import Phrase from "./Phrase";
import { currentLocation } from "../utils/url.utils";

const SkillSegment = ({ skill }) => {
    const history = useHistory();
    const { nativeLanguage } = useLanguage();

    return (
        <div style={{ marginBottom: "1rem" }}>
            <b onClick={() => history.push(`/skills/${skill.id}?back=${currentLocation()}`)}>
                {skill.name_native ? skill.name_native : skill.name}
            </b>
            <div>
                {skill.vocab?.map((phrase, index) => (
                    <>
                        <Phrase phrase={phrase} translationLanguage={nativeLanguage} inline={true} />
                        {index < skill.vocab.length - 1 ? ", " : ""}
                    </>
                ))}
            </div>
        </div>
    );
};

export default SkillSegment;
