import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Progress as ProgressBar } from "semantic-ui-react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";
import { Row } from "./Flex";
import { StreakBar, TopStatistics } from "./TopStatistics";
import { ZataLoader } from "./ZataLoader";
import { Statistic, StatisticsBar, StatisticsRow } from "./StatisticsBar";
import useAuth from "../hooks/useAuth";
import DailyProgressBar from "./DailyProgressBar";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const COLOR1 = "#037ddb";
const COLOR2 = "#f7b500";
const COLOR3 = "#9a00fa";

const ProgressChartStatistic = ({ statistic, statisticTitle }) => {
    return (
        <div style={{ flexGrow: 1 }}>
            <div
                style={{
                    flexGrow: 0,
                    marginRight: "1rem",
                    marginBottom: "0.25rem",
                    fontSize: "2rem",
                    fontWeight: 800,
                }}>
                {statistic}
            </div>
            <div>{statisticTitle}</div>
        </div>
    );
};
const ProgressChart = ({ dataLabel, emoji, labels, data, datasets }) => {
    const options = {
        animation: false,
        plugins: {
            legend: {
                display: datasets ? true : false,
                position: "bottom",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return Math.round(context.parsed.y, 0);
                    },
                },
            },
        },
        datasets: {
            bar: {
                borderRadius: 20,
            },
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
        },
    };

    const chartData = {
        labels,
        datasets: datasets
            ? datasets
            : [
                  {
                      label: dataLabel,
                      data: data,
                      backgroundColor: COLOR1,
                  },
              ],
    };
    return (
        <Segment>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <div
                    style={{
                        flexGrow: 0,
                        marginRight: "1rem",
                        fontSize: "3rem",
                    }}>
                    {toCoolEmojis(emoji)}
                </div>

                {chartData.datasets.map((d, i) => (
                    <ProgressChartStatistic key={i} statistic={d.data[d.data.length - 1]} statisticTitle={d.label} />
                ))}
            </div>
            <div style={{ height: datasets ? "9rem" : "7rem", marginTop: "1rem" }}>
                <Bar options={options} data={chartData} />
            </div>
        </Segment>
    );
};

const Progress = () => {
    const { language, nativeLanguage } = useParams();
    const [progressAPI, callProgressAPI] = useAPI({ loading: true });
    const { statistics } = useAuth();

    useEffect(() => {
        callProgressAPI("GET", `/api/languages/${language}/${nativeLanguage}/progress`);
    }, []);

    if (progressAPI.loading) {
        return <ZataLoader />;
    }

    const labels = progressAPI.response?.map((p) => {
        const date = new Date(p.day.replace(/-/g, "/"));
        // is the date today?
        if (date.toDateString() === new Date().toDateString()) {
            return strings.today;
        }
        return "";
        // create day-month in the local language
        // return date.toLocaleString(nativeLanguage, { day: "2-digit", month: "short" });
    });

    return (
        <>
            <StatisticsBar>
                <StatisticsRow>
                    <Statistic statistic={statistics?.streak_days + " 🔥"} message={strings.stats_streak} />
                    <Statistic
                        statistic={statistics?.flashcards_needing_review?.toString()}
                        message={strings.flashcards_needing_review}
                    />
                </StatisticsRow>
            </StatisticsBar>

            <StreakBar progress={progressAPI.response} />

            <ProgressChart
                dataLabel={strings.activity_minutes_title}
                emoji={"🏋️‍♀️"}
                labels={labels}
                data={progressAPI.response.map((p) => Math.round(Math.max(p.activity_minutes, 0)))}
            />

            <ProgressChart
                dataLabel={"Reviewed / Created"}
                labels={labels}
                emoji={"🗣️"}
                datasets={[
                    {
                        label: strings.exercises_reviewed,
                        data: progressAPI.response.map((p) => p.flashcards_reviewed),
                        backgroundColor: COLOR1,
                    },
                    {
                        label: strings.exercises_created,
                        data: progressAPI.response.map((p) => p.flashcards_created),
                        backgroundColor: COLOR2,
                    },
                    {
                        label: strings.exercises_inferred,
                        data: progressAPI.response.map((p) => p.flashcards_inferred),
                        backgroundColor: COLOR3,
                    },
                ]}
            />

            <ProgressChart
                dataLabel={strings.exercises_needing_review}
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => p.flashcards_needing_review)}
            />

            <ProgressChart
                dataLabel={strings.exercises_known}
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => p.flashcards_known)}
            />

            <ProgressChart
                labels={labels}
                emoji={"🗣️"}
                data={progressAPI.response.map((p) => Math.max(p.words_sent, 0))}
                dataLabel={strings.words_sent_title}
            />
        </>
    );
};

export default Progress;
