import { useContext } from "react";
import { AuthProviderContext } from "../providers/AuthProvider";

function useAuth() {
    const {
        user,
        userLoading,
        userError,
        setUser,
        count,
        total,
        percentFed,
        statistics,
        setStatistics,
        languageSettings,
        setLanguageSettings,
        cookie,
        setCookie,
    } = useContext(AuthProviderContext);

    return {
        user,
        userLoading,
        userError,
        setUser,
        count,
        total,
        percentFed,
        statistics,
        setStatistics,
        languageSettings,
        setLanguageSettings,
        cookie,
        setCookie,
    };
}

export default useAuth;
