import { Archive, ClockCounterClockwise, DotsThreeOutline, Gear, SignOut } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Dropdown, Label } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { allStrings, strings } from "../utils/i18n.utils";
import { isMobile } from "../utils/platform.utils";
import DailyProgressBar from "./DailyProgressBar";
import "./HomeTopBar.css";
import LanguageMenu from "./LanguageMenu";

const HomeTopBar = () => {
    const { user, statistics } = useAuth();
    const { language, nativeLanguage } = useLanguage();
    const dimensions = useWindowDimensions();
    const [isMobileDevice, setIsMobileDevice] = useState(isMobile.any());

    useEffect(() => {
        setIsMobileDevice(dimensions.width < 1200);
    }, [dimensions]);

    return (
        <div className="home-top-bar">
            <div className="home-top-row">
                <span className="home-top-bar-greeting">
                    {!isMobile.any() && (
                        <>
                            {allStrings[language].hello}, {user.name}.
                        </>
                    )}
                    {isMobile.any() && <>{allStrings[language].hello}</>}
                </span>
                {!isMobileDevice && (
                    <span className="home-top-bar-progress">
                        <Label basic as={"a"} href={`/languages/${language}/${nativeLanguage}/you`}>
                            <DailyProgressBar />
                        </Label>
                    </span>
                )}
                <div class="home-top-bar-buttons">
                    <span>
                        <Label compact basic as={"a"} href={`/languages/${language}/${nativeLanguage}/you`}>
                            <span class="emoji color">🔥</span>
                            &nbsp;{statistics?.streak_days}
                        </Label>
                    </span>
                    <LanguageMenu />
                    <Dropdown
                        trigger={<Label basic icon={<DotsThreeOutline />} className="icon" />}
                        icon={null}
                        direction={"left"}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={"a"}
                                href={`/languages/${language}/${nativeLanguage}/settings`}
                                icon={<Gear />}
                                content={strings.settings}
                            />
                            <Dropdown.Item
                                as={"a"}
                                href={`/languages/${language}/${nativeLanguage}/history`}
                                icon={<ClockCounterClockwise />}
                                content={strings.history}></Dropdown.Item>
                            <Dropdown.Item
                                as={"a"}
                                href={`/languages/${language}/${nativeLanguage}/archive`}
                                icon={<Archive />}
                                content={strings.archived_items}></Dropdown.Item>
                            <Dropdown.Item
                                as={"a"}
                                href={`/logout`}
                                icon={<SignOut />}
                                content={strings.logout}></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            {isMobileDevice && (
                <div className="home-top-bar-progress">
                    <Label basic as={"a"} href={`/languages/${language}/${nativeLanguage}/you`}>
                        <DailyProgressBar />
                    </Label>
                </div>
            )}
        </div>
    );
};

export const HomeTopBarPortal = () => {
    return createPortal(<HomeTopBar />, document.getElementById("topbar"));
};

export default HomeTopBar;
