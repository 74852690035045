import { Button, Modal } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import { Trash } from "@phosphor-icons/react";

const ActivityCollectionDeleteDialog = ({ collection, onDelete, setOpen, isOpen }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function cancel() {
        setOpen(false);
    }

    function deleteActivityCollection() {
        if (collection.id) {
            callDeleteAPI("DELETE", `/api/activity_collections/${collection.id}?hard=true`);
        } else {
            onDelete(collection);
        }
    }

    useEffect(() => {
        if (deleteAPI.response) {
            setOpen(false);
            onDelete(collection);
        }
    }, [deleteAPI.response]);

    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={isOpen}>
            <Modal.Header>{strings.activity_collection_delete_header}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>{strings.formatString(strings.activity_collection_delete_warning, collection.name_native)}</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={cancel} disabled={deleteAPI.loading}>
                    Cancel
                </Button>
                <Button
                    content={strings.delete}
                    icon={<Trash />}
                    onClick={deleteActivityCollection}
                    disabled={deleteAPI.loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ActivityCollectionDeleteDialog;
