import { Divider, Segment } from "semantic-ui-react";
import "./StatisticsBar.css";
import { StreakAchievedIcon, StreakFreezeIcon, StreakMissedIcon, StreakTodayIcon } from "./SvgIcons";
import { toCoolEmojis } from "../utils/emoji.utils";

export function StatisticsBar({ children }) {
    return <Segment className="StatisticsBar">{children}</Segment>;
}

export function StatisticsRow({ children }) {
    return <div className="StatisticsRow">{children}</div>;
}

export function StatisticsVerticallySplitRow({ left, right }) {
    return (
        <div className="StatisticsVerticallySplitRow">
            <div className={"left"}>{left}</div>
            <div className={"divider"}></div>
            <div className={"right"}>{right}</div>
        </div>
    );
}

export function Statistic({ statistic, message }) {
    return (
        <span className="Statistic">
            <div className="StatisticWrapper">
                <div className="StatisticNumber">{statistic ? toCoolEmojis(statistic) : 0}</div>
                <div className="StatisticMessage">{message}</div>
            </div>
            <div className="StatisticSpacer"> </div>
        </span>
    );
}

export function StreakIcon({ type }) {
    return (
        <div className={type ? "StreakIcon " + type : "StreakIcon"}>
            {type === "streak" && <StreakAchievedIcon size="1.34rem" />}
            {type === "freeze" && <StreakFreezeIcon size="1.34rem" />}
            {type === "today" && <StreakTodayIcon size="1.34rem" />}
            {!type && <StreakMissedIcon size="1.34rem" />}
        </div>
    );
}
