import _ from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { createEditor, Editor, Node, Transforms } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";

import useAPI from "../hooks/useAPI";
import { useEventListener } from "../hooks/useEventListener";

const withInlines = (editor) => {
    const { isInline } = editor;

    editor.isInline = (element) => {
        return ["grammar"].includes(element.type) || isInline(element);
    };

    return editor;
};

export const TextEditor = memo(
    ({
        initialText,
        placeholder,
        language,
        nativeLanguage,
        onChange,
        liveCorrect,
        onSubmit,
        onCorrectionStateChange,
        disabled,
        autoCapitalize,
        grammarCorrections,
        onFocus,
        onSelectionChange,
        onApplyCorrection,
        sendCorrections = false,
        ignoredGrammarCorrections = [],
        setIgnoredGrammarCorrections = () => null,
        resetCount = 0,
        scrollLock = true,
        style,
        minHeight,
    }) => {
        const initialValue = [
            {
                type: "paragraph",
                children: [{ text: "" }],
            },
        ];

        const [allGrammarCorrections, setAllGrammarCorrections] = useState([[]]);
        const [textByNode, setTextByNode] = useState([""]);
        const [previousTextByNode, setPreviousTextByNode] = useState([""]);
        const [grammarAPI, callGrammarAPI] = useAPI();
        const editor = useMemo(() => withHistory(withInlines(withReact(createEditor()))), []);
        const [value, setValue] = useState(initialValue);
        const [inFocus, setInFocus] = useState(false);
        const [firstTap, setFirstTap] = useState(true);
        const [lastScrollTop, setLastScrollTop] = useState(-1);
        const correctionStateRef = useRef();
        const [textValue, setTextValue] = useState("");
        const loaded = useRef(false);
        correctionStateRef.current = {
            sendCorrections: sendCorrections,
            allGrammarCorrections: allGrammarCorrections,
        };

        useEffect(() => {
            if (initialText === textValue) {
                loaded.current = true;
                return;
            }

            let replacementText = "";
            if (initialText) {
                replacementText = initialText;
            }
            try {
                Transforms.insertText(editor, replacementText, {
                    at: {
                        anchor: Editor.start(editor, []),
                        focus: Editor.end(editor, []),
                    },
                });

                ReactEditor.blur(editor);
                ReactEditor.focus(editor);
                Transforms.collapse(editor, { edge: "end" });
            } catch (err) {
                console.error("Slate error:", err);
            }
            console.log("TextEditor: initialText", initialText);
            loaded.current = true;
        }, [editor, initialText]);

        useEffect(() => {
            if (resetCount > 0) {
                Transforms.insertText(editor, "", {
                    at: {
                        anchor: Editor.start(editor, []),
                        focus: Editor.end(editor, []),
                    },
                });
                Transforms.select(editor, [0]);
                console.log("TextEditor: reset", resetCount);
            }
        }, [editor, resetCount]);

        useEffect(() => {
            if (!grammarCorrections) {
                return;
            }
            markupGrammarCorrections([grammarCorrections], 0);
            setAllGrammarCorrections([grammarCorrections]);
        }, [grammarCorrections]);
        /*
        useEffect(() => {
            if (!disabled && editor && !isMobile.any()) {
                ReactEditor.blur(editor);
                ReactEditor.focus(editor);
            }
        }, [disabled]);
        */
        const serialize = (nodes) => {
            return Array.from(nodes.map((child) => Node.string(child)));
        };

        function internalOnSelection() {
            const { selection } = editor;
            if (selection !== null && selection.anchor !== null) {
                const [, path] = Editor.above(editor, {
                    at: selection.anchor.path,
                });
                const selectedText = Editor.string(editor, editor.selection);

                const wordRange = word(editor, selection, {
                    terminator: [" ", ".", "!", ",", "-", '"', "'", "\n", "¿", "¡"],
                    directions: "both",
                    include: true,
                });

                const wordAtCursor = Editor.string(editor, wordRange);

                let selectionData = {
                    selectedText: selectedText,
                    wordAtCursor: wordAtCursor.trim(),
                    replace: (text) => {
                        Transforms.insertText(editor, text, {
                            at: selection,
                        });
                    },
                };

                if (onSelectionChange) {
                    onSelectionChange(selectionData);
                }
            }
        }

        function word(editor, location, options = {}) {
            const { terminator = [" "], include = false, directions = "both" } = options;

            const { selection } = editor;
            if (!selection) return;

            // Get start and end, modify it as we move along.
            let start = location.anchor;
            let end = location.focus;
            let point = start;

            function move(direction) {
                const next =
                    direction === "right"
                        ? Editor.after(editor, point, {
                              unit: "character",
                          })
                        : Editor.before(editor, point, { unit: "character" });

                const wordNext =
                    next &&
                    Editor.string(
                        editor,
                        direction === "right" ? { anchor: point, focus: next } : { anchor: next, focus: point }
                    );

                const last = wordNext && wordNext[direction === "right" ? 0 : wordNext.length - 1];
                if (next && last && !terminator.includes(last)) {
                    point = next;

                    if (point.offset === 0) {
                        // Means we've wrapped to beginning of another block
                        return false;
                    }
                } else {
                    return false;
                }

                return true;
            }

            // Move point and update start & end ranges

            // Move forwards
            if (directions !== "left") {
                point = end;
                while (move("right"));
                end = point;
            }

            // Move backwards
            if (directions !== "right") {
                point = start;
                while (move("left"));
                start = point;
            }

            if (include) {
                return {
                    anchor: Editor.before(editor, start, { unit: "offset" }) ?? start,
                    focus: Editor.after(editor, end, { unit: "offset" }) ?? end,
                };
            }

            return { anchor: start, focus: end };
        }

        // find the current suggestion and show it at the right place if the user clicks on it
        function onClick(e) {}

        function isDescendent(current, element) {
            while (current && current != element && current != document.body) {
                current = current.parentNode;
            }
            if (typeof current == "undefined" || typeof current == "null") {
                return false;
            } else if (current == element) {
                return true;
            } else if (current == document.body) {
                return false;
            }
        }

        useEffect(() => {
            if (liveCorrect) {
                let startIdx = textByNode.length;
                for (let i = 0; i < textByNode.length; i++) {
                    if (i >= textByNode.length) {
                        startIdx = i;
                        break;
                    } else if (previousTextByNode[i] != textByNode[i]) {
                        startIdx = i;
                        break;
                    }
                }

                let endIdx = textByNode.length;
                let previousEndIdx = previousTextByNode.length;
                for (let i = 0; i < textByNode.length; i++) {
                    if (i >= previousTextByNode.length) {
                        endIdx = textByNode.length;
                        previousEndIdx = previousTextByNode.length;
                        break;
                    } else if (
                        previousTextByNode[previousTextByNode.length - 1 - i] != textByNode[textByNode.length - 1 - i]
                    ) {
                        endIdx = textByNode.length - i;
                        previousEndIdx = previousTextByNode.length - i;
                        break;
                    }
                }

                let textsToCorrect = [];

                if (startIdx < endIdx) textsToCorrect = textByNode.slice(startIdx, endIdx);

                let selectedTextIdx = -1;
                let selectedTextOffset = -1;
                const { selection } = editor;
                if (selection !== null && selection.anchor !== null) {
                    selectedTextIdx = selection.anchor.path[0];
                    selectedTextOffset = selection.anchor.offset;
                }

                if (textsToCorrect.length > 0) {
                    const delayDebounceFn = setTimeout(() => {
                        setPreviousTextByNode(textByNode);
                        let url = `/api/grammar/check_all`;

                        callGrammarAPI(
                            "POST",
                            url,
                            {
                                language: language,
                                native_language: nativeLanguage,
                                texts: textsToCorrect,
                                textSuggestIdx: selectedTextIdx - startIdx,
                                textSuggesttOffset: selectedTextOffset,
                            },
                            [startIdx, endIdx, previousEndIdx]
                        );
                    }, 1000);

                    if (onCorrectionStateChange) {
                        onCorrectionStateChange({
                            checking: true,
                        });
                    }
                    return () => clearTimeout(delayDebounceFn);
                }
            }
        }, [textByNode, language]);

        useEffect(() => {
            if (grammarAPI.response) {
                const [startIdx, endIdx, previousEndIdx] = grammarAPI.callbackData;

                markupGrammarCorrections(grammarAPI.response, startIdx);

                let newAllGrammarCorrections = allGrammarCorrections
                    .splice(0, startIdx)
                    .concat(grammarAPI.response)
                    .concat(
                        allGrammarCorrections.splice(previousEndIdx, allGrammarCorrections.length - previousEndIdx)
                    );
                setAllGrammarCorrections(newAllGrammarCorrections);
            }
        }, [grammarAPI.response]);

        // apply grammar corrections from external and internal
        function markupGrammarCorrections(allCorrections, startIdx) {
            //Editor.withoutNormalizing(editor, () => {
            for (var i = 0; i < allCorrections.length; i++) {
                const nodeIdx = i + startIdx;
                const parent = value[nodeIdx];

                // First remove all grammar nodes here
                Transforms.unwrapNodes(editor, {
                    at: [nodeIdx],
                    match: (n) => {
                        return parent.children.includes(n);
                    },
                });

                // add grammar corrections
                allCorrections[i].sort((a, b) => b.offset - a.offset);

                for (const correction of allCorrections[i]) {
                    if (ignoredGrammarCorrections.includes(correction.id)) continue;

                    if (correction.length === 0) continue;

                    Transforms.wrapNodes(
                        editor,
                        {
                            type: "grammar",
                            children: [{ text: correction.offending_text }],
                            correction: correction,
                        },
                        {
                            at: {
                                anchor: {
                                    path: [nodeIdx, 0],
                                    offset: correction.offset,
                                },
                                focus: {
                                    path: [nodeIdx, 0],
                                    offset: correction.offset + correction.length,
                                },
                            },
                            split: true,
                        }
                    );
                }
            }
            //})
        }

        useEffect(() => {
            if (!onCorrectionStateChange) {
                return;
            }

            let suggestions = 0;
            let typos = 0;
            let errors = 0;

            for (const corrections of allGrammarCorrections) {
                for (const correction of corrections) {
                    if (ignoredGrammarCorrections.includes(correction.id)) continue;

                    if (correction.is_suggestion) {
                        suggestions += 1;
                    } else if (correction.is_typo) {
                        typos += 1;
                    } else {
                        errors += 1;
                    }
                }
            }

            onCorrectionStateChange({
                checking: false,
                errors: errors,
                suggestions: suggestions,
                typos: typos,
            });
        }, [allGrammarCorrections]);

        function internalOnFocus(e) {
            console.log("TextEditor.onFocus");

            setInFocus(true);
            if (onFocus) {
                onFocus(e, true);
            }
        }

        function internalOnBlur(e) {
            console.log("TextEditor.onBlur");
            setInFocus(false);
            if (onFocus) {
                onFocus(e, false);
            }
            setLastScrollTop(-1);
        }

        // Prevent scrolling when the editor is in focus
        useEventListener("touchmove", (e) => {
            if (!scrollLock) {
                if (lastScrollTop === -1) {
                    setLastScrollTop(document.documentElement.scrollHeight - window.window.visualViewport.height);
                }

                console.log(
                    "Got scroll",
                    lastScrollTop,
                    inFocus,
                    document.documentElement.scrollHeight,
                    window.window.visualViewport.height
                );
                if (inFocus) {
                    let content = document.getElementById("content");
                    if (!isDescendent(content, e.target)) {
                        e.preventDefault();

                        document.body.scrollTop = lastScrollTop;
                        window.scrollTo(0, lastScrollTop);
                    }
                } else {
                    setLastScrollTop(document.documentElement.scrollHeight - window.window.visualViewport.height);
                }
            }
        });

        // add buffer when the editor is in focus so all the relevant content is in the window
        useEffect(() => {
            if (scrollLock) {
                const element = document.getElementById("keyboard-dead-area");

                if (element == null) return;
                if (inFocus) {
                    element.classList.add("show");
                } else {
                    element.classList.remove("show");
                }
            }
        }, [inFocus]);

        function ignoreCorrection(correctionId) {
            Transforms.unwrapNodes(editor, {
                match: (n) => n.correction?.id === correctionId,
            });
            ignoredGrammarCorrections.push(correctionId);
            setIgnoredGrammarCorrections(ignoredGrammarCorrections);
        }

        const applyCorrection = useCallback(
            (correction, replacement) => {
                let result = Editor.next(editor, {
                    match: (n) => {
                        return n.correction?.id === correction.id;
                    },
                    mode: "all",
                });

                if (!result) {
                    result = Editor.previous(editor, {
                        match: (n) => {
                            return n.correction?.id === correction.id;
                        },
                        mode: "all",
                    });
                }

                if (result == null || result.length === 0) {
                    return;
                }

                const [node, path] = result;
                Transforms.insertText(editor, replacement, {
                    at: path,
                });

                if (onApplyCorrection) {
                    onApplyCorrection(correction, replacement);
                }
            },
            [sendCorrections, allGrammarCorrections]
        );

        function onKeyDown(event) {
            if (event.key === "Enter" && onSubmit) {
                // Prevent the ampersand character from being inserted.
                event.preventDefault();
                onSubmit(textValue, null);
                ReactEditor.blur(editor);
            }
        }

        function internalOnTouchStart(event) {
            onClick(event);

            let element = event.target;
            if (element !== document.activeElement || firstTap) {
                setFirstTap(false);
                // iOS doesn't allow full focus until user taps in
                internalOnFocus(event);
            }
        }

        // Define a rendering function based on the element passed to `props`. We use
        // `useCallback` here to memoize the function for subsequent renders.
        const renderElement = useCallback((props) => {
            if (props.element.type === "grammar") {
                return (
                    <GrammarCorrectionElement
                        ignoreCorrection={ignoreCorrection}
                        applyCorrection={applyCorrection}
                        {...props}>
                        {props.children}
                    </GrammarCorrectionElement>
                );
            } else {
                return <DefaultElement {...props}>{props.children}</DefaultElement>;
            }
        }, []);

        function internalOnChange(newValue) {
            console.log("TextEditor.onChange");
            setValue(newValue);
            if (editor.operations.every((op) => op.type === "set_selection")) {
                internalOnSelection();
            }
        }

        useEffect(() => {
            if (!loaded.current) {
                console.log("TextEditor: not loaded, skipping");
                return;
            }

            let newTextByNode = serialize(value);
            setTextByNode(newTextByNode);
            if (onChange) {
                let newTextValue = newTextByNode.join("\n");
                setTextValue(newTextValue);
                onChange(newTextValue);
            }

            // clear out the grammar correction if the user changed the text
            const { selection } = editor;
            if (selection !== null && selection.anchor !== null) {
                const [selected, path] = Editor.above(editor, {
                    at: selection.anchor.path,
                });
                if (selected.type === "grammar" && Node.string(selected) !== selected.correction.offending_text) {
                    Transforms.unwrapNodes(editor, { at: path });
                }
            }
        }, [value]);

        return (
            <>
                <div
                    data-testid="text-editor"
                    style={{ position: "relative", margin: 0, ...style }}
                    className={inFocus ? "text-editor segment-focus" : "text-editor"}>
                    <div className="editor">
                        <Slate editor={editor} initialValue={initialValue} onChange={internalOnChange}>
                            <Editable
                                id="editable"
                                placeholder={placeholder}
                                className="editable"
                                onClick={onClick}
                                onBlur={internalOnBlur}
                                onFocus={internalOnFocus}
                                renderElement={renderElement}
                                onKeyDown={onKeyDown}
                                onTouchStart={internalOnTouchStart}
                                autoCapitalize={autoCapitalize}
                                spellCheck={false}
                                readOnly={disabled}
                                style={{ minHeight: minHeight }}
                            />
                        </Slate>
                    </div>
                </div>
            </>
        );
    }
);

const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
};

// Define a React component renderer for our code blocks.
const GrammarCorrectionElement = (props) => {
    let correction = props.element.correction;
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState("top center");
    const ref = useRef(null);

    useEffect(updatePosition, [ref]);
    useEventListener("resize", updatePosition, window.visualViewport);

    function updatePosition() {
        if (!ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        if (rect.left < 100) {
            setPosition("top right");
        }
        if (rect.right < 100) {
            setPosition("top left");
        } else {
            setPosition("top center");
        }
    }

    return (
        <>
            {correction.suggested_replacements?.length > 0 && (
                <Popup
                    pinned
                    on={"click"}
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    trigger={
                        <span
                            {...props.attributes}
                            id={correction.id}
                            className={correction.is_typo ? "typo-underline" : "error-underline"}
                            ref={ref}>
                            {props.children}
                        </span>
                    }
                    position={position}
                    content={
                        <div>
                            <div
                                style={{
                                    width: "160px",
                                    maxHeight: "150px",
                                    overflowY: "auto",
                                }}>
                                {_.map(correction.suggested_replacements, (replacement) => {
                                    return (
                                        <p
                                            key={replacement}
                                            className="te-tooltip"
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                                props.applyCorrection(correction, replacement);
                                            }}>
                                            {replacement}
                                            {replacement === "" && "(remove word)"}
                                        </p>
                                    );
                                })}
                            </div>
                            {!correction.is_typo && (
                                <>
                                    {correction.text != "" && false && (
                                        <>
                                            <hr />
                                            <div
                                                style={{
                                                    width: "160px",
                                                    maxHeight: "100px",
                                                    overflowY: "auto",
                                                }}>
                                                <p>{correction.text}</p>
                                            </div>
                                        </>
                                    )}
                                    <hr />
                                    <p>
                                        <span
                                            className="te-tooltip"
                                            onMouseDown={(event) => {
                                                props.ignoreCorrection(correction.id);
                                            }}>
                                            <Icon name="thumbs down"></Icon> Bad suggestion
                                        </span>
                                    </p>
                                </>
                            )}
                        </div>
                    }
                />
            )}
            {!(correction.suggested_replacements?.length > 0) && (
                <span
                    {...props.attributes}
                    id={correction.id}
                    className={correction.is_typo ? "typo-underline" : "error-underline"}
                    ref={ref}>
                    {props.children}
                </span>
            )}
        </>
    );
};

export const GrammarStatusBar = ({ grammarCorrectionState, errorInstructions }) => {
    return (
        <span>
            {grammarCorrectionState && grammarCorrectionState.checking && <>Checking grammar...</>}
            {grammarCorrectionState && !grammarCorrectionState.checking && (
                <>
                    {grammarCorrectionState.errors + grammarCorrectionState.typos == 1 && (
                        <>
                            {grammarCorrectionState.errors + grammarCorrectionState.typos} error. {errorInstructions}
                        </>
                    )}
                    {grammarCorrectionState.errors + grammarCorrectionState.typos > 1 && (
                        <>
                            {grammarCorrectionState.errors + grammarCorrectionState.typos} errors. {errorInstructions}
                        </>
                    )}
                    {grammarCorrectionState.errors == 0 && grammarCorrectionState.typos == 0 && <>&nbsp;</>}
                    {grammarCorrectionState.suggestions == 1 && <>{grammarCorrectionState.suggestions} suggestion</>}
                    {grammarCorrectionState.suggestions > 1 && <>{grammarCorrectionState.suggestions} suggestions</>}
                </>
            )}
        </span>
    );
};
