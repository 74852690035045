import { useEffect, useState } from "react";
import { Button, TextArea } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { ModalInput } from "./ModalInput";
import "./Memory.css";

const UpdateBiography = ({ trigger }) => {
    const [UpdateMemory, callUserAPI] = useAPI({ loading: false });
    const { user, setUser } = useAuth();
    const [dossier, setDossier] = useState();

    useEffect(() => {
        if (user) {
            setDossier(user.dossier);
        }
    }, [user]);

    function updateDossier() {
        setUser({ ...user, dossier: dossier });

        callUserAPI("PUT", "/api/user", { dossier: dossier });
    }

    return (
        <ModalInput
            label={strings.memory}
            hasValue={dossier !== null}
            input={
                <TextArea
                    value={dossier}
                    onChange={(e) => setDossier(e.target.value)}
                    autoFocus
                    style={{ width: "100%", height: "10rem", border: "none", padding: "0.5rem" }}
                />
            }
            trigger={trigger}
            onSave={updateDossier}
            saving={UpdateMemory.loading}
            saveComplete={UpdateMemory.response !== null && !UpdateMemory.loading}></ModalInput>
    );
};

const Memory = () => {
    const { user } = useAuth();

    return (
        <>
            <p>{strings.memory_about}</p>
            {user.dossier && (
                <div className="facts">
                    {user.dossier.split("\n").map((fact) => {
                        return <div className="fact">{fact}</div>;
                    })}
                </div>
            )}
            {!user.dossier && <p>{strings.facts_empty_state}</p>}
            <UpdateBiography
                trigger={
                    <Button icon="edit" content={strings.edit} style={{ margin: 0 }} size={"medium"} primary></Button>
                }></UpdateBiography>
        </>
    );
};

export default Memory;
