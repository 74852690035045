import "./Banner.css";
const Banner = ({ title, subtitle, image }) => {
    return (
        <div
            className="banner"
            style={{
                backgroundImage: image
                    ? `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url("${image}")`
                    : null,
            }}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
        </div>
    );
};

export default Banner;
