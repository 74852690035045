import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Phrase from "../components/Phrase";

const SkillsScreen = () => {
    const { language, nativeLanguage } = useLanguage();
    useTitle(strings.skills, strings.skills);

    const [skillsAPI, callSkillsAPI] = useAPI();
    const history = useHistory();

    useEffect(() => {
        callSkillsAPI("GET", `/api/languages/${language}/${nativeLanguage}/skills`);
    }, []);

    return (
        <>
            {skillsAPI.response?.map((skill) => (
                <Segment key={skill.id} onClick={() => history.push(`/skills/${skill.id}`)}>
                    <p>
                        <b>{skill.name_native}</b>
                    </p>

                    {skill.vocab?.map((phrase, index) => (
                        <>
                            <Phrase phrase={phrase} translationLanguage={nativeLanguage} inline={true} />
                            {index < skill.vocab.length - 1 ? ", " : ""}
                        </>
                    ))}
                </Segment>
            ))}
        </>
    );
};

export default SkillsScreen;
