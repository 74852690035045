import { BoxArrowDown, ClockCounterClockwise, Play, Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Segment, Tab } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import useNotifications from "../hooks/useNotifications";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import ActivitySettings from "../screens/ExerciseSettings";
import { strings } from "../utils/i18n.utils";
import { ActivityHelp } from "../components/ActivityHelpModal";
import { ActivityIcon } from "../components/ActivityIcon";
import { EmptyState } from "../components/EmptyState";
import { ZataLoader } from "../components/ZataLoader";
import {
    ActivityDescription,
    ActivityNote,
    ActivitySkills,
    ActivityTasks,
    Flashcards,
} from "../components/ActivityDetails";
import SelectActivityCollection from "../components/SelectActivityCollection";
import ButtonBar from "../components/ButtonBar";
import LoadableButton from "../components/LoadableButton";
import ActivityDeleteDialog from "../components/ActivityDeleteDialog";
import SegmentWithHeader from "../components/SegmentWithHeader";
import { PracticeActivityStatistics } from "../components/PracticeActivityStatistics";

export const ActivityScreen = () => {
    const notFoundErrorHandler = (error) => {
        if (error.status === 404) {
            return;
        }
        addNotification("api", strings.error_api, error.message);
    };
    const { user } = useAuth();
    const [{ response, error }, callAPI] = useAPI();
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });
    const [updateAPI, callUpdateAPI] = useAPI({ loading: false });

    const { addNotification } = useNotifications();
    const [activity, setActivity] = useState(null);
    const history = useHistory();
    const { activityId } = useParams();
    const { language, nativeLanguage, setLanguage } = useLanguage();
    const [taskResult] = useTask(response?.task_id);
    const { setPageTitle, setDescription } = useTitle();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function archiveActivity() {
        callDeleteAPI("PUT", `/api/activities/${activity.id}`, {
            archived: !activity.archived,
            unarchive_activity_collection: activity.archived,
        });
        setActivity({
            ...activity,
            archived: !activity.archived,
        });
    }

    useEffect(() => {
        if (response) {
            setActivity(response);
        }
    }, [response]);

    useEffect(() => {
        callAPI(
            "GET",
            `/api/activities/${activityId}?include_progress=True&native_language=${nativeLanguage}&include_vocab=True&include_practice_statistics=True`,
            null,
            null,
            notFoundErrorHandler
        );
    }, [activityId, nativeLanguage]);

    useEffect(() => {
        if (taskResult?.task) {
            if (taskResult.task.status === "COMPLETED") {
                callAPI(
                    "GET",
                    `/api/activities/${activityId}?include_progress=True&native_language=${nativeLanguage}&include_vocab=True&include_practice_statistics=True`
                );
            } else {
                setActivity(taskResult.result);
            }
        }
    }, [taskResult]);

    useEffect(() => {
        if (activity) {
            setLanguage(activity.language);
            setPageTitle(activity.name_native);

            if (activity.type === "role_play") {
                setDescription(activity.scenario_description_native);
            } else if (activity.type === "discuss") {
                // join the response.activity_tasks into a string with commas in between
                let description = "";
                for (let i = 0; i < activity.activity_tasks.length; i++) {
                    description += activity.activity_tasks[i].description_native;
                    if (i < activity.activity_tasks.length - 1) {
                        description += ", ";
                    }
                }
                setDescription(description);
            }
        }
    }, [activity]);

    const updateActivityCollection = (activityCollection) => {
        callUpdateAPI("PUT", `/api/activities/${activity.id}`, { activity_collection_id: activityCollection.id });
        setActivity({
            ...activity,
            activity_collection_id: activityCollection.id,
            activity_collection: activityCollection,
        });
    };

    const redirectAfterDelete = () => {
        history.push(`/languages/${language}/${nativeLanguage}/home`);
    };

    if (!error && !activity) return <ZataLoader message={strings.loading} />;

    if (error && error.status === 404) {
        return (
            <>
                <EmptyState
                    message={
                        <>
                            <h1>{strings.activity_not_found}</h1> <p>{strings.activity_not_found_details}</p>
                        </>
                    }
                />
            </>
        );
    }

    if (!response) {
        return <div />;
    }

    const panes = [];

    if (activity.type === "notes") {
        panes.push({
            menuItem: strings.notes,
            render: () => <ActivityNote activity={activity} />,
        });
    }

    if (["discuss", "role_play"].includes(activity.type)) {
        panes.push({
            menuItem: strings.tasks,
            render: () => (
                <div>
                    {!activity.task_id && (
                        <ActivityDescription activity={activity} user={user} setActivity={setActivity} />
                    )}
                    <ActivityTasks tasks={activity.activity_tasks} />
                </div>
            ),
        });
    }

    panes.push({
        menuItem: strings.skills,
        render: () => (
            <>
                {activity.type === "practice" && (
                    <>
                        <PracticeActivityStatistics statistics={activity.practice_statistics} />
                        <div style={{ margin: "1rem" }} />
                    </>
                )}
                <SegmentWithHeader header={strings.skills_core}>
                    <ActivitySkills skills={activity.skills} />
                </SegmentWithHeader>
                <SegmentWithHeader header={strings.skills_saved}>
                    <ActivitySkills skills={activity.skills_saved} />
                </SegmentWithHeader>
                {activity.type !== "practice" && (
                    <SegmentWithHeader header={strings.skills_from_corrections}>
                        <ActivitySkills skills={activity.skills_from_corrections} />
                    </SegmentWithHeader>
                )}
            </>
        ),
    });

    if (activity.type === "practice") {
        panes.push({
            menuItem: strings.guide,
            render: () => <ActivityHelp activity={activity} setActivity={setActivity} />,
        });
    }

    panes.push({
        menuItem: strings.exercises,
        render: () => <Flashcards url={`/api/activities/${activity.id}/flashcards`} />,
    });

    if (user && activity.user_id === user.id) {
        panes.push({
            menuItem: "Settings",
            render: () => <ActivitySettings activity={activity} />,
        });
    }

    return (
        <div>
            <div style={{ flexGrow: 4, display: "flex", alignItems: "center", margin: "0rem 0 1rem 0" }}>
                <ActivityIcon emoji={activity.emoji} size="large" />
                <div
                    style={{
                        marginLeft: "1rem",
                    }}>
                    <h1>{activity.name_native ? activity.name_native : activity.name}</h1>
                    {activity.name_native && <div>{activity.name}</div>}

                    <div className="wrapping-label-container">
                        <div className="ActivityDetailsLabel">{strings[activity.language]}</div>

                        <div className="ActivityDetailsLabel">
                            {activity.cefr_level
                                ? strings[activity.cefr_level.toLowerCase() + "_description_and_cefr"]
                                : strings.any_level}
                        </div>
                    </div>
                </div>
            </div>

            <ButtonBar>
                <Button
                    icon={<Play />}
                    content={strings.activity_start}
                    primary
                    compact
                    as={Link}
                    to={`/languages/${activity.language}/${activity.native_language}/chat?activityId=${response.id}`}
                />

                <SelectActivityCollection
                    activity={activity}
                    selectedActivityCollection={activity.activity_collection}
                    compact={true}
                    setSelectedActivityCollection={updateActivityCollection}
                />

                {user && activity.user_id === user.id && (
                    <>
                        {activity.archived && (
                            <LoadableButton
                                loading={deleteAPI.loading}
                                icon={<ClockCounterClockwise />}
                                content={strings.restore}
                                compact
                                onClick={archiveActivity}
                            />
                        )}

                        {activity.archived && (
                            <Button
                                icon={<Trash />}
                                content={strings.delete}
                                compact
                                onClick={() => setShowDeleteDialog(true)}
                            />
                        )}

                        {!activity.archived && (
                            <LoadableButton
                                loading={deleteAPI.loading}
                                icon={<BoxArrowDown />}
                                content={strings.archive}
                                compact
                                onClick={archiveActivity}
                            />
                        )}
                    </>
                )}
            </ButtonBar>

            {activity.task_id && <ZataLoader />}
            {!activity.task_id && <Tab panes={panes} menu={{ secondary: true, pointing: true }} />}

            <ActivityDeleteDialog
                activity={activity}
                setOpen={setShowDeleteDialog}
                isOpen={showDeleteDialog}
                onDelete={redirectAfterDelete}
            />
        </div>
    );
};
