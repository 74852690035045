import { strings } from "../utils/i18n.utils";
import { ActivityIcon } from "./ActivityIcon";
import EditableText from "./EditableText";

const ActivityCollectionTitle = ({ collection, saveCollectionName }) => {
    return (
        <div style={{ flexGrow: 4, display: "flex", alignItems: "center", margin: "0rem 0 1rem 0" }}>
            <ActivityIcon emoji={collection.emoji} size="large" />
            <div
                style={{
                    marginLeft: "1rem",
                }}>
                <div
                    style={{
                        width: "100%",
                        fontWeight: 800,
                        fontSize: "1.75rem",
                        lineHeight: "2rem",
                    }}>
                    <EditableText
                        text={collection.name_native ? collection.name_native : collection.name}
                        onChange={saveCollectionName}
                    />
                </div>

                <div className="wrapping-label-container">
                    <div className="ActivityDetailsLabel">{strings[collection.language]}</div>

                    <div className="ActivityDetailsLabel">
                        {collection.cefr_level
                            ? strings[collection.cefr_level.toLowerCase() + "_description_and_cefr"]
                            : strings.any_level}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityCollectionTitle;
