import { ContentWithRight } from "./Flex";
import "./Tilebar.css";
import { Checkbox, Ref, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDrag, useDrop } from "react-dnd";
import { useRef, useState, useEffect } from "react";
import { CloseIcon } from "./SvgIcons";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Button } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { X } from "@phosphor-icons/react";

export const Tilebar = ({ header, children, size, wrap = false, showTileOutlines = true, tileCount = 0 }) => {
    const { width } = useWindowDimensions();

    return (
        <div className={`Tilebar${size === "large" ? " Large" : ""}${showTileOutlines === true ? " Outline" : ""}`}>
            <div className="TilebarHeader">{header}</div>
            <div className={`TilebarContent${wrap === true ? " TilebarContentWrap" : ""}`}>
                {children}
                {/* add placeholder activity tiles so there are always a multiple of 2 or 4 depending on form factor*/}

                {width < 760 &&
                    tileCount !== null &&
                    tileCount % 2 !== 0 &&
                    [...Array(2 - (tileCount % 2))].map((_, index) => <EmptyTile key={"placeholder-" + index} />)}

                {width >= 760 &&
                    tileCount !== null &&
                    tileCount % 4 !== 0 &&
                    [...Array(4 - (tileCount % 4))].map((_, index) => <EmptyTile key={"placeholder-" + index} />)}
            </div>
        </div>
    );
};

export const EmptyTile = () => {
    return <div className="Tile Empty"></div>;
};

export const Tile = ({
    icon,
    title,
    primaryHref,
    primaryOnClick,
    children,
    onClose,
    className,
    moveTile,
    id,
    index,
    movable,
    selectable,
    select,
    selected,
    progress,
}) => {
    const history = useHistory();
    const ref = useRef(null);
    const [isSelected, setIsSelected] = useState(false);

    const [{ opacity }, drag] = useDrag(
        () => ({
            type: "CARD",
            item: () => {
                return { id, index };
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0 : 1,
            }),
        }),
        []
    );

    const [{ handlerId }, drop] = useDrop({
        accept: "CARD",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            moveTile(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    if (movable) {
        drag(drop(ref));
    }

    return (
        <Ref innerRef={ref} data-handler-id={handlerId}>
            <div className={`Tile${className ? " " + className : ""}${isSelected || selected ? " Selected" : ""}`}>
                <div
                    id={id}
                    className="TileIcon"
                    onClick={() => (primaryOnClick ? primaryOnClick() : history.push(primaryHref))}
                    style={{ cursor: "pointer", opacity: opacity }}>
                    {selectable && (
                        <div className="TileSelect">
                            <Checkbox
                                checked={isSelected}
                                onClick={(e, data) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    select && select(id, data.checked);
                                    setIsSelected(data.checked);
                                }}
                            />
                        </div>
                    )}
                    {onClose && (
                        <div className="TileMenu">
                            <CloseIcon size={"0.66rem"} icon={<X />} onClick={onClose} />
                        </div>
                    )}

                    <div className="ActualIcon">{icon}</div>

                    {progress && <div className="TileProgress">{progress}</div>}
                </div>

                {title && (
                    <div className="TileTitle">
                        <p>{title}</p>
                    </div>
                )}

                {children && <div className="TileChildren">{children}</div>}
            </div>
        </Ref>
    );
};

export const DeletingTile = ({ onDeleteStart, onDeleteEnd, onDeleteUndo }) => {
    const timerRef = useRef(null);

    useEffect(() => {
        onDeleteStart && onDeleteStart();
        const timer = setTimeout(() => {
            onDeleteEnd && onDeleteEnd();
        }, 3000);

        timerRef.current = timer;

        return () => {
            clearTimeout(timer);
            timerRef.current = null;
        };
    }, []);

    function undoDelete() {
        onDeleteUndo && onDeleteUndo();
    }

    return (
        <Tile>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexGrow: 1,
                }}>
                <p>{strings.activity_removed}</p>
                <p>
                    <Button onClick={undoDelete}>{strings.undo}</Button>
                </p>
            </div>
        </Tile>
    );
};
