import { Button, Divider, Grid, GridColumn, GridRow } from "semantic-ui-react";
import Banner from "../components/Banner";
import { strings } from "../utils/i18n.utils";

export function ProductOverview() {
    return (
        <>
            <div id="overview" />
            <Banner
                title={"Your AI language workspace"}
                subtitle="Why limit your language learning to a preset course? Organize, study and review any topic you want with Jetway."
            />
            <Grid stackable columns={2}>
                <HeaderAndDescription2
                    header={"Organize your language learning"}
                    description={<div>Create activities using AI or class notes around your goals and interests.</div>}
                    screenshot={"./product/home.png"}
                />
                <HeaderAndDescription2
                    header={"AI powered chats and exercises"}
                    description={
                        <div>
                            Immersive activities that help you practice speaking about any topic that interests you.
                        </div>
                    }
                    screenshot={"./product/exercise-question.png"}
                />
                <Divider />
                <HeaderAndDescription2
                    header={"Personalized feedback"}
                    description={
                        <div>
                            Jetway identifies and explains your mistakes in the context of real world conversations,
                            helping you quickly learn self-correct.
                        </div>
                    }
                    screenshot={"./product/correction.png"}
                />
                <Divider />
                <HeaderAndDescription2
                    header={"Generate study exercises from notes"}
                    description={
                        <div>
                            Turn your class notes, vocabulary lists, podcast/TV notes or anything else into personalized
                            exercises in seconds.
                        </div>
                    }
                    screenshot={"./product/note.png"}
                />
                <Divider />
                <HeaderAndDescription2
                    header={"Intelligent spaced repetition"}
                    description={
                        <div>
                            Jetway uses spaced repetition to help you remember what you’ve learned. Jetway learns what
                            you know and what you don’t, adapting exercises for accelerated learning.
                        </div>
                    }
                    screenshot={"./product/skills.png"}
                />
                <Divider />
                <HeaderAndDescription2
                    header={"Track your progress"}
                    description={
                        <div>
                            Track your streak and output time so you be sure you're giving your language the attention
                            it needs.
                        </div>
                    }
                    screenshot={"./product/stats.png"}
                />
            </Grid>
            <Banner
                title={"Ready to start?"}
                subtitle={
                    <Button
                        content={strings.get_started}
                        as={"a"}
                        href={`/login`}
                        primary
                        active
                        className="wide"
                        size={"large"}
                    />
                }
            />
        </>
    );
}

function HeaderAndDescription2({ header, description, screenshot }) {
    return (
        <GridRow>
            <GridColumn
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                <h2>{header}</h2>
                {description}
            </GridColumn>
            <GridColumn
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                {screenshot && <img className="screenshot" src={screenshot} style={{ maxWidth: "260px" }} />}
            </GridColumn>
        </GridRow>
    );
}
