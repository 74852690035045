import { Segment } from "semantic-ui-react";
import "./SegmentWithHeader.css";

const SegmentWithHeader = ({ header, style, children, id }) => {
    return (
        <Segment id={id} className="with-header" style={style}>
            {header && <div className="header">{header}</div>}
            <div className="content">{children}</div>
        </Segment>
    );
};

export default SegmentWithHeader;
