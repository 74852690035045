import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Flashcard } from "../components/ActivityDetails";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { useQuery } from "../hooks/useQuery";

const Exercises = () => {
    const [exerciseAPI, callExerciseAPI] = useAPI();
    const { language, nativeLanguage } = useParams();

    const { setNavigationTitle } = useTitle();
    const [exercises, setExercises] = useState([]);
    const limit = 20;
    const query = useQuery();

    function loadMore() {
        console.log("Loading more at", exercises.length);
        const skillId = query.get("skillId");
        let url = `/api/languages/${language}/${nativeLanguage}/flashcards?limit=${limit}&offset=${exercises.length}`;
        if (skillId) {
            url += `&skill_id=${skillId}`;
        }
        callExerciseAPI("GET", url);
    }

    function refresh() {
        setExercises([]);
        callExerciseAPI("GET", `/api/languages/${language}/${nativeLanguage}/flashcards?limit=${limit}&offset=0`);
    }

    useEffect(() => {
        if (exerciseAPI.response) {
            setExercises((exercises) => [...exercises, ...exerciseAPI.response]);
        }
    }, [exerciseAPI.response]);

    useEffect(() => {
        loadMore();
    }, []);

    setNavigationTitle(strings.exercises);

    return (
        <div>
            <InfiniteScroll
                dataLength={exercises.length}
                next={loadMore}
                hasMore={exerciseAPI.loading || (exerciseAPI.response && exerciseAPI.response.length === limit)}
                loader={<ZataLoader />}
                endMessage={<p>No more!</p>}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {exercises.map((exercise) => (
                    <Flashcard flashcard={exercise} phrase={exercise.phrase} />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default Exercises;
