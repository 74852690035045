import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import ArchivedActivityCollection from "../components/ArchivedActivityCollection";
import { Tab } from "semantic-ui-react";
import ArchivedActivity from "../components/ArchivedActivity";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const ArchivedActivities = () => {
    const { language, nativeLanguage } = useLanguage();

    const [activitiesAPI, callActivitiesAPI] = useAPI();

    useEffect(() => {
        if (language) {
            callActivitiesAPI(
                "GET",
                `/api/activities?include_archived=true&include_non_archived=false&language=${language}&native_language=${nativeLanguage}&limit=10`
            );
        }
    }, [language]);

    function updateActivity(activity) {
        // update array on collectionsAPI.response
        const updatedActivities = activitiesAPI.response.map((c) => {
            if (c.id === activity.id) {
                return activity;
            }
            return c;
        });
        activitiesAPI.setResponse(updatedActivities);
    }

    function removeActivityFromList(collection) {
        const updatedActivities = activitiesAPI.response.filter((c) => c.id !== collection.id);
        activitiesAPI.setResponse(updatedActivities);
    }

    return (
        <div>
            {activitiesAPI.response?.map((activity) => {
                return (
                    <ArchivedActivity
                        key={activity.id}
                        activity={activity}
                        updateActivity={updateActivity}
                        removeActivityFromList={removeActivityFromList}
                    />
                );
            })}
        </div>
    );
};

const ArchivedCollections = () => {
    const { language, nativeLanguage } = useLanguage();

    const [collectionsAPI, callCollectionsAPI] = useAPI();

    useEffect(() => {
        if (language) {
            callCollectionsAPI(
                "GET",
                `/api/activity_collections?include_archived=true&include_non_archived=false&language=${language}&native_language=${nativeLanguage}&limit=10`
            );
        }
    }, [language]);

    function updateCollection(collection) {
        // update array on collectionsAPI.response
        const updatedCollections = collectionsAPI.response.map((c) => {
            if (c.id === collection.id) {
                return collection;
            }
            return c;
        });
        collectionsAPI.setResponse(updatedCollections);
    }

    function removeCollectionFromList(collection) {
        const updatedCollections = collectionsAPI.response.filter((c) => c.id !== collection.id);
        collectionsAPI.setResponse(updatedCollections);
    }

    return (
        <div>
            {collectionsAPI.response?.map((collection) => {
                return (
                    <ArchivedActivityCollection
                        key={collection.id}
                        collection={collection}
                        updateCollection={updateCollection}
                        removeCollectionFromList={removeCollectionFromList}
                    />
                );
            })}
        </div>
    );
};

const ArchivedCollectionsScreen = () => {
    useTitle(strings.archived_items, strings.archived_items);
    const tabIds = ["lists", "activities"];
    const { tabId } = useParams();
    const panes = [
        { menuItem: strings.lists, render: () => <ArchivedCollections /> },
        { menuItem: strings.activities, render: () => <ArchivedActivities /> },
    ];
    const { language, nativeLanguage } = useLanguage();

    return (
        <Tab
            panes={panes}
            activeIndex={tabIds.includes(tabId) ? tabIds.indexOf(tabId) : 0}
            onTabChange={(e, { activeIndex }) => {
                window.location.href = `/languages/${language}/${nativeLanguage}/archive/${tabIds[activeIndex]}`;
            }}
            menu={{ secondary: true, pointing: true }}
        />
    );
};

export default ArchivedCollectionsScreen;
