import { useContext } from "react";
import { SidebarProviderContext } from "../providers/SidebarProvider";

function useSidebar() {
    const { showSidebar, setShowSidebar } = useContext(SidebarProviderContext);

    return {
        showSidebar,
        setShowSidebar,
    };
}

export default useSidebar;
