import { Archive, Flag, Trash } from "@phosphor-icons/react";
import { createElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Dropdown, Form, Icon, Label, Progress, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";
import { timeSince } from "../utils/time.utils";
import { currentLocation } from "../utils/url.utils";
import "./ActivityDetails.css";
import { ClickableIcon } from "./ClickableIcon";
import { EmptyState } from "./EmptyState";
import Phrase from "./Phrase";
import { PracticeActivityStatistics } from "./PracticeActivityStatistics";
import SkillSegment from "./SkillSegment";
import { GrammarStatusBar, TextEditor } from "./TextEditor";
import { ZataLoader } from "./ZataLoader";

export const LevelBadge = ({ level }) => {
    return (
        <Label basic size="small">
            {level ? strings[level.toLowerCase() + "_description_and_cefr"] : strings.any_level}
        </Label>
    );
};

export const ActivityTypeLabel = ({ type }) => {
    return (
        <Label basic size="small">
            {strings["activity_type_" + type]}
        </Label>
    );
};

export const Activity = ({ activity, onArchive, showActivityType = false }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function deleteActivity() {
        callDeleteAPI("DELETE", `/api/activities/${activity.id}`);
    }

    useEffect(() => {
        if (deleteAPI.response) {
            onArchive && onArchive(deleteActivity);
        }
    }, [deleteAPI.response]);

    return (
        <div className="Activity">
            <a href={`/activities/${activity.id}?back=${currentLocation()}`}>
                {activity.emoji} {activity.name_native ? activity.name_native : activity.name}
            </a>
            <div>
                {activity.last_session &&
                    activity.last_session.activity_mastered !== true &&
                    activity.last_session.skills_mastered !== null &&
                    activity.last_session.progress !== null &&
                    activity.last_session.progress !== 0 &&
                    activity.last_session.progress !== 1 && (
                        <Progress value={activity.last_session.progress} total={1} size="tiny" />
                    )}
            </div>
            <div className="wrapping-label-container">
                <ActivityTypeLabel type={activity.type} />
                {activity.last_session?.activity_mastered === true && (
                    <Label basic size="small" className="completed">
                        Completed
                    </Label>
                )}
                {activity.statistics?.flashcards_needing_review > 0 && (
                    <Label basic size="small">
                        📝 {activity.statistics?.flashcards_needing_review}
                    </Label>
                )}
            </div>
        </div>
    );
};

export function ActivitySelector({ squidgy, selectedId, activities }) {
    const [internalSelectedId, setInternalSelectedId] = useState(selectedId);
    const [, callChangeActivityAPI] = useAPI();

    const activityOptions = activities.map((cl) => ({
        key: cl.id,
        value: cl.id,
        text: cl.name,
    }));

    function change(e, data) {
        callChangeActivityAPI("PUT", `/api/jetway/${squidgy.id}`, {
            activity_id: data.value,
        });
        setInternalSelectedId(data.value);
    }

    return (
        <Dropdown value={internalSelectedId} options={activityOptions} onChange={change} inline className="icon tiny" />
    );
}

export const ActivityNote = ({ activity }) => {
    const history = useHistory();
    const { user } = useAuth();

    return (
        <Segment
            onClick={
                user &&
                user.id === activity.user_id &&
                (() => history.push(`/notes/${activity.note.id}?back=${currentLocation()}`))
            }>
            {createElement("span", {
                dangerouslySetInnerHTML: { __html: activity.note.text.replace(/\n/g, "<br>") },
            })}
            {!activity.note.text && <i>Empty note</i>}
        </Segment>
    );
};

export const defaultOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
    },
};

export const ActivityDescription = ({ activity, setActivity }) => {
    const [tasks, setTasks] = useState([]);
    const [languageSkills, setLanguageSkills] = useState([]);
    const { user } = useAuth();

    useEffect(() => {
        if (activity.activity_tasks) {
            setTasks(activity.activity_tasks.filter((t) => ["task"].includes(t.type)));
            setLanguageSkills(activity.skills);
        }
    }, [activity]);

    if (tasks.length === 0 && languageSkills.length === 0 && !activity.description) {
        return <div />;
    }

    return (
        <div>
            {activity.description_native && <p>{activity.description_native}</p>}
            {activity.scenario_description_native && <p>{activity.scenario_description_native}</p>}

            {activity.type === "review" && activity.statistics?.flashcards_needing_review > 0 && (
                <p>
                    <ul>
                        <li>
                            📝 {activity.statistics?.flashcards_total} {strings.flashcards_total}
                        </li>
                        <li>
                            &#x23F0;&nbsp;{activity.statistics?.flashcards_needing_review}{" "}
                            {strings.flashcards_needing_review}
                        </li>
                    </ul>
                </p>
            )}
        </div>
    );
};

export const ActivitySkills = ({ skills }) => {
    return (
        <>
            {(skills === null || skills.length === 0) && strings.activity_no_skills}
            {skills?.map((skill) => (
                <SkillSegment skill={skill} />
            ))}
        </>
    );
};

export const ActivityTasks = ({ tasks }) => {
    if (!tasks || tasks.length === 0) {
        return null;
    }
    return (
        <Segment>
            <p>
                {tasks.map((t) => (
                    <div>
                        {toCoolEmojis("✅")} {t.description_native ? t.description_native : t.description}
                    </div>
                ))}
            </p>
        </Segment>
    );
};

export const Flashcards = ({ url, phrase = null }) => {
    const [flashcardsAPI, callFlashcardsAPI] = useAPI();

    useEffect(() => {
        callFlashcardsAPI("GET", url);
    }, []);

    function removeFlashcard(flashcard) {
        var flashcards = flashcardsAPI.response.filter((f) => f.id !== flashcard.id);
        flashcardsAPI.setResponse(flashcards);
    }

    return (
        <>
            {flashcardsAPI.loading && <ZataLoader />}
            {flashcardsAPI.response &&
                flashcardsAPI.response.map((flashcard) => (
                    <Flashcard
                        flashcard={flashcard}
                        phrase={phrase ? phrase : flashcard.phrase}
                        onDelete={removeFlashcard}
                    />
                ))}

            {flashcardsAPI.response && flashcardsAPI.response.length === 0 && (
                <EmptyState message={strings.activities_exercises_empty_state} />
            )}
        </>
    );
};

export const Flashcard = ({ flashcard, phrase, onDelete }) => {
    let { nativeLanguage } = useLanguage();

    return (
        <Segment>
            <Phrase
                phrase={phrase}
                activityId={flashcard.activity_id}
                blanks={flashcard.cloze_answer_word_ids}
                allowFullTranslation={true}
                translationLanguage={nativeLanguage}
                additionalActions={<FlashcardActions flashcard={flashcard} onDelete={onDelete} />}
            />
            {flashcard.next_review && !flashcard.next_review?.startsWith("9999") && (
                <Label basic size="small">
                    &#x23F0;&nbsp;{" "}
                    {new Date(flashcard.next_review) > Date.now()
                        ? timeSince(flashcard.next_review)
                        : "Ready for Review"}
                </Label>
            )}
        </Segment>
    );
};

export const FlashcardActions = ({ flashcard, onDelete }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function deleteFlashcard(flashcardId) {
        callDeleteAPI("DELETE", `/api/flashcards/${flashcardId}`);
    }

    useEffect(() => {
        if (deleteAPI.response && onDelete) {
            onDelete(flashcard);
        }
    }, [deleteAPI.response]);

    if (deleteAPI.loading) {
        return <p>Deleting...</p>;
    }

    return (
        <>
            <Button
                compact
                icon={<Archive />}
                size={"small"}
                content={strings.archive}
                onClick={() => deleteFlashcard(flashcard.id)}
            />
        </>
    );
};
