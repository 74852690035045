import { bestBackgroundColor } from "../utils/color.utils";
import { toCoolEmojis } from "../utils/emoji.utils";
import "./ActivityIcon.css";
export const ActivityIcon = ({ emoji, size = "medium" }) => {
    return (
        <span className={"ActivityIcon " + size} style={{ backgroundColor: bestBackgroundColor(emoji) }}>
            {toCoolEmojis(emoji)}
        </span>
    );
};
