import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import "./PromptHints.css";
import { Loader } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { HelpText } from "./HelpText";
import { ClickableIcon } from "./ClickableIcon";
import { LazyPhrase } from "./LazyPhrase";
import { useEventListener } from "../hooks/useEventListener";
import { MagicWand, X } from "@phosphor-icons/react";

const PromptHint = ({ prompt, hint, setActiveHint, hide }) => {
    const [showExample, setShowExample] = useState(false);
    const [hideWords, setHideWords] = useState(true);

    useEffect(() => {
        if (showExample) {
            setActiveHint(hint);
            setHideWords(false);
        } else {
            setActiveHint(null);
        }
    }, [showExample]);

    return (
        <div className={showExample ? "PromptHint FullWidth" : "PromptHint"} style={{ display: hide ? "none" : null }}>
            <div className="PromptHintContent">
                <div style={{ flexGrow: 1 }}>
                    <HelpText
                        content={hint.hint_html}
                        activityId={prompt.activity_id}
                        hideWords={hideWords}
                        wordsUnhidden={() => setShowExample(true)}
                    />
                </div>
                {!showExample && (
                    <ClickableIcon
                        icon={<MagicWand />}
                        onClick={(e) => {
                            setShowExample(true);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    />
                )}
                {showExample && (
                    <ClickableIcon
                        icon={<X />}
                        onClick={(e) => {
                            setShowExample(false);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    />
                )}
            </div>
            {showExample && <HintExample prompt={prompt} activeHint={hint.hint} />}
            <div className="PromptHintSpacer"></div>
        </div>
    );
};

export const HintExample = ({ prompt, activeHint }) => {
    const [exampleAPI, callExampleAPI] = useAPI({ loading: true });

    useEffect(() => {
        callExampleAPI("POST", `/api/prompts/${prompt.id}/suggest_phrase_example`, {
            hint: activeHint,
        });
    }, []);

    return (
        <div className="PromptHintExample">
            {exampleAPI.loading && (
                <>
                    <Loader inline size={"tiny"} /> {strings.prompt_hints_creating_example}
                </>
            )}
            {!exampleAPI.loading && !exampleAPI.error && (
                <>
                    ✨ &nbsp;
                    <LazyPhrase activityId={prompt.activity_id} text={exampleAPI.response.example} hideWords={false} />
                </>
            )}
        </div>
    );
};

export const PromptHints = ({ prompt, lastMessage, onResize }) => {
    const [hintsAPI, callHintsAPI] = useAPI();
    const [activeHint, setActiveHint] = useState(null);

    useEventListener(
        "scroll",
        (event) => {
            // if the PromptHint is off the screen to right or left then add a CSS class called
            //  PromptHint-overflow-right" or "PromptHint-overflow-left" so we can fade in/out
            const hintsElement = document.getElementById("PromptHints");

            hintsElement.classList.toggle("PromptHint-overflow-left", hintsElement.scrollLeft !== 0);
            hintsElement.classList.toggle(
                "PromptHint-overflow-right",
                hintsElement.scrollLeft + hintsElement.clientWidth < hintsElement.scrollWidth - 1
            );
        },
        document.getElementById("PromptHints")
    );

    useEffect(() => {
        if (prompt) {
            setActiveHint(null);
            hintsAPI.setResponse(null);
            callHintsAPI("POST", `/api/prompts/${prompt.id}/suggest_phrases`);
        }
    }, [prompt, lastMessage]);

    useEffect(() => {
        if (hintsAPI.response) {
            onResize();
        }
    }, [hintsAPI.response]);

    return (
        <div className="PromptHints" id="PromptHints">
            {hintsAPI.loading && (
                <div className="PromptHintsLoader">
                    <Loader active={true} inline={true} size="tiny" />
                    &nbsp;{strings.prompt_creating_hints}
                </div>
            )}
            {hintsAPI.response?.map((hint) => (
                <PromptHint
                    hint={hint}
                    prompt={prompt}
                    setActiveHint={setActiveHint}
                    hide={activeHint !== null && activeHint !== hint}
                />
            ))}
        </div>
    );
};
