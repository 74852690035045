import { Button, Segment } from "semantic-ui-react";
import { Row, StackableRow } from "./Flex";
import { Trash } from "@phosphor-icons/react";
import { currentLocation } from "../utils/url.utils";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { strings } from "../utils/i18n.utils";
import LoadableButton from "./LoadableButton";
import useAPI from "../hooks/useAPI";
import { useEffect, useState } from "react";
import ActivityCollectionDeleteDialog from "./ActivityCollectionDeleteDialog";

const ArchivedActivityCollection = ({ collection, updateCollection, removeCollectionFromList }) => {
    const [updateAPI, callUpdateAPI] = useAPI();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function restoreCollection() {
        callUpdateAPI("PUT", `/api/activity_collections/${collection.id}`, { archived: !collection.archived });
    }

    useEffect(() => {
        if (updateAPI.response) {
            updateCollection(updateAPI.response);
        }
    }, [updateAPI.response]);

    return (
        <Segment>
            <StackableRow>
                <Link to={`/collections/${collection.id}?back=${currentLocation()}`}>{collection.name}</Link>
                <div style={{ flexGrow: 0, width: "unset", display: "flex", alignItems: "center" }}>
                    {collection.archived && (
                        <>
                            <Button icon={<Trash />} compact onClick={() => setShowDeleteDialog(true)}></Button>
                            <LoadableButton
                                primary
                                compact
                                onClick={restoreCollection}
                                content={strings.restore}
                                loading={updateAPI.loading}
                            />
                        </>
                    )}
                    {!collection.archived && (
                        <LoadableButton
                            primary
                            compact
                            onClick={restoreCollection}
                            content={strings.archive}
                            loading={updateAPI.loading}
                        />
                    )}
                </div>

                <ActivityCollectionDeleteDialog
                    collection={collection}
                    setOpen={setShowDeleteDialog}
                    isOpen={showDeleteDialog}
                    onDelete={removeCollectionFromList}
                />
            </StackableRow>
        </Segment>
    );
};

export default ArchivedActivityCollection;
