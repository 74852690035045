import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { Statistic, StatisticsBar, StatisticsVerticallySplitRow } from "./StatisticsBar";
import { Checkbox, Divider, Form } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import LevelSelector from "../components/LevelSelector";

export function LevelBar({ level }) {
    const { statistics, languageSettings, setLanguageSettings } = useAuth();
    const { language, nativeLanguage } = useParams();
    const [, callLanguageSettingsAPI] = useAPI();

    function updateUseSelfReportedLevel(checked) {
        const updatedLanguageSettings = { ...languageSettings, use_self_reported_level: checked };

        if (!updatedLanguageSettings.self_reported_level) {
            updatedLanguageSettings.self_reported_level = "A2";
        }
        updateLanguageSettings(updatedLanguageSettings);
    }

    function changeLevel(level) {
        languageSettings.self_reported_level = level;
        updateLanguageSettings(languageSettings);
    }

    function updateLanguageSettings(updatedLanguageSettings) {
        console.log("updateLanguageSettings", updatedLanguageSettings);
        callLanguageSettingsAPI(
            "PUT",
            `/api/languages/${language}/${nativeLanguage}/settings`,
            updatedLanguageSettings
        );
        setLanguageSettings(updatedLanguageSettings);
    }

    return (
        <>
            <p>{strings.formatString(strings.level_explanation, language, nativeLanguage)}</p>
            <Form>
                <Form.Field>
                    <Checkbox
                        toggle
                        label={strings.setting_use_self_reported_level}
                        checked={!languageSettings.use_self_reported_level}
                        onChange={(e, { checked }) => updateUseSelfReportedLevel(!checked)}></Checkbox>
                </Form.Field>
                {languageSettings.use_self_reported_level && (
                    <>
                        {strings.my_level_is}
                        <LevelSelector
                            defaultLevel={languageSettings.self_reported_level || "A2"}
                            onChange={changeLevel}
                        />
                        <Divider />
                    </>
                )}
            </Form>
            {!languageSettings.use_self_reported_level && (
                <StatisticsBar>
                    {statistics.estimated_cefr_level && (
                        <StatisticsVerticallySplitRow
                            left={
                                <>
                                    <Statistic
                                        statistic={
                                            strings[statistics.estimated_cefr_level.toLowerCase() + "_description"]
                                        }
                                    />
                                    <p>
                                        {strings.formatString(
                                            strings.cefr_explanation,
                                            strings[statistics.estimated_cefr_level.toLowerCase()]
                                        )}
                                    </p>
                                </>
                            }
                            right={strings["level_" + statistics.estimated_cefr_level.toLowerCase() + "_you"]}
                        />
                    )}
                    {!statistics.estimated_cefr_level && (
                        <p>{strings.formatString(strings.level_unavailable, strings[language])}</p>
                    )}
                </StatisticsBar>
            )}
        </>
    );
}

export default LevelBar;
