import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import useNotifications from "./useNotifications";
import { strings } from "../utils/i18n.utils";

const useTask = (task_id) => {
    const [taskAPI, callTaskAPI] = useAPI({ loading: false });
    const [taskResult, setTaskResult] = useState(null);
    const [attempts, setAttemts] = useState(0);
    const { addNotification } = useNotifications();

    useEffect(() => {
        if (taskAPI.response) {
            if (taskAPI.response.task.status === "QUEUED" && attempts < 5) {
                console.log("Task is queued, retrying", attempts);
                setAttemts(attempts + 1);
            } else {
                setTaskResult(taskAPI.response);
            }
        }
    }, [taskAPI.response]);

    useEffect(() => {
        if (task_id) {
            console.log("Starting task polling");
            let debounce = setTimeout(() => {
                // swallow errors
                callTaskAPI("GET", `/api/tasks/${task_id}`, null, null, (error) => {
                    if (error.status !== 403) {
                        addNotification("api", strings.error_api, error.message);
                    }
                });
            }, 0);

            return () => clearTimeout(debounce);
        }
    }, [task_id, attempts]);

    return [taskResult];
};

export default useTask;
