import React from "react";
const SkillStrength = ({ strength, numOfBars }) => {
    const bars = Array.from({ length: numOfBars }, (_, index) => (
        <span
            key={index}
            style={{
                width: "3px",
                height: "calc( 1rem *  " + index + " / " + numOfBars + ")",
                backgroundColor: index < strength ? "green" : "#ccc",
                margin: "1px",
            }}
        />
    ));

    return (
        <span style={{ display: "inline-flex", flexDirection: "row", alignItems: "baseline", marginRight: "0.5rem" }}>
            {bars}
        </span>
    );
};

export default SkillStrength;
