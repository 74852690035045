import { House, Question, ThumbsDown } from "@phosphor-icons/react";
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { Button, Container, Form, Loader } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { useAudio } from "../hooks/useAudio";
import useAuth from "../hooks/useAuth";
import { useQuery } from "../hooks/useQuery";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import useWalkthrough from "../hooks/useWalkthrough";
import { strings } from "../utils/i18n.utils";
import { isMobile } from "../utils/platform.utils";
import { ActivityProgressBar } from "./ActivityProgressBar";
import {
    AdvancingChat,
    AdvancingChatContext,
    Chat,
    ChatIconLeft,
    ChatIconRight,
    ChatInput,
    ChatMessageLeft,
    ChatMessageRight,
    ChatRowLeft,
    ChatRowRight,
    ChatWaitingIndiciator,
} from "./Chat";
import Phrase from "./Phrase";
import PromptButtonBar from "./PromptButtonBar";
import { PromptInputBar } from "./PromptInputBar";
import { TopBar } from "./TopBar";
import { toCoolEmojis } from "../utils/emoji.utils";
import AudioPhrase from "./AudioPhrase";
import { LazyPhrase } from "./LazyPhrase";

const DEFAULT_PROMPT_CONTEXT = {
    missedWordIds: [],
    grammarCorrectedWordIds: [],
    unknownWordIds: [],
    ignoredGrammarCorrections: [],
};

export function Prompt({ promptType }) {
    let { language, nativeLanguage } = useParams();
    const history = useHistory();
    useTitle(strings.chat_title);
    const location = useLocation();

    const [promptAPI, callPromptAPI] = useAPI();
    const [replyAPI, callReplyAPI] = useAPI({ loading: false });
    const [sendCommandAPI, callSendCommandAPI] = useAPI({ loading: false });
    const [_, callAcceptGrammrCorrectionAPI] = useAPI({ loading: false });

    const [disabled, setDisabled] = useState(true);
    const [count, setCount] = useState(0);
    const [error, setError] = useState(null);
    const [replacedValue, setReplacedValue] = useState(null);
    const [activitySession, setActivitySession] = useState(null);
    const [scoredReply, setScoredReply] = useState(null);
    const [answer, setAnswer] = useState("");
    const [isAllMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [showDiffs, setShowDiffs] = useState(false);
    const [giveUp, setGiveUp] = useState(false);
    const [forceCorrect, setForceCorrect] = useState(false);
    const [complete, setComplete] = useState(false);
    const [grammarCorrections, setGrammarCorrections] = useState([]);
    const [promptContext, setPromptContext] = useState(DEFAULT_PROMPT_CONTEXT);
    const [prompt, setPrompt] = useState(null);
    const [noSpeechFound, setNoSpeechFound] = useState(false);
    const [progress, setProgress] = useState({ steps_completed: 0, steps_total: 0 });

    const [inFocus, setInFocus] = useState(null);
    const [chatKey, setChatKey] = useState([]);
    const [textMode, setTextMode] = useState(false);

    const [messages, setMessages] = useState([]);
    const [lastMessage, setLastMessage] = useState(null);
    const [taskResult] = useTask(lastMessage?.task_id);

    const nextButtonView = useInView();
    const giveUpRef = useRef(null);
    const endRef = useRef(null);

    const { textEditorView } = useWalkthrough();
    const { loadAudio, unloadAudio, stop: stopAudio } = useAudio();

    let query = useQuery();

    useEffect(() => {
        // The query parameters aren't relevant anymore.
        if (count > 0 && Array.from(query.keys()).length > 0) {
            history.push(`/languages/${language}/${nativeLanguage}/chat`);
            return;
        }

        setReplacedValue(null);
        setPrompt(null);
        setPromptContext({
            missedWordIds: [],
            grammarCorrectedWordIds: [],
            unknownWordIds: [],
            ignoredGrammarCorrections: [],
        });
        setComplete(false);
        stopAudio();

        // the language can be null during the onboarding flow
        // in which case, we should call the start URL
        let url = language
            ? `/api/languages/${language}/${nativeLanguage}/prompts/next?`
            : `/api/prompts/start/${nativeLanguage}?`;

        const params = [];

        params.push(`auto_advance_to_next_session=${count === 0 ? "true" : "false"}`);
        if (query.get("activityId")) {
            params.push(`activity_id=${query.get("activityId")}`);
        }

        if (query.get("activitySessionId")) {
            params.push(`activity_session_id=${query.get("activitySessionId")}`);
        }

        let newActivityType = query.get("activityType");
        if (newActivityType) {
            params.push(`activity_type=${newActivityType}`);
        }

        if (query.get("promptDataId")) {
            params.push(`prompt_data_id=${query.get("promptDataId")}`);
        }

        if (query.get("clear") === "true") {
            params.push(`clear_prompts=true`);
        }

        url += params.join("&");

        console.log("Prompt: next", language, nativeLanguage, count, query, location.key);
        callPromptAPI("GET", url, null, null, handleAPIError);
    }, [language, nativeLanguage, count, query, location.key]);

    function advance() {
        setCount(count + 1);
    }

    useEffect(() => {
        if (promptAPI.error && promptAPI.error.status === 404) {
            history.push(`/languages/${language}/${nativeLanguage}/home`);
        }
    }, [promptAPI.error]);

    useEffect(() => {
        stopAudio();
        if (promptAPI.response) {
            setPrompt(promptAPI.response);
        }
    }, [promptAPI.response]);

    useEffect(() => {
        if (prompt) {
            setTextMode(["any", "writing"].includes(prompt.activity.input_mode) || prompt.prompt_type === "review");
            setMessages(prompt.messages);
            setActivitySession(prompt.activity_session);
            setGiveUp(false);
            setAnswer("");
            setPromptContext({
                missedWordIds: [],
                grammarCorrectedWordIds: [],
                unknownWordIds: [],
                ignoredGrammarCorrections: [],
            });
            setProgress({
                steps_completed: prompt.activity_session.steps_completed,
                steps_total: prompt.activity_session.steps_total,
            });
        }
    }, [prompt]);

    useEffect(() => {
        if (messages?.length > 0) {
            setLastMessage(messages[messages.length - 1]);
        }
    }, [messages]);

    const submit = (text, skip_corrections = false) => {
        if (answer || text) {
            if (complete) {
                advanceToNextExercise();
            } else {
                sendReply(text, null, skip_corrections);
            }
        }
    };

    const advanceToNextExercise = () => {
        console.log("Prompt: advanceToNextExercise");
        if (!showDiffs && scoredReply && scoredReply.explanations && scoredReply.explanations.length > 0) {
            console.log("Prompt: Showing Diffs");
            setShowDiffs(true);
            setChatKey([replyAPI.response, giveUp]);
            return;
        }

        unloadAudio();
        advance();

        document.getElementById("content").classList.remove("chat");
    };

    useEffect(() => {
        if (nextButtonView.entry) {
            document.getElementById("next-button")?.focus();
        }
    }, [nextButtonView.entry]);

    useEffect(() => {
        if (textEditorView.entry) {
            document.getElementById("editable")?.focus();
        }
    }, [textEditorView.entry]);

    const doForceCorrect = (e) => {
        setForceCorrect(true);
    };

    useEffect(() => {
        if (forceCorrect) {
            sendReply(answer);
        }
    }, [forceCorrect]);

    const onSpeechSubmit = (base64Audio) => {
        sendReply(null, base64Audio, true);
    };

    const sendReply = (text, audio, skip_corrections = false) => {
        stopAudio();

        setDisabled(true);

        // this enables auto play
        loadAudio();

        if (text) {
            let sanitizedText = sanitizeHtml(text || answer, {
                allowedTags: [],
                allowedAttributes: {},
            })
                .replace(/\u00a0/g, " ")
                .replace("\u200b", "");

            let spaceCount = 0;
            while (sanitizedText[spaceCount] === " ") {
                spaceCount++;
            }

            sanitizedText = sanitizedText.substring(spaceCount);
            setReplacedValue(sanitizedText);
        }

        let newMessages = [...messages];
        newMessages.push({
            id: newMessages.length,
            message: text,
            transcribing: audio != null,
            from_user: true,
        });
        setMessages(newMessages);

        let payload = {
            message: text,
            message_audio: audio,
            give_up: giveUp,
            force_correct: forceCorrect,
            unknown_word_ids: promptContext.unknownWordIds,
            grammar_corrections_by_word: promptContext.grammarCorrectedWordIds,
            ignored_grammar_corrections: promptContext.ignoredGrammarCorrections,
            skip_corrections: skip_corrections === true ? true : false,
        };

        let url = `/api/prompts/${prompt.id}/reply`;

        callReplyAPI("POST", url, payload, null, handleAPIError);
        updateChatKeyToScroll();
    };

    function sendCommand(command, commandLabel, actionId, messageId) {
        if (command === "go:activities") {
            history.push(`/activities`);
            return;
        }

        if (command === "show") {
            doGiveUp();
            return;
        }

        console.log("Sending command", command, commandLabel);
        let payload = {
            message: command,
            store_score: true,
            give_up: false,
            force_correct: false,
            unknown_word_ids: promptContext.unknownWordIds,
            grammar_corrections_by_word: promptContext.grammarCorrectedWordIds,
            ignored_grammar_corrections: promptContext.ignoredGrammarCorrections,
        };

        let url = `/api/prompts/${prompt.id}/command/${command}`;

        if (messageId) url += `?prompt_message_id=${messageId}`;

        callSendCommandAPI("POST", url, payload, null, handleAPIError);
        updateChatKeyToScroll();

        // if we're regenerating, remove all messages that were generated by the system
        let newMessages = [...messages];
        if (["regenerate", "ignore_correction"].includes(command)) {
            // loop through messages in reverse
            for (let i = newMessages.length - 1; i >= 0; i--) {
                if (newMessages[i].from_user) {
                    break;
                } else {
                    // remove message
                    newMessages.splice(i, 1);
                }
            }
            setMessages(newMessages);
        } else if (!messageId) {
            newMessages.push({
                id: newMessages.length,
                message: commandLabel,
                from_user: true,
            });
            setMessages(newMessages);
        }
    }

    useEffect(() => {
        if (sendCommandAPI.response) {
            if (sendCommandAPI.response.advance) {
                advance();
            } else if (sendCommandAPI.response.replies && sendCommandAPI.response.replies.length > 0) {
                // small hack to keep the score across requests when doing an explanation
                for (const reply of sendCommandAPI.response.replies) {
                    if (scoredReply && reply.id === scoredReply.id) {
                        reply.score = scoredReply.score;
                    }
                }

                updateReplies(
                    sendCommandAPI.response.replies,
                    sendCommandAPI.response.message_ids_to_discard,
                    sendCommandAPI.response.activity_session_status,
                    sendCommandAPI.response.steps_completed,
                    sendCommandAPI.response.daily_points_goal
                );
            }
        }
    }, [sendCommandAPI.response]);

    useEffect(() => {
        if (replyAPI.response) {
            updateReplies(
                replyAPI.response.replies,
                replyAPI.response.message_ids_to_discard,
                replyAPI.response.activity_session_status,
                replyAPI.response.steps_completed,
                replyAPI.response.daily_points_goal
            );
        }
    }, [replyAPI.response]);

    const doGiveUp = (e) => {
        setGiveUp(true);
        setTimeout(() => {
            console.log("Scrolling to exercise");
            giveUpRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 0);
    };

    function updateReplies(replies, messageIdsToDiscard, newSessionStatus, stepsCompleted, dailyPointsGoal) {
        if (newSessionStatus) {
            activitySession.status = newSessionStatus;
            setActivitySession(activitySession);
        }

        let scoredReply = null;
        let isComplete = false;
        for (const reply of replies) {
            if (reply.score) {
                scoredReply = reply;
            }

            if (reply.last_message) {
                isComplete = true;
            }
        }

        setProgress({
            ...progress,
            steps_completed: stepsCompleted,
        });

        if (scoredReply) {
            console.log("Got scored reply", scoredReply);
            setScoredReply(scoredReply);
        }

        // remove the dummy user message
        let newMessages = messages.slice(0, -1);
        // discard messages
        if (messageIdsToDiscard) {
            newMessages = newMessages.filter((message) => !messageIdsToDiscard.includes(message.id));
        }
        newMessages = newMessages.concat(replies);
        setMessages(newMessages);

        if (scoredReply) {
            if (scoredReply.score.correct) {
                setReplacedValue("");
                setPromptContext({ ...promptContext, ignoredGrammarCorrections: [] });
                setGrammarCorrections([]);
            } else {
                setGrammarCorrections(scoredReply.score.grammar_corrections);
            }
            // setMissedWordIds(scoredReply.score.missed_word_ids);
            setPromptContext({
                ...promptContext,
                grammarCorrectedWordIds: scoredReply.score.grammar_corrections_by_word,
                unknownWordIds: scoredReply.score.unknown_word_ids,
            });
            updateChatKeyToScroll();
        } else {
            setGrammarCorrections([]);
        }

        setComplete(isComplete);

        if (!isComplete && !isMobile.any()) {
            document.getElementById("editable")?.focus();
        }
    }

    useEffect(() => {
        if (!taskResult) {
            return;
        }

        // check to see if it is an array
        if (taskResult.result && Array.isArray(taskResult.result)) {
            // we've got some replies from the task result
            updateReplies(taskResult.result);
        } else if (taskResult.task.status !== "FAILED") {
            // the task result returned nothing, which means we should advance to the next prompt
            advance();
        } else {
            setError({
                message: strings.activity_creation_problem,
            });
        }
    }, [taskResult]);

    function handleAPIError(error) {
        console.log("Got API Error", error);

        if ("no_speech_found" === error.errorDetails?.code) {
            setNoSpeechFound(true);
        }

        let message = error.message;

        if (strings[error.message]) {
            message = strings[error.message];
        }

        if (!prompt) {
            setError(error);
        } else {
            let newMessages = [...messages];
            newMessages.push({
                id: newMessages.length,
                message: message,
                from_user: false,
                tag: "error",
            });
            setMessages(newMessages);

            scrollToEnd();
        }
    }

    function updateChatKeyToScroll() {
        let newChatKey = [replyAPI.response, promptAPI.loading, giveUp];
        setChatKey(newChatKey);
    }

    function scrollToEnd() {
        endRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const onRatingReply = (response) => {
        console.log("Rating response", response);

        if (response.advance) {
            advance();
        } else if (response.replies) {
            replyAPI.setResponse(response.replies);
        }
    };

    function onFocus(event, inFocus) {
        console.log("In focus", inFocus);
        setInFocus(inFocus);

        if (giveUp === false && inFocus) {
            setTimeout(
                scrollToEnd,
                // Android requires more time for the keyboard to pop up
                isMobile.Android() ? 750 : 200
            );
        }
    }

    useEffect(() => {
        setDisabled(promptAPI.loading === true || replyAPI.loading === true || sendCommandAPI.loading === true);
    }, [promptAPI.loading, replyAPI.loading, sendCommandAPI.loading]);

    function onApplyCorrection(correction, replacement) {
        callAcceptGrammrCorrectionAPI(
            "POST",
            `/api/grammar/accept`,
            {
                correction_ids: correction.source_correction_ids,
            },
            null,
            () => null
        );
    }

    if (error) {
        // At this point, no prompt is loaded, so we need to render the chat manually
        return (
            <Chat autoScroll={true} messages={error}>
                <ChatRowLeft>
                    <ChatMessageLeft>{error.message}</ChatMessageLeft>
                </ChatRowLeft>

                {error.retrying && (
                    <ChatRowLeft>
                        <ChatMessageLeft>
                            <ChatWaitingIndiciator />
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}
                <ChatInput className={"solid-bottom-bar"}>
                    <Container />
                    <div
                        className="command-options"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                            flexWrap: "wrap",
                        }}>
                        <Button
                            content={strings.home}
                            icon={<House />}
                            onClick={() => history.push(`/languages/${language}/${nativeLanguage}/home`)}
                            primary
                        />
                    </div>
                    <div
                        style={{
                            marginBottom: "0.5rem",
                        }}
                    />
                </ChatInput>
            </Chat>
        );
    }

    return (
        <Form>
            <fieldset disabled={disabled}>
                <TopBar>
                    {activitySession && (
                        <ActivityProgressBar
                            icon={activitySession.activity.emoji}
                            title={activitySession.activity.name_native}
                            points={progress.steps_completed}
                            total={progress.steps_total}
                            onClose={() => history.push(`/languages/${language}/${nativeLanguage}/home`)}
                        />
                    )}
                </TopBar>

                <Chat autoScroll={true} messages={chatKey}>
                    <PromptChat
                        key={prompt ? prompt.id : "empty-chat"}
                        prompt={prompt}
                        activity={activitySession?.activity}
                        promptContext={promptContext}
                        setPromptContext={setPromptContext}
                        messages={messages}
                        lastMessage={lastMessage}
                        setAllMessagesLoaded={setAllMessagesLoaded}
                        statistics={promptAPI?.response?.activity?.statistics}
                        endRef={endRef}
                        loading={promptAPI.loading || replyAPI.loading || sendCommandAPI.loading}
                        showDiffs={showDiffs}
                        doGiveUp={doGiveUp}
                        giveUp={giveUp}
                        giveUpRef={giveUpRef}
                        forceCorrect={forceCorrect}
                        doForceCorrect={doForceCorrect}
                        completed={scoredReply?.score?.correct}
                        sendCommand={sendCommand}
                        sendCommandAPI={sendCommandAPI}
                        onRatingReply={onRatingReply}
                        scrollToEnd={updateChatKeyToScroll}
                        inFocus={inFocus}
                    />

                    <ChatInput className={complete ? "solid-bottom-bar" : "white-bottom-bar"}>
                        {prompt && lastMessage?.tag !== "task" && (
                            <Container
                                style={{
                                    display: !isAllMessagesLoaded || disabled ? "none" : null,
                                }}>
                                {complete && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            margin: "1rem 0",
                                        }}>
                                        <div ref={nextButtonView.ref} />

                                        <Button
                                            id="next-button"
                                            onClick={advanceToNextExercise}
                                            style={{ minWidth: "20rem", fontSize: "1rem" }}
                                            content={strings.next}
                                            primary></Button>
                                    </div>
                                )}

                                {!complete && (
                                    <Form>
                                        <fieldset disabled={disabled}>
                                            {messages.length > 0 && (
                                                <PromptButtonBar
                                                    prompt={prompt}
                                                    sendCommand={sendCommand}
                                                    sendResponse={submit}
                                                    disabled={disabled}
                                                    lastMessage={lastMessage}
                                                    hasGrammarCorrections={
                                                        grammarCorrections !== null && grammarCorrections.length > 0
                                                    }
                                                    onResize={scrollToEnd}
                                                    textMode={textMode}
                                                    setTextMode={setTextMode}
                                                />
                                            )}

                                            {lastMessage?.tag !== "error" &&
                                                promptAPI.response !== null &&
                                                !promptAPI.loading && (
                                                    <PromptInputBar
                                                        resetCount={count}
                                                        textMode={textMode}
                                                        textEditorRef={textEditorView.ref}
                                                        activityId={promptAPI?.response?.activity_id}
                                                        submit={submit}
                                                        onSpeechSubmit={onSpeechSubmit}
                                                        sendCommand={sendCommand}
                                                        onChange={setAnswer}
                                                        disabled={disabled}
                                                        onFocus={onFocus}
                                                        grammarCorrections={grammarCorrections}
                                                        ignoredGrammarCorrections={
                                                            promptContext.ignoredGrammarCorrections
                                                        }
                                                        setIgnoredGrammarCorrections={(ignoredGrammarCorrections) =>
                                                            setPromptContext({
                                                                ...promptContext,
                                                                ignoredGrammarCorrections: ignoredGrammarCorrections,
                                                            })
                                                        }
                                                        autoCapitalize={prompt?.prompt_type !== "review"}
                                                        onApplyCorrection={onApplyCorrection}
                                                        sendCorrections={
                                                            !["review", "translation"].includes(prompt?.prompt_type)
                                                        }
                                                        isAllMessagesLoaded={isAllMessagesLoaded}
                                                        replacedValue={replacedValue}
                                                        setReplacedValue={setReplacedValue}
                                                        lastMessage={lastMessage}
                                                        language={prompt.language}
                                                        nativeLanguage={
                                                            prompt.native_language
                                                                ? prompt.native_language
                                                                : prompt.language
                                                        }
                                                        loadingPrompt={promptAPI.loading}
                                                    />
                                                )}

                                            <div
                                                style={{
                                                    marginBottom: "0.5rem",
                                                }}
                                            />
                                        </fieldset>
                                    </Form>
                                )}
                            </Container>
                        )}
                    </ChatInput>
                </Chat>
            </fieldset>
        </Form>
    );
}

function findFlashcardMessage(messages) {
    for (const message of messages) {
        if (message.tag === "flashcard") {
            return message;
        }
    }
    return null;
}

const defaultAudioState = {
    audioPlayingIndex: -1,
    currentMessageIndex: -1,
    playing: false,
};

export const PromptChat = memo(
    ({
        prompt,
        activity,
        readOnly = false,
        setAllMessagesLoaded,
        messages,
        lastMessage,
        loading,
        promptContext,
        setPromptContext,
        doGiveUp,
        giveUp,
        giveUpRef,
        endRef,
        showDiffs,
        forceCorrect,
        doForceCorrect,
        completed = true,
        onRatingReply,
        sendCommand,
        sendCommandAPI,
        scrollToEnd,
        inFocus,
    }) => {
        const audioInfo = useRef(defaultAudioState);
        const [audioPlayingIndex, setAudioPlayingIndex] = useState(-1);
        const [flashcardMessage, setFlashcardMessage] = useState();
        const { languageSettings } = useAuth();

        useEffect(() => {
            audioInfo.current = defaultAudioState;
            setAudioPlayingIndex(-1);
        }, [prompt]);

        useEffect(() => {
            let message = findFlashcardMessage(messages);
            console.log("Found flashcard message", message);
            setFlashcardMessage(message);
        }, [messages]);

        function setPlayingAudio(playing) {
            if (playing === audioInfo.current.playing) {
                return;
            }

            console.log("Audio playing", audioInfo.current);

            let newAudioInfo = { ...audioInfo.current };
            newAudioInfo.playing = playing;

            if (!playing && audioInfo.current.currentMessageIndex > audioInfo.current.audioPlayingIndex) {
                console.log("Audio advancing because the previous message stopped", audioInfo.current);
                advanceToNextAudio(newAudioInfo);
            }
            console.log("Audio: setPlayingAudio", newAudioInfo);
            audioInfo.current = newAudioInfo;
        }

        function advanceToNextAudio(audioInfo) {
            console.log("Audio: advanceToNextAudio", audioInfo);
            // find the last user message, we always want to make the audio start after that
            let minAudioIndex = 0;
            for (let i = audioInfo.currentMessageIndex; i >= 0; i--) {
                if (messages[i]?.from_user) {
                    minAudioIndex = i + 1;
                    break;
                }
            }

            while (audioInfo.audioPlayingIndex < audioInfo.currentMessageIndex) {
                audioInfo.audioPlayingIndex += 1;

                if (messages[audioInfo.audioPlayingIndex]?.from_user) {
                    continue;
                }

                // skip messages which don't have a phrase to play
                if (messages[audioInfo.audioPlayingIndex]?.message_phrase_id === null) {
                    continue;
                }

                // make sure it's from the latest reply batch
                if (audioInfo.audioPlayingIndex < minAudioIndex) {
                    audioInfo.audioPlayingIndex = minAudioIndex;
                }

                setAudioPlayingIndex(audioInfo.audioPlayingIndex);
                return;
            }
        }

        function setCurrentMessageIndex(newIndex) {
            console.log("Audio: setCurrentMessageIndex start", newIndex, audioInfo.current);

            let newAudioInfo = { ...audioInfo.current };

            newAudioInfo.currentMessageIndex = newIndex;

            if (newAudioInfo.audioPlayingIndex === -1) {
                console.log("Audio advancing because we got our first message", audioInfo.current);
                advanceToNextAudio(newAudioInfo);
            } else if (!audioInfo.current.playing && newIndex > audioInfo.current.audioPlayingIndex) {
                advanceToNextAudio(newAudioInfo);
            }

            console.log("Audio: setCurrentMessageIndex end", newAudioInfo);
            audioInfo.current = newAudioInfo;
            scrollToEnd();
        }

        /**
         * Using a callback here so that the message doesn't get re-rendered unless
         * the properties we actually care about change.
         */
        const renderMessage = useCallback(
            (message, messageIndex, messages) => {
                return (
                    <>
                        {(message.tag !== "task" || (message.tag === "task" && message === lastMessage)) && (
                            <PromptChatMessage
                                key={"message-" + message.id}
                                prompt={prompt}
                                activity={activity}
                                promptContext={promptContext}
                                setPromptContext={setPromptContext}
                                message={message}
                                setAllMessagesLoaded={setAllMessagesLoaded}
                                messageIndex={messageIndex}
                                flashcardMessage={flashcardMessage}
                                autoPlayAudio={messageIndex === audioPlayingIndex}
                                setPlayingAudio={setPlayingAudio}
                                showDiffs={showDiffs}
                                doGiveUp={doGiveUp}
                                giveUp={giveUp}
                                forceCorrect={forceCorrect}
                                doForceCorrect={doForceCorrect}
                                completed={completed}
                                sendCommand={sendCommand}
                                sendCommandAPI={sendCommandAPI}
                                onRatingReply={onRatingReply}
                                giveUpRef={giveUpRef}
                                isFirstBubble={
                                    messageIndex === 0 || messages[messageIndex - 1].from_user !== message.from_user
                                }
                                isLastBubble={
                                    (messages.length === messageIndex + 1 ||
                                        messages[messageIndex + 1].from_user !== message.from_user) &&
                                    !(
                                        message.explanations?.length > 0 &&
                                        ((message.last_message && showDiffs) || !message.last_message)
                                    )
                                }
                            />
                        )}
                    </>
                );
            },
            [
                flashcardMessage,
                audioPlayingIndex,
                showDiffs,
                inFocus,
                giveUp,
                sendCommandAPI.loading,
                messages,
                promptContext?.unknownWordIds,
            ]
        );

        return (
            <>
                {prompt && messages && messages.length > 0 && (
                    <AdvancingChat
                        key={"advancing-" + (prompt ? prompt.id : "loading")}
                        chatId={prompt ? prompt.id : "prompt-loading"}
                        setCurrentMessageIndex={setCurrentMessageIndex}
                        setAllMessagesLoaded={setAllMessagesLoaded}
                        messages={messages}
                        loading={loading}
                        render={renderMessage}
                        readOnly={readOnly}
                        useArtificialDelay={!["default", "flashcard", "grammar", "vocab"].includes(prompt.prompt_type)}
                    />
                )}

                {(!prompt || !messages || messages.length === 0) && !readOnly && (
                    <ChatRowLeft key={"typing"} className="chat-bubble-last">
                        <ChatMessageLeft>
                            <ChatWaitingIndiciator />
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}
                <div ref={endRef} className={"promptend"} />
            </>
        );
    }
);

const TaskAchievementMessages = ({ prompt, message }) => {
    const [tasksAchieved, setTasksAcheived] = useState([]);
    const [skillsDemonstrated, setSkillsDemonstrated] = useState([]);

    const celebratoryEmojis = ["🤩", "🌟", "🎉", "🔥", "🥳"];
    const celebratoryEmoji = celebratoryEmojis[message.id % celebratoryEmojis.length];
    const [taskResult] = useTask(message.score_task_id);

    function updateTasksCompleted(tasks_completed, skills_demonstrated) {
        const newTasksAchieved = [];
        for (const goal of prompt.activity.activity_tasks) {
            if (tasks_completed && tasks_completed.includes(goal.id)) {
                newTasksAchieved.push(goal);
            }
        }
        setTasksAcheived(newTasksAchieved);

        const newSkillsDemonstrated = [];
        for (const skill of prompt.activity.skills) {
            if (skills_demonstrated && skills_demonstrated.includes(skill.id)) {
                newTasksAchieved.push(skill);
            }
        }
        setSkillsDemonstrated(newSkillsDemonstrated);
    }

    useEffect(() => {
        if (!prompt.activity?.activity_tasks) {
            return;
        }

        if (!message.score_task_id) {
            updateTasksCompleted(message.tasks_completed, message.skills_demonstrated);
        }
    }, [prompt, message]);

    useEffect(() => {
        if (taskResult?.result) {
            updateTasksCompleted(taskResult.result.tasks_completed, taskResult.result.skills_demonstrated);
        }
    }, [taskResult]);

    if (!tasksAchieved && message.score_task_id && !taskResult) {
        return (
            <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                <Loader active inline size="tiny" />
            </div>
        );
    }

    return (
        <>
            {tasksAchieved.map((goal) => (
                <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                    {celebratoryEmoji} {goal.description_native}
                </div>
            ))}
            {skillsDemonstrated.map((skill) => (
                <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                    ⭐️ {skill.description_native}
                </div>
            ))}
        </>
    );
};

export const PromptChatMessage = memo(
    ({
        prompt,
        activity,
        message,
        messageIndex,
        flashcardMessage,
        setPlayingAudio,
        giveUpRef,
        giveUp,
        promptContext,
        setPromptContext,
        sendCommand,
        showDiffs,
        autoPlayAudio,
        doForceCorrect,
        completed = true,
        isFirstBubble,
        isLastBubble,
    }) => {
        // console.log("Message", message, isFirstBubble, isLastBubble);
        const [showNativeHint, setShowNativeHint] = useState(message.explanations !== null);
        let { nativeLanguage } = useParams();

        const { promptView, grammarView, grammarThumbsDownView, clozeView, hintView, tabooWordView } = useWalkthrough();
        const chatContext = useContext(AdvancingChatContext);
        const [explainActive, setExplainActive] = useState(false);
        const [isLastGroup, setIsLastGroup] = useState(false);

        useEffect(() => {
            if (message.explanations?.length > 0 && ["incorrect_final", "correct"].includes(message.tag)) {
                setExplainActive(true);
            }
        }, [message.explanations]);

        const renderHTML = (rawHTML) =>
            React.createElement("div", {
                dangerouslySetInnerHTML: { __html: rawHTML },
            });

        useEffect(() => {
            if (
                prompt &&
                (prompt.flashcard_review?.forgetting_index === null ||
                    prompt?.flashcard_review?.forgetting_index > 0.25)
            ) {
                setShowNativeHint(true);
            }
        }, [prompt]);

        useEffect(() => {
            if (!chatContext.loadedMessages) {
                return;
            }

            // iterate over loaded messages in reverse order
            // if we find a message from the same user, then we're not the last group
            for (let i = chatContext.loadedMessages.length - 1; i >= 0; i--) {
                const loadedMessage = chatContext.loadedMessages[i];
                if (loadedMessage.from_user) {
                    setIsLastGroup(false);
                    return;
                } else if (loadedMessage === message) {
                    setIsLastGroup(true);
                    return;
                }
            }
        }, [chatContext.loadedMessages]);

        function explain() {
            if (!explainActive) {
                setExplainActive(true);
                if (message.explanations === null || message.explanations.length === 0) {
                    sendCommand("explain", null, null, message.id);
                }
            } else {
                setExplainActive(false);
            }
        }

        function format(text) {
            text = text.replace(/\n/g, "<br/>");

            return React.createElement("span", {
                dangerouslySetInnerHTML: { __html: text },
            });
        }

        function setUnknownWordIds(unknownWordIds) {
            let newUnknownWordIds = promptContext.unknownWordIds;
            newUnknownWordIds.push(...unknownWordIds);
            setPromptContext({ ...promptContext, unknownWordIds: newUnknownWordIds });
        }

        return (
            <React.Fragment key={message.id + "-chat-row-fragment"}>
                {(message?.message !== "" || message.transcribing) && message.from_user && (
                    <ChatRowRight
                        key={message.id + "-chat-row"}
                        className={
                            (isFirstBubble ? "chat-bubble-first " : "") + (isLastBubble ? "chat-bubble-last" : "")
                        }>
                        <ChatMessageRight>
                            {!message.message_phrase && message.message && format(message.message)}
                            {message.message_phrase && (
                                <Phrase
                                    phrase={message.message_phrase}
                                    alignments={message.message_phrase.alignments}
                                    fromOrTarget="from"
                                    translations={message.message_phrase.translations}
                                    translationLanguage={nativeLanguage}
                                    unknownWordIds={[]}
                                    setUnknownWordIds={() => null}
                                    missedWordIds={[]}
                                    blanks={message.blanks}
                                    markUknownRemotely={true}
                                    allowFullTranslation={true}
                                    squidgyId={prompt.id}
                                    promptMessageId={message.id}
                                    enableButtons={false}
                                />
                            )}
                            {message.transcribing && <Loader active inline size="mini" />}

                            {message.corrected_message && (
                                <div className="correction">
                                    <div className="correction-message">{message.corrected_message}</div>
                                    {message.correction_explanation && (
                                        <div className="correction-explanation">{message.correction_explanation}</div>
                                    )}
                                </div>
                            )}
                        </ChatMessageRight>
                        <ChatIconRight>{strings.you}</ChatIconRight>
                    </ChatRowRight>
                )}

                <TaskAchievementMessages prompt={prompt} message={message} />

                {messageIndex === 0 && (
                    <>
                        {flashcardMessage && (
                            <Instructions
                                instructions={toCoolEmojis(
                                    strings["prompt_exercise_type_" + flashcardMessage.flashcard.type]
                                )}
                                prompt={prompt}
                            />
                        )}
                        {activity.type === "role_play" && flashcardMessage === null && (
                            <Instructions
                                instructions={toCoolEmojis(strings["activity_type_" + activity.type])}
                                prompt={prompt}
                            />
                        )}
                        {activity.type === "discuss" && flashcardMessage === null && (
                            <Instructions
                                instructions={toCoolEmojis(strings["activity_type_" + activity.type])}
                                prompt={prompt}
                            />
                        )}
                    </>
                )}

                {message.tag === "prompt" && <span ref={promptView.ref}></span>}

                {!message.from_user && (
                    <ChatRowLeft
                        rowRef={flashcardMessage === message ? giveUpRef : null}
                        key={message.id + "-chat-row"}
                        className={
                            (isFirstBubble ? "chat-bubble-first " : "") +
                            (isLastBubble ? "chat-bubble-last" : "") +
                            (message.tag ? " " + message.tag : "")
                        }>
                        <ChatIconLeft></ChatIconLeft>
                        <ChatMessageLeft id={message.tag}>
                            {message.tag === "taboo_word" && <span ref={tabooWordView.ref}></span>}

                            {message.tag === "hint" && (
                                <>
                                    <span ref={hintView.ref}></span>
                                    {(!message.message_phrase || showNativeHint) && (
                                        <p>
                                            {strings.flashcard_hint}: {message.cloze_details.hint_native}
                                        </p>
                                    )}
                                    {message.message_phrase && !showNativeHint && (
                                        <p>
                                            {strings.flashcard_hint}: {message.cloze_details.hint_native_hidden}{" "}
                                            <Button size="small" compact onClick={() => setShowNativeHint(true)}>
                                                {strings.formatString(
                                                    strings.flashcard_show_native_hint,
                                                    strings[prompt.language]
                                                )}
                                            </Button>
                                        </p>
                                    )}
                                </>
                            )}

                            {message.tag === "grammar" && (
                                <>
                                    <span ref={grammarView.ref}></span>
                                    <p style={{ fontSize: "0.8125rem" }}>{strings.prompt_grammar_try_this_instead}</p>
                                </>
                            )}

                            {message.message_phrase && !["flashcard", "vocab"].includes(message.tag) && (
                                <Phrase
                                    phrase={message.message_phrase}
                                    alignments={message.message_phrase.alignments}
                                    fromOrTarget={"from"}
                                    translations={message.message_phrase.translations}
                                    translationLanguage={nativeLanguage}
                                    blanks={message.blanks}
                                    markUknownRemotely={true}
                                    allowFullTranslation={true}
                                    autoPlayAudio={autoPlayAudio}
                                    setPlayingAudio={setPlayingAudio}
                                    squidgyId={prompt.id}
                                    promptMessageId={message.id}
                                    unknownWordIds={promptContext.unknownWordIds}
                                    setUnknownWordIds={setUnknownWordIds}
                                    additionalActions={
                                        <>
                                            {(message.tag === "grammar" || message.tag === "grammar_explanation") && (
                                                <>
                                                    {isLastGroup && (
                                                        <span ref={grammarThumbsDownView.ref}>
                                                            <Button
                                                                compact={true}
                                                                size={"small"}
                                                                icon={<ThumbsDown />}
                                                                content={strings.prompt_ignore_correction}
                                                                onClick={() =>
                                                                    sendCommand("ignore_correction")
                                                                }></Button>
                                                        </span>
                                                    )}
                                                    <Button
                                                        compact
                                                        size={"small"}
                                                        icon={<Question />}
                                                        active={explainActive}
                                                        content={strings.prompt_explain}
                                                        onClick={explain}
                                                    />
                                                </>
                                            )}

                                            {message.tag === "incorrect" && isLastGroup && (
                                                <>
                                                    <Button
                                                        compact
                                                        size={"small"}
                                                        icon={<Question />}
                                                        active={explainActive}
                                                        content={strings.prompt_explain}
                                                        /*disabled={explainActive}*/
                                                        onClick={explain}
                                                    />

                                                    <Button
                                                        compact
                                                        size={"small"}
                                                        content={strings.prompt_my_answer_accepted}
                                                        onClick={(e) => {
                                                            doForceCorrect();
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            )}

                            {message.message &&
                                !message.message_phrase &&
                                !["flashcard", "vocab"].includes(message.tag) &&
                                format(message.message)}

                            {message.attachment_html && renderHTML(message.attachment_html)}

                            {message.flashcard?.type === "translation" && (
                                <Phrase
                                    phrase={message.message_phrase}
                                    alignments={message.message_phrase.alignments}
                                    fromOrTarget="from"
                                    translations={message.message_phrase.translations}
                                    translationLanguage={prompt.language}
                                    unknownWordIds={promptContext.unknownWordIds}
                                    setUnknownWordIds={setUnknownWordIds}
                                    grammarCorrectedWordIds={promptContext.grammarCorrectedWordIds}
                                    missedWordIds={promptContext.missedWordIds}
                                    squidgyId={prompt.id}
                                    allowFullTranslation={completed}
                                    promptMessageId={message.id}
                                    giveUp={giveUp}
                                    autoPlayAudio={autoPlayAudio}
                                    setPlayingAudio={setPlayingAudio}
                                />
                            )}

                            {message.flashcard?.type === "transcribe" && (
                                <>
                                    <p>{toCoolEmojis(strings.transcribe_instructions)}</p>
                                    <AudioPhrase
                                        phrase={message.message_phrase}
                                        autoPlayAudio={autoPlayAudio}
                                        setPlayingAudio={setPlayingAudio}
                                    />
                                </>
                            )}

                            {["cloze", "multiple_choice", "question", "question_mc"].includes(
                                message.flashcard?.type
                            ) && (
                                <span ref={clozeView.ref}>
                                    <Phrase
                                        phrase={message.message_phrase}
                                        inline={false}
                                        alignments={message.message_phrase.alignments}
                                        fromOrTarget="from"
                                        translations={message.message_phrase.translations}
                                        translationLanguage={nativeLanguage}
                                        unknownWordIds={promptContext.unknownWordIds}
                                        setUnknownWordIds={setUnknownWordIds}
                                        grammarCorrectedWordIds={promptContext.grammarCorrectedWordIds}
                                        missedWordIds={promptContext.missedWordIds}
                                        blanks={message.flashcard.cloze_answer_word_ids}
                                        autoPlayAudio={autoPlayAudio}
                                        setPlayingAudio={setPlayingAudio}
                                        squidgyId={prompt.id}
                                        allowFullTranslation={completed}
                                        promptMessageId={message.id}
                                        giveUp={giveUp}
                                        markUknownRemotely={true}
                                    />
                                </span>
                            )}

                            {explainActive &&
                                message.explanations?.length > 0 &&
                                ((message.last_message && showDiffs) || !message.last_message) && (
                                    <ExplanationList activity={activity} explanations={message.explanations} />
                                )}

                            {explainActive && message.correction_explanation}
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}

                {message.ref && <div ref={message.ref} />}
            </React.Fragment>
        );
    }
);

export const ExplanationList = ({ activity, explanations }) => {
    return (
        <div style={{ marginTop: "1rem" }}>
            {explanations.map((explanation) => (
                <Explanation activity={activity} explanation={explanation} />
            ))}
        </div>
    );
};

export function Explanation({ activity, explanation }) {
    return (
        <div className="Explanation">
            {explanation.alternate_cloze && (
                <p>
                    <b>You said:</b> {explanation.alternate_cloze} <br />
                    <b>Answer:</b> {explanation.cloze}
                </p>
            )}

            <p>
                {explanation.explanation_language === activity.language && (
                    <LazyPhrase text={explanation.explanation} activityId={activity.id} hideWords={false} />
                )}
                {explanation.explanation_language !== activity.language && explanation.explanation}
            </p>
        </div>
    );
}

function Instructions({ prompt, instructions }) {
    return (
        <div className="ExerciseInstruction">
            {prompt.flashcard_review !== null ? (
                <span style={{ whiteSpace: "nowrap" }}>
                    {instructions}&nbsp;
                    <span>
                        {prompt.flashcard_review.forgetting_index === null && <>&nbsp;&#x1F331;</>}
                        {prompt.flashcard_review.forgetting_index >= 0.5 && <>&#x1F4AA;</>}
                        {prompt.flashcard_review.forgetting_index >= 0.125 &&
                            prompt.flashcard_review.forgetting_index < 0.5 && <>&#x1F4AA;&#x1F4AA;</>}
                        {prompt.flashcard_review.forgetting_index >= 0.0625 &&
                            prompt.flashcard_review.forgetting_index < 0.125 && <>&#x1F4AA;&#x1F4AA;&#x1F4AA;</>}
                        {prompt.flashcard_review.forgetting_index !== null &&
                            prompt.flashcard_review.forgetting_index < 0.0625 && (
                                <>&#x1F4AA;&#x1F4AA;&#x1F4AA;&#x1F4AA;</>
                            )}
                    </span>
                </span>
            ) : (
                <span>{instructions}</span>
            )}
        </div>
    );
}
