import "./Flex.css";
export function TitleWithAction({ title, action }) {
    return (
        <span style={{ display: "flex" }}>
            <span
                style={{
                    flexGrow: 4,
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                }}>
                {title}
            </span>
            <span style={{ display: "flex", direction: "column", justifyContent: "center", alignItems: "center" }}>
                <span className="spacer" />
                {action}
                <span className="spacer" />
            </span>
        </span>
    );
}

export function ContentWithRight({ children, right, className, stackable = true }) {
    return (
        <div className={"content-with-right" + (className ? " " + className : "") + (stackable ? " stackable" : "")}>
            <div className="content-with-right-children">{children}</div>
            {right && <div className="content-with-right-right">{right}</div>}
        </div>
    );
}

export function ContentWithLeft({ children, left, minWidth = "5rem" }) {
    return (
        <div style={{ display: "flex", width: "100%" }}>
            <span style={{ marginRight: "1rem" }}>{left}</span>
            <span
                style={{
                    flexGrow: 4,
                    display: "flex",
                    direction: "column",
                    alignItems: "center",
                }}>
                <div>{children}</div>
            </span>
        </div>
    );
}

export const Row = ({ children, className, wrap, centerAlign = true, style = {} }) => {
    return (
        <div
            className={`flex-row${className ? " " + className : ""}`}
            style={{
                flexWrap: wrap ? "wrap" : "nowrap",
                alignItems: centerAlign ? "center" : "flex-start",
                ...style,
            }}>
            {children}
        </div>
    );
};

export const Column = ({ children, className }) => {
    return <div className={`flex-column${className ? " " + className : ""}`}>{children}</div>;
};

export const StackableRow = ({ children, columns, className }) => {
    return <div className={`flex-row-stackable${className ? " " + className : ""}`}>{children}</div>;
};

export const WrappedRow = ({ children, className, wrap, centerAlign = true }) => {
    return (
        <div
            className={className}
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            }}>
            {children}
        </div>
    );
};
