import useAPI from "../hooks/useAPI";
import { useEffect } from "react";
import { ZataLoader } from "./ZataLoader";
import { Dropdown } from "semantic-ui-react";
import { useLanguage } from "../hooks/useLanguage";

const CharacterSelector = ({ selectedCharacterId, setCharacterId }) => {
    const { language, nativeLanguage } = useLanguage();
    const [characterAPI, callCharacterAPI] = useAPI({ loading: true });

    useEffect(() => {
        callCharacterAPI("GET", `/api/characters?language=${language}&native_language=${nativeLanguage}`);
    }, []);

    if (characterAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <Dropdown
            text={
                selectedCharacterId
                    ? characterAPI.response.find((c) => c.id === selectedCharacterId).name
                    : characterAPI.response.find((c) => c.default).name
            }>
            <Dropdown.Menu>
                {characterAPI.response.map((character) => {
                    return (
                        <Dropdown.Item
                            key={character.id}
                            value={character.id}
                            onClick={(e, { value }) => setCharacterId(value)}>
                            <p>
                                <b>{character.name}</b>
                            </p>
                            <p>{character.description_native}</p>
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CharacterSelector;
