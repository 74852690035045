import React, { useState } from "react";

export const SidebarProviderContext = React.createContext({
    showSidebar: null,
    setShowsidebar: () => null,
});

export default function SidebarProvider({ children }) {
    const [showSidebar, setShowSidebar] = useState(false);

    const contextValue = {
        showSidebar,
        setShowSidebar,
    };

    return <SidebarProviderContext.Provider value={contextValue}>{children}</SidebarProviderContext.Provider>;
}
