import { Button, Loader } from "semantic-ui-react";

const LoadableButton = (props) => {
    return (
        <Button
            {...props}
            icon={
                props.loading ? (
                    <Loader active inline="true" size="tiny" style={{ margin: "0", padding: "0" }} />
                ) : (
                    props.icon
                )
            }
            disabled={props.loading || props.disabled}
            loading={null}
        />
    );
};

export default LoadableButton;
