import { Copy, Export } from "@phosphor-icons/react";
import { cloneElement } from "react";
import { Button, Input, Modal } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import { isMobile } from "../utils/platform.utils";
import "./ActivityCollectionShareButton.css";
import { Row } from "./Flex";

const ActiivtyCollectionShareButton = ({ collection, size, compact, content, trigger }) => {
    const [, callUpdateActivityAPI] = useAPI();
    const { user } = useAuth();

    function changeSharingType(sharingType) {
        callUpdateActivityAPI("PUT", `/api/activity_collections/${collection.id}`, {
            sharing_type: sharingType,
        });
    }

    function createLink() {
        navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/collections/${collection.id}`
        );

        if (user && collection.user_id === user.id) {
            changeSharingType("public");
        }
    }

    const share = async () => {
        try {
            if (user && collection.user_id === user.id) {
                changeSharingType("public");
            }
            await navigator.share({
                url: `${window.location.protocol}//${window.location.host}/collections/${collection.id}`,
                text: collection.name_native,
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {navigator.share && isMobile.any() && (
                <div onClick={share}>
                    {trigger ? (
                        cloneElement(trigger, { onClick: createLink })
                    ) : (
                        <Button icon={<Export />} content={content} size={size} compact={compact} />
                    )}
                </div>
            )}

            {(!navigator.share || !isMobile.any()) && (
                <Modal
                    on="click"
                    position="bottom center"
                    closeIcon={true}
                    onOpen={createLink}
                    trigger={
                        trigger ? (
                            trigger
                        ) : (
                            <Button
                                icon={<Export />}
                                content={content}
                                size={size}
                                compact={compact}
                                onClick={createLink}
                            />
                        )
                    }>
                    <Modal.Header>
                        {strings.formatString(
                            strings.share,
                            collection.name_native ? collection.name_native : collection.name
                        )}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Row style={{ marginBottom: "0.5rem" }}>
                                <Input
                                    value={`${window.location.protocol}//${window.location.host}/collections/${collection.id}`}
                                />
                                <Button icon={<Copy />} size={size} compact={compact} onClick={createLink} />
                            </Row>

                            <p>{strings.link_copied}</p>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

export default ActiivtyCollectionShareButton;
