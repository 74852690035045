import { Gear } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Container, Form, Input, Label, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { useQuery } from "../hooks/useQuery";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import { Activity } from "./ActivityDetails";
import "./ActivityQuery.css";
import { ContentWithLeft, ContentWithRight } from "./Flex";
import LevelSelector from "./LevelSelector";
import LoadableButton from "./LoadableButton";
import { ZataLoader } from "./ZataLoader";
import useTask from "../hooks/useTask";
import SkillSegment from "./SkillSegment";
import useNotifications from "../hooks/useNotifications";
import ButtonBar from "./ButtonBar";
import { toCoolEmojis } from "../utils/emoji.utils";

export const ActivityTypeOption = ({ name, icon, onSelect, isSelected }) => {
    return (
        <div className="activity-type-option" onClick={onSelect}>
            <div className={`activity-type-option-icon ${isSelected && "selected"}`}>{icon}</div>
            <p>{name}</p>
            <div className="spacer"></div>
        </div>
    );
};

const Recommendations = () => {
    const [recommendationsAPI, callRecommendationsAPI] = useAPI();
    const { language, nativeLanguage } = useLanguage();
    const query = useQuery();

    useEffect(() => {
        callRecommendationsAPI("GET", `/api/languages/${language}/${nativeLanguage}/recommendations`);
    }, [language, nativeLanguage]);

    if (recommendationsAPI.loading) {
        <ZataLoader message="Loading recommendations..." />;
    }

    return (
        <ButtonBar centered>
            {recommendationsAPI.response?.map((r) => (
                <Label
                    key={r.id}
                    as={Link}
                    basic
                    to={`/activities/new?searchText=${r.topic_native}&activityCollectionId=${query.get(
                        "activityCollectionId"
                    )}`}>
                    {toCoolEmojis(r.emoji)} {r.topic_native ? r.topic_native : r.topic}
                </Label>
            ))}
        </ButtonBar>
    );
};

export const AddActivities = () => {
    const { language, nativeLanguage } = useLanguage();
    const history = useHistory();
    const { setNavigationTitle } = useTitle();
    const { languageSettings } = useAuth();
    const { addNotification } = useNotifications();

    const [isSaving, setSaving] = useState(false);
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [updateActivityCollectionAPI, callUpdateActivityCollectionAPI] = useAPI({ loading: false });
    const [queryAPI, callQueryAPI, cancelQuery] = useAPI({ loading: false });
    const [searchText, setSearchText] = useState("");
    const [showAdvancedSettings, setShowAdvancedSettings] = useState();

    const [level, setLevel] = useState(
        (languageSettings.use_self_reported_level
            ? languageSettings.self_reported_level
            : languageSettings.estimated_cefr_level) || "A2"
    );

    const [activityQueryRequest, setActivityQueryRequest] = useState(null);
    const [activityQuery, setActivityQuery] = useState();
    const [taskResult] = useTask(activityQuery?.generation_task_id);

    const query = useQuery();

    useEffect(() => {
        const request = {
            activity_collection_id: query.get("activityCollectionId") === "" ? null : query.get("activityCollectionId"),
            topic: query.get("searchText"),
            id: query.get("activityQueryId") === "" ? null : query.get("activityQueryId"),
            cefr_level: showAdvancedSettings ? level : null,
            language: language,
            native_language: nativeLanguage,
        };

        setActivityQueryRequest(request);
        if (searchText !== request.topic) {
            setSearchText(request.topic);
        }
    }, [window.location, language, nativeLanguage, level, showAdvancedSettings, query]);

    useEffect(() => {
        // update the url with the search text
        if (activityQueryRequest) {
            if (searchText !== activityQueryRequest.topic) {
                history.replace(
                    `/activities/new?searchText=${searchText}&activityCollectionId=${activityQueryRequest.activity_collection_id}`
                );
                setActivityQuery(null);
            }
        }
    }, [searchText]);

    useEffect(() => {
        setNavigationTitle(strings.add);
    }, [language, nativeLanguage]);

    function save() {
        setSaving(true);

        return callUpdateActivityCollectionAPI("POST", `/api/activity_queries/${activityQuery.id}/copy`, {
            activity_collection_id: activityQueryRequest.activity_collection_id,
            native_language: nativeLanguage,
        });
    }

    function navigateHome() {
        history.push(
            `/languages/${language}/${nativeLanguage}/home?focusOnActivityCollection=${activityQueryRequest.activity_collection_id}`
        );
    }

    useEffect(() => {
        if (updateActivityCollectionAPI.response) {
            navigateHome();
        }
    }, [updateActivityCollectionAPI.response]);

    function clearQueryResults() {
        queryAPI.setLoading(false);
        cancelQuery();
    }

    useEffect(() => {
        if (activityQueryRequest !== null && (activityQueryRequest.topic || activityQueryRequest.id)) {
            clearQueryResults();
            queryAPI.setLoading(true);
            const debounce = setTimeout(() => {
                if (activityQueryRequest.id) {
                    callQueryAPI("GET", `/api/activity_queries/${activityQueryRequest.id}`);
                } else if (activityQueryRequest.topic) {
                    callQueryAPI("POST", `/api/activity_queries`, activityQueryRequest);
                }
            }, 750);
            return () => clearTimeout(debounce);
        } else {
            clearQueryResults();
        }
    }, [activityQueryRequest]);

    useEffect(() => {
        if (queryAPI.response) {
            setActivityQuery(queryAPI.response);
        }
    }, [queryAPI.response]);

    useEffect(() => {
        if (taskResult && taskResult.task) {
            if (taskResult.task.status === "COMPLETED") {
                callQueryAPI("GET", `/api/activity_queries/${activityQuery.id}?native_language=${nativeLanguage}`);
            } else {
                addNotification("add_activities", "Could not generate activities. Please try again later.");
            }
        }
    }, [taskResult]);

    useEffect(() => {
        setSaveEnabled(activityQuery && activityQuery.activities?.length > 0);
    }, [activityQuery]);

    return (
        <div>
            <div>
                <ContentWithRight
                    className="create-activity-search"
                    right={
                        <Button
                            icon={<Gear />}
                            active={showAdvancedSettings}
                            onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                        />
                    }
                    stackable={false}>
                    <Input
                        placeholder={strings.activity_add_placeholder}
                        focus
                        style={{ width: "100%" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoFocus></Input>
                </ContentWithRight>

                {showAdvancedSettings && (
                    <Segment className="create-activity-settings">
                        <Form.Field>
                            <p>
                                {strings.difficulty}: <LevelSelector onChange={setLevel} defaultLevel={level} />
                            </p>
                        </Form.Field>
                    </Segment>
                )}

                {(searchText === null || searchText === "") && (
                    <div>
                        <p>{strings.activities_create_explainer}</p>
                        <Recommendations setActivityQuery={setActivityQuery} />
                    </div>
                )}
            </div>

            {queryAPI.loading && <p>{strings.activities_building}</p>}

            {!queryAPI.loading && activityQuery && activityQuery.skills?.length > 0 && (
                <Segment>
                    <h2>Skills</h2>
                    {activityQuery.skills.map((skill) => (
                        <SkillSegment key={skill.id} skill={skill} />
                    ))}
                </Segment>
            )}

            {!queryAPI.loading && activityQuery && activityQuery?.generation_task_id && (
                <p>{strings.activities_building}</p>
            )}

            {!queryAPI.loading &&
                activityQuery &&
                activityQuery.activities?.map((a) => <Activity key={a.id} activity={a} showActivityType={true} />)}

            {createPortal(
                <Container>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}>
                        <LoadableButton
                            content={strings.add}
                            onClick={save}
                            loading={isSaving}
                            disabled={!isSaveEnabled}
                        />
                    </div>
                </Container>,
                document.getElementById("content-wrapper")
            )}
        </div>
    );
};

export const CreateYoutubeCollection = ({ setActivityQueryRequest }) => {
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaAPI, callMediaAPI] = useAPI({
        loading: false,
        ignoredStatusCodes: ["unrecognized_video_url", "unsupported_language"],
    });

    const [refreshMediaAPI, callRefreshMediaAPI] = useAPI({
        loading: false,
    });
    const [media, setMedia] = useState(null);
    const { language, nativeLanguage } = useLanguage();

    useEffect(() => {
        setActivityQueryRequest(null);
        setMedia(null);

        if (mediaUrl === "") {
            return;
        }

        const debounce = setTimeout(() => {
            callMediaAPI("POST", "/api/media", {
                media_url: mediaUrl,
                language: language,
            });
        }, 750);
        return () => clearTimeout(debounce);
    }, [mediaUrl]);

    useEffect(() => {
        if (mediaAPI.response) {
            setMedia(mediaAPI.response);
            callRefreshMediaAPI("GET", `/api/media/${mediaAPI.response.id}`);
            setActivityQueryRequest({
                language: language,
                native_language: nativeLanguage,
                media_id: mediaAPI.response.id,
            });
        }
    }, [mediaAPI.response]);

    useEffect(() => {
        if (refreshMediaAPI.response) {
            setMedia(refreshMediaAPI.response);
        }
    }, [refreshMediaAPI.response]);

    function truncate(str, n) {
        return str.length > n ? str.substr(0, n - 1) + "..." : str;
    }

    return (
        <div>
            <p>
                <Input
                    placeholder={"YouTube Video URL"}
                    focus
                    style={{ width: "100%" }}
                    value={mediaUrl}
                    onChange={(e) => setMediaUrl(e.target.value)}></Input>
            </p>

            {mediaAPI.loading && (
                <Segment>
                    <ZataLoader message="Loading video..." />
                </Segment>
            )}
            {mediaAPI.error && <p>{strings["youtube_" + mediaAPI.error.code]}</p>}

            {refreshMediaAPI.loading && (
                <Segment>
                    <ZataLoader message="Analyzing video..." />
                </Segment>
            )}
            {!mediaAPI.error && media && (
                <div>
                    <Segment>
                        <h2>{media.title}</h2>
                        <ContentWithLeft left={<img src={media.thumbnail_url} />}>
                            <p>{truncate(media.description, 300)}</p>
                        </ContentWithLeft>
                    </Segment>
                    {media.key_sentences?.length > 0 && (
                        <Segment>
                            <h2>Key Sentences</h2>
                            <ul>
                                {media.key_sentences?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                    {media.vocabulary?.length > 0 && (
                        <Segment>
                            <h2>Key Expressions</h2>
                            <ul>
                                {media.vocabulary?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                    {media.discussion_questions?.length > 0 && (
                        <Segment>
                            <h2>Discussion questions</h2>
                            <ul>
                                {media.discussion_questions?.map((e) => (
                                    <li>{e}</li>
                                ))}
                            </ul>
                        </Segment>
                    )}
                </div>
            )}
        </div>
    );
};
