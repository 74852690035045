import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useAPI from "../hooks/useAPI";
import { strings } from "../utils/i18n.utils";
import { Button, Divider, Input, Modal } from "semantic-ui-react";
import { Divide, Trash } from "@phosphor-icons/react";
import { useLanguage } from "../hooks/useLanguage";

export const DeleteLanguage = () => {
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState("");
    const { user } = useAuth();
    const [canDelete, setCanDelete] = useState("");
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });
    const { language, nativeLanguage } = useLanguage();

    useEffect(() => {
        setCanDelete(input === strings.delete_language_confirm);
    }, [input]);

    function deleteAccount() {
        callDeleteAPI("DELETE", `/api/languages/${language}/${nativeLanguage}`);
    }

    useEffect(() => {
        if (deleteAPI.response) {
            window.location.href = "/start";
        }
    }, [deleteAPI.response]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <div>
                    <Divider />
                    <p>{strings.delete_language_warning}</p>
                    <Button content={strings.delete_language} primary></Button>
                </div>
            }>
            <Modal.Header>{strings.delete_language_header}</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <p>{strings.formatString(strings.delete_language_warning, user.email)}</p>
                    <p>{strings.delete_language_continue}.</p>
                    <Input onChange={(e) => setInput(e.target.value)} style={{ width: "100%" }} />
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => setOpen(false)} disabled={deleteAPI.loading}>
                    {strings.cancel}
                </Button>
                <Button
                    content={strings.delete}
                    icon={<Trash />}
                    onClick={deleteAccount}
                    disabled={!canDelete || deleteAPI.loading}
                />
            </Modal.Actions>
        </Modal>
    );
};
