import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Label } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";
import "./LanguageMenu.css";

const LanguageMenu = () => {
    const history = useHistory();
    const { user } = useAuth();
    const [languages, setLanguages] = useState([]);
    const { language } = useLanguage();

    useEffect(() => {
        var languagesToSelect = [
            { to: strings.english, to_code: "en" },
            { to: strings.french, to_code: "fr" },
            { to: strings.german, to_code: "de" },
            { to: strings.spanish, to_code: "es" },
            { to: strings.italian, to_code: "it" },
            { to: strings.portuguese_european, to_code: "pt-pt" },
            { to: strings.portuguese_brazil, to_code: "pt-br" },
        ];

        languagesToSelect = languagesToSelect.filter((l) => l.to_code !== user.current_native_language);
        setLanguages(languagesToSelect);
    }, [user]);

    const select = (to_code) => {
        history.push(`/languages/${to_code}/${user.current_native_language}/home`);
    };

    return (
        <Dropdown
            trigger={<span>{strings[language]}</span>}
            pointing="top right"
            onChange={select}
            className={"language-menu"}>
            <Dropdown.Menu onChange={select}>
                {languages.map((l) => (
                    <Dropdown.Item key={l.to} onClick={() => select(l.to_code)}>
                        {l.to}
                    </Dropdown.Item>
                ))}
                <Dropdown.Item key={"start"} onClick={() => history.push(`/start`)}>
                    {strings.switch_native_language}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageMenu;
