import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { bestBackgroundColor } from "../utils/color.utils";
import "./SquidgySegment.css";

import { ClickableSegment } from "./ClickableSegment";
import { ActivityIcon } from "./ActivityIcon";

export const SquidgySegment = ({ icon, content, primaryHref, primaryOnClick, detailsHref, menuItems }) => {
    const history = useHistory();

    const doPrimaryOnClick = () => {
        if (primaryOnClick) {
            primaryOnClick();
        } else if (primaryHref) {
            history.push(primaryHref);
        }
    };

    return (
        <ClickableSegment onClick={doPrimaryOnClick}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexGrow: 4, display: "flex", alignItems: "center" }}>
                    <ActivityIcon emoji={icon} />
                    <div style={{ width: "100%", marginLeft: "1rem" }}>{content}</div>
                </div>
                {menuItems && (
                    <div className="SquidgySegmentButtonWrapper">
                        <Dropdown icon={"ellipsis horizontal"} text="" direction="left">
                            <Dropdown.Menu>{menuItems}</Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
            </div>
        </ClickableSegment>
    );
};
