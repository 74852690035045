import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Container } from "semantic-ui-react";
import { nextPage } from "../components/Login";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useQuery } from "../hooks/useQuery";
import { strings } from "../utils/i18n.utils";

export const Terms = () => {
    const { setUser } = useAuth();
    const [termsData, setTermsData] = useState({
        acceptedTerms: false,
        subscribedProductUpdates: false,
    });

    const [userAPI, callUserAPI] = useAPI({ loading: false });
    const history = useHistory();
    const query = useQuery();

    function submit() {
        callUserAPI("PUT", "/api/user", {
            accepted_terms: termsData.acceptedTerms,
            subscribed_product_updates: termsData.subscribedProductUpdates,
        });
    }

    useEffect(() => {
        if (userAPI.response) {
            setUser(userAPI.response.user);
            history.push(nextPage(userAPI.response.user, query.get("redirectTo")));
        }
    }, [userAPI.response]);

    return (
        <Container
            style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
            }}>
            <div>
                <p style={{ fontSize: "1.4rem" }}>Welcome to Jetway</p>
                <p>
                    <Checkbox
                        onChange={(event, data) =>
                            setTermsData({ ...termsData, subscribedProductUpdates: data.checked })
                        }
                    />{" "}
                    {strings.subscribe_product_updates}
                </p>

                <p>
                    <Checkbox onChange={(event, data) => setTermsData({ ...termsData, acceptedTerms: data.checked })} />{" "}
                    {strings.formatString(
                        strings.accept_terms,
                        <a
                            href="https://app.termly.io/document/terms-and-conditions/5ea102fa-ac9f-4241-b691-47919813eb84"
                            target={"_blank"}
                            rel="noreferrer">
                            {strings.terms_of_service}
                        </a>,

                        <a href="https://app.termly.io/document/privacy-policy/ffd0261c-f9f8-43fd-aedd-aba61d1cdc11">
                            {strings.privacy_agreement}
                        </a>
                    )}
                </p>
                <Button
                    primary
                    content={"Let's Start"}
                    disabled={!termsData.acceptedTerms || userAPI.loading}
                    onClick={submit}
                />
            </div>
        </Container>
    );
};
