import { Plus } from "@phosphor-icons/react";
import update from "immutability-helper";
import { memo, useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Label, Progress } from "semantic-ui-react";
import { Board, Card, ListWithTitle } from "../components/Board";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";
import ActiivtyCollectionShareButton from "./ActivityCollectionShareButton";
import { ActivityTypeLabel } from "./ActivityDetails";
import { Row } from "./Flex";
import LoadableButton from "./LoadableButton";
import { ZataLoader } from "./ZataLoader";
import "./ActivityBoard.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import useTask from "../hooks/useTask";
import useNotifications from "../hooks/useNotifications";

const ActivityCard = ({ activity, cardIndex, collectionIndex, moveCard, saveCardOrder, cardsMovable }) => {
    return (
        <Card
            id={activity.id}
            cardIndex={cardIndex}
            listIndex={collectionIndex}
            saveCardOrder={saveCardOrder}
            moveCard={moveCard}
            cardsMovable={cardsMovable}>
            <div>
                <Row style={{ marginBottom: "0.5rem" }}>
                    <Link to={`/activities/${activity.id}`}>
                        {toCoolEmojis(activity.emoji)} {activity.name_native}
                    </Link>
                </Row>
            </div>
            {activity.last_session &&
                activity.last_session?.progress !== 0 &&
                activity.last_session?.progress !== null &&
                !activity.last_session?.activity_mastered && (
                    <div className="list-progress">
                        <Progress value={activity.last_session?.progress} total={1} size="tiny" />
                    </div>
                )}

            <div>
                <ActivityTypeLabel type={activity.type} />
                {activity.last_session?.activity_mastered === true && (
                    <Label basic size="small" className="completed">
                        {strings.activity_completed}
                    </Label>
                )}
                {activity.statistics?.flashcards_needing_review > 0 && (
                    <Label basic size="small">
                        📝 {activity.statistics?.flashcards_needing_review}
                    </Label>
                )}
            </div>
        </Card>
    );
};

const isCardChanged = (prevProps, nextProps) => {
    // return true if props are equal
    return prevProps.collectionIndex === nextProps.collectionIndex && prevProps.cardIndex === nextProps.cardIndex;
};

const MemoizedActivityCard = memo(ActivityCard, isCardChanged);

const AddCard = ({ collection }) => {
    const { user } = useAuth();

    return (
        <>
            {collection.user_id !== user.id && (
                <Label basic size="small">
                    🔒 Shared with you
                </Label>
            )}

            {user.id === collection.user_id && (
                <>
                    <Button
                        icon={<Plus />}
                        content={strings.topic}
                        size="small"
                        compact
                        as="a"
                        href={`/activities/new?activityCollectionId=${collection.id}`}
                    />
                    <Button
                        icon={<Plus />}
                        content={strings.note}
                        size="small"
                        compact
                        as="a"
                        href={`/notes/new?activityCollectionId=${collection.id}`}
                    />
                </>
            )}
        </>
    );
};
export const ListForCollection = ({
    collection,
    collectionIndex,
    removeCollectionFromBoard,
    updateCollection,
    saveCardOrder,
    moveCard,
    moveList,
    saveListOrder,
}) => {
    const [, callUpdateCollectionAPI] = useAPI();
    const [getCollectionAPI, callGetCollectionAPI] = useAPI({ loading: false });
    const [showCompletedCards, setShowCompletedCards] = useState(false);
    const [activities, setActivities] = useState([]);
    const { nativeLanguage } = useLanguage();
    const { user } = useAuth();
    const [taskResult] = useTask(collection.generation_task_id);
    useEffect(() => {
        if (taskResult && taskResult.status === "COMPLETED") {
        }
        if (taskResult && taskResult.task) {
            if (taskResult.task.status === "COMPLETED") {
                callGetCollectionAPI(
                    "GET",
                    `/api/activity_collections/${collection.id}?include_activities=true&include_completed_activities=${showCompletedCards}&native_language=${nativeLanguage}`
                );
            } else {
                // addNotification("add_activities", "Could not generate activities. Please try again later.");
            }
        }
    }, [taskResult]);

    function archiveCollection(collection) {
        callUpdateCollectionAPI("PUT", `/api/activity_collections/${collection.id}`, {
            archived: true,
        });
        removeCollectionFromBoard(collection);
    }

    const saveListTitle = (title) => {
        callUpdateCollectionAPI("PUT", `/api/activity_collections/${collection.id}`, {
            id: collection.id,
            name: title,
        });

        const newCollection = { ...collection };
        newCollection.name = title;
        updateCollection(newCollection);
    };

    useEffect(() => {
        if (showCompletedCards === true || getCollectionAPI.response !== null) {
            callGetCollectionAPI(
                "GET",
                `/api/activity_collections/${collection.id}?include_activities=true&include_completed_activities=${showCompletedCards}&native_language=${nativeLanguage}`
            );
        }
    }, [showCompletedCards]);

    useEffect(() => {
        if (getCollectionAPI.response) {
            updateCollection(getCollectionAPI.response);
        }
    }, [getCollectionAPI.response]);

    useEffect(() => {
        if (!collection.activities) {
            return;
        }

        setActivities(collection.activities);
    }, [collection.activities]);

    function addArchiveButton(text) {
        /*
        Replace the text in betweeen, e.g. {archive} with a Button with the content attribute set to "archive". Return a paragraph:

        <p>
            {text_beginning}
            <Button compact size="small" onClick={() => archiveCollection(collection)} primary content={text_in_brackets}/>
            {text_end}
        </p>
        */
        const regex = /{([^}]+)}/g;

        const parts = text.split(regex);

        return (
            <p>
                {parts.map((part, index) => {
                    if (index % 2 === 0) {
                        return part;
                    } else {
                        return (
                            <Button
                                compact
                                size="small"
                                onClick={() => archiveCollection(collection)}
                                primary
                                content={part}
                            />
                        );
                    }
                })}
            </p>
        );
    }

    return (
        <ListWithTitle
            key={collection.id}
            listIndex={collectionIndex}
            title={collection.name}
            saveListTitle={saveListTitle}
            id={collection.id}
            cardCount={activities.length}
            cardsMovable={user.id === collection.user_id}
            addCard={!collection.generation_task_id && <AddCard collection={collection} />}
            saving={collection.saving}
            menuItems={
                <>
                    <Dropdown.Item key="archive" text={strings.archive} onClick={() => archiveCollection(collection)} />
                    <ActiivtyCollectionShareButton
                        collection={collection}
                        trigger={<Dropdown.Item key="share" text={strings.share} />}
                    />
                    <Dropdown.Item as="a" href={`/collections/${collection.id}`}>
                        {strings.more}
                    </Dropdown.Item>
                </>
            }
            saveCardOrder={saveCardOrder}
            moveCard={moveCard}
            moveList={moveList}
            saveListOrder={saveListOrder}>
            {collection.statistics?.activities_mastered > 0 && (
                <Row style={{ margin: "0 0 0 0.5rem " }}>
                    <span>{collection.statistics.activities_mastered} completed</span>
                    <LoadableButton
                        compact
                        content={showCompletedCards ? strings.hide : strings.show_all}
                        size={"tiny"}
                        onClick={() => setShowCompletedCards(!showCompletedCards)}
                        style={{ margin: 0 }}
                        loading={getCollectionAPI.loading}
                    />
                </Row>
            )}

            {collection.statistics?.activities_mastered > 0 && activities?.length === 0 && (
                <div className="completed-list">
                    <p>{strings.activity_collection_done}</p>
                    <p>{addArchiveButton(strings.activity_collection_done_next_action)}</p>
                </div>
            )}

            {getCollectionAPI.loading && <ZataLoader />}

            {collection.generation_task_id && <ZataLoader />}

            {!getCollectionAPI.loading &&
                activities.map((activity, activityIndex) => (
                    <MemoizedActivityCard
                        activity={activity}
                        cardIndex={activityIndex}
                        collectionIndex={collectionIndex}
                        saveCardOrder={saveCardOrder}
                        moveCard={moveCard}
                        key={activity.id}
                        cardsMovable={user.id === collection.user_id}
                    />
                ))}
        </ListWithTitle>
    );
};

const isListChanged = (prevProps, nextProps) => {
    return prevProps.collection === nextProps.collection || prevProps.listIndex === nextProps.collectionIndex;
};

const MemoizedListForCollection = memo(ListForCollection, isListChanged);

const ActivityBoard = ({ collections, setCollections }) => {
    const { language, nativeLanguage } = useLanguage();
    const [createCollectionAPI, callCreateCollectionAPI] = useAPI();
    const [, callUpdateCollectionAPI] = useAPI();
    const { user } = useAuth();

    const [savedCollections, setSavedCollections] = useState(collections);

    const addList = (title) => {
        const collection = {
            name: title,
            language: language,
            activities: [],
            creating: true,
            user_id: user.id,
        };
        console.log("Adding collection", collection);
        callCreateCollectionAPI("POST", `/api/activity_collections?native_language=${nativeLanguage}`, collection);
    };

    useEffect(() => {
        if (createCollectionAPI.response) {
            const newCollections = [...collections];
            newCollections.push(createCollectionAPI.response);
            setCollections(newCollections);
            setSavedCollections(newCollections);
        }
    }, [createCollectionAPI.response]);

    const moveCard = useCallback((fromCollectionIndex, fromActivityIndex, toCollectionIndex, toActivityIndex) => {
        console.log("Moving card", fromCollectionIndex, fromActivityIndex, toCollectionIndex, toActivityIndex);

        // use immutability helper to update collections
        if (fromCollectionIndex === toCollectionIndex) {
            console.log("Moving within same collection");
            setCollections((prevColletions) =>
                update(prevColletions, {
                    [fromCollectionIndex]: {
                        activities: {
                            $splice: [
                                [fromActivityIndex, 1],
                                [toActivityIndex, 0, prevColletions[fromCollectionIndex].activities[fromActivityIndex]],
                            ],
                        },
                    },
                })
            );
        } else {
            console.log("Moving between collections");
            setCollections((prevColletions) =>
                update(prevColletions, {
                    [fromCollectionIndex]: {
                        activities: {
                            $splice: [[fromActivityIndex, 1]],
                        },
                    },
                    [toCollectionIndex]: {
                        activities: {
                            $splice: [
                                [toActivityIndex, 0, prevColletions[fromCollectionIndex].activities[fromActivityIndex]],
                            ],
                        },
                    },
                })
            );
        }
    });

    function removeCollectionFromBoard(collection) {
        setCollections((prevCollections) => prevCollections.filter((c) => c.id !== collection.id));
    }

    const isSuperset = (superset, subset) => {
        return subset.every((val) => superset.includes(val)) && superset.length > subset.length;
    };

    const saveCardOrder = () => {
        // find differences between activity orders in the collections and savedCollections and save the ones with differences
        for (let collectionIndex in collections) {
            const collection = collections[collectionIndex];
            const savedCollection = savedCollections[collectionIndex];

            let unsavedActivityIds = collection.activities.map((activity) => activity.id);
            let savedActivityIds = savedCollection.activities.map((activity) => activity.id);

            if (isSuperset(unsavedActivityIds, savedActivityIds)) {
                callUpdateCollectionAPI("PUT", `/api/activity_collections/${collection.id}`, {
                    activities: collection.activities.map((activity, index) => ({
                        id: activity.id,
                        order: index,
                    })),
                });
            }
        }

        setSavedCollections([...collections]);
    };

    const moveList = useCallback((fromListIndex, toListIndex) => {
        console.log("Moving list", fromListIndex, toListIndex);
        setCollections((prevCollections) =>
            update(prevCollections, {
                $splice: [
                    [fromListIndex, 1],
                    [toListIndex, 0, prevCollections[fromListIndex]],
                ],
            })
        );
    });

    useEffect(() => {
        if (collections) {
            console.log(
                "Setting saved collections",
                collections.map((c) => c.name_native)
            );
        }
    }, [collections]);

    const saveListOrder = () => {
        callUpdateCollectionAPI("PUT", `/api/activity_collections/`, {
            requests: collections.map((collection, index) => ({
                id: collection.id,
                order: index,
            })),
        });
    };

    return (
        <Board addList={addList}>
            {collections.map((collection, collectionIndex) => (
                <ListForCollection
                    collection={collection}
                    collectionIndex={collectionIndex}
                    updateCollection={(newCollection) => {
                        setCollections((prevCollections) => {
                            const index = prevCollections.findIndex((c) => c.id === newCollection.id);
                            return update(prevCollections, {
                                [index]: {
                                    $set: newCollection,
                                },
                            });
                        });
                    }}
                    removeCollectionFromBoard={removeCollectionFromBoard}
                    key={collection.id}
                    saveCardOrder={saveCardOrder}
                    moveCard={moveCard}
                    moveList={moveList}
                    saveListOrder={saveListOrder}
                />
            ))}
        </Board>
    );
};

export default ActivityBoard;
