import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Button, Dropdown, Input, Loader } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import "./Board.css";
import EditableText from "./EditableText";
import { Row } from "./Flex";

export const Card = ({
    id,
    cardIndex,
    listIndex,
    moveCard = () => null,
    saveCardOrder,
    isActionable = true,
    children,
    cardsMovable = true,
}) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "CARD",
            item: () => {
                return { id: id, cardIndex: cardIndex, listIndex: listIndex };
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [cardIndex, listIndex]
    );

    const [{ handlerId }, drop] = useDrop(
        {
            accept: "CARD",
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item, monitor) {
                if (!ref.current) {
                    return;
                }

                if (id === item.id) {
                    return;
                }

                const hoverCardIndex = cardIndex;
                const hoverListIndex = listIndex;

                if (item.cardIndex === hoverCardIndex && item.listIndex === hoverListIndex) {
                    return;
                }

                if (item.listIndex === hoverListIndex) {
                    // Determine rectangle on screen
                    const hoverBoundingRect = ref.current?.getBoundingClientRect();

                    // Get vertical middle
                    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                    // Determine mouse position
                    const clientOffset = monitor.getClientOffset();

                    // Get pixels to the top
                    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                    // Only perform the move when the mouse has crossed half of the items height
                    // When dragging downwards, only move when the cursor is below 50%
                    // When dragging upwards, only move when the cursor is above 50%

                    // Dragging downwards
                    if (item.cardIndex < hoverCardIndex && hoverClientY < hoverMiddleY) {
                        return;
                    }

                    // Dragging upwards
                    if (item.cardIndex > hoverCardIndex && hoverClientY > hoverMiddleY) {
                        return;
                    }
                }

                console.log(`Card ${id} is hovering over ${item.id} at ${hoverListIndex}:${hoverCardIndex}`);

                moveCard(item.listIndex, item.cardIndex, hoverListIndex, hoverCardIndex);

                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.listIndex = hoverListIndex;
                item.cardIndex = hoverCardIndex;

                return true;
            },
            drop: (item, monitor) => {
                console.log("Dropped", listIndex, cardIndex);
                saveCardOrder();
            },
        },
        [id, cardIndex, listIndex]
    );

    if (cardsMovable) {
        drag(drop(ref));
    }

    return (
        <div
            ref={ref}
            className={`card ${isDragging && "dragging"} ${isActionable && "actionable"}`}
            data-handler-id={handlerId}>
            {children}
        </div>
    );
};

export const List = ({ children, saving, addCard }) => {
    return (
        <div className="list">
            <div className="cards ">{children}</div>
            {saving && <Loader inline message={strings.saving} />}
        </div>
    );
};

export const ListWithTitle = ({
    id,
    title,
    menuItems,
    cardCount,
    listIndex,
    moveCard,
    moveList,
    saveListTitle,
    saveCardOrder,
    saveListOrder,
    addCard,
    cardsMovable = true,
    children,
}) => {
    const ref = useRef(null);

    const [, cardDrop] = useDrop(
        {
            accept: "CARD",
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item, monitor) {
                if (!ref.current || cardCount > 0) {
                    return;
                }

                const hoverCardIndex = 0;
                const hoverListIndex = listIndex;

                if (item.cardIndex === hoverCardIndex && item.listIndex === hoverListIndex) {
                    return;
                }

                console.log(`Card ${item.id} is hovering over EMPTY LIST ${id}`);
                moveCard(item.listIndex, item.cardIndex, hoverListIndex, hoverCardIndex);

                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.listIndex = hoverListIndex;
                item.cardIndex = hoverCardIndex;

                return true;
            },
            drop: (item, monitor) => {
                console.log("Dropped", listIndex, 0);
                saveCardOrder();
            },
        },
        [listIndex, cardCount]
    );

    const [{ isDragging }, listDrag] = useDrag(
        () => ({
            type: "LIST",
            item: () => {
                return { id: id, listIndex: listIndex };
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [listIndex]
    );

    const [, listDrop] = useDrop(
        {
            accept: "LIST",
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item, monitor) {
                if (!ref.current) {
                    return;
                }

                if (id === item.id) {
                    return;
                }

                const hoverListIndex = listIndex;

                if (item.listIndex === hoverListIndex) {
                    return;
                }

                moveList(item.listIndex, hoverListIndex);

                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.listIndex = hoverListIndex;

                return true;
            },
            drop: (item, monitor) => {
                saveListOrder();
            },
        },
        [listIndex]
    );

    listDrag(listDrop(ref));

    cardsMovable && cardCount === 0 && cardDrop(ref);

    return (
        <div className="list-container" ref={ref}>
            <div className="list-border">
                <Row wrap={false} centerAlign={false}>
                    <div className="list-title">
                        <EditableText text={title} onChange={saveListTitle} />
                    </div>
                    <Dropdown>
                        <Dropdown.Menu direction="left">{menuItems}</Dropdown.Menu>
                    </Dropdown>
                </Row>
                <List addCard={addCard}>{children}</List>
                {addCard && <div className="add-card">{addCard}</div>}
            </div>
            <div className="list-container-spacer">&nbsp;</div>
        </div>
    );
};

export const AddList = ({ addList }) => {
    const [adding, setAdding] = useState(false);
    const [title, setTitle] = useState("");

    return (
        <div className="list-container">
            {adding ? (
                <div className="list add-list">
                    <div>
                        <Input
                            type="text"
                            placeholder="List Title"
                            onChange={(e) => setTitle(e.target.value)}
                            autoFocus
                        />
                    </div>
                    <Button
                        content={strings.add}
                        onClick={() => {
                            setAdding(false);
                            addList(title);
                        }}
                        compact
                        primary
                        disabled={!title}
                    />
                    <Button
                        content={strings.cancel}
                        onClick={() => {
                            setAdding(false);
                        }}
                        compact
                    />
                </div>
            ) : (
                <div className="list add-list-placeholder" onClick={() => setAdding(true)}>
                    + Add List
                </div>
            )}
        </div>
    );
};

export const Board = ({ children, addList, enableAddList = true }) => {
    return (
        <div className="board">
            <div className="list-holder">
                {children}
                {enableAddList && <AddList addList={addList} />}
            </div>
        </div>
    );
};
