import { Input } from "semantic-ui-react";
import Phrase from "../components/Phrase";
import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";

export const TestPhrase = () => {
    const [phraseId, setPhraseId] = useState();
    const [phraseAPI, callPhraseAPI] = useAPI({ loading: false });

    useEffect(() => {
        if (phraseId) {
            callPhraseAPI("GET", `/api/phrases/${phraseId}`);
        }
    }, [phraseId]);

    return (
        <div>
            <Input
                onChange={(e) => {
                    setPhraseId(e.target.value);
                }}
            />

            {phraseAPI.loading && <p>Loading...</p>}
            {phraseAPI.response && (
                <p>
                    <Phrase
                        phrase={phraseAPI.response}
                        translationLanguage={"en"}
                        markUknownRemotely={true}
                        activityId={"foo"}
                    />
                </p>
            )}
        </div>
    );
};
