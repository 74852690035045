import { Label } from "semantic-ui-react";
import "./SelectableLabel.css";

const SelectableLabel = ({ label, selected, onClick, size, basic }) => {
    return (
        <Label
            onClick={onClick}
            className={`selectable ${selected && "selected"}`}
            active={selected}
            size={size}
            basic={basic}>
            {label}
        </Label>
    );
};

export default SelectableLabel;
