import "./MessageLimitIndicator.css";

const MessageLimitIndicator = ({ percentComplete, message, remaining }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
            <div style={{ marginRight: "0.5rem" }}>
                {remaining < 0 && <div style={{ color: "red" }}>{remaining}</div>}
                {remaining >= 0 && (
                    <div
                        className="CircleProgressBar"
                        style={{
                            backgroundImage: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${
                                percentComplete > 100 ? "red" : "#00868E"
                            } ${percentComplete}%, ${percentComplete > 100 ? "red" : "#e2f3f4"} 0)`,
                        }}>
                        {remaining < 20 ? remaining : ""}
                    </div>
                )}
            </div>
            {message}
        </div>
    );
};

export default MessageLimitIndicator;
