import { memo, useCallback, useEffect, useState } from "react";
import { Board, Card, ListWithTitle } from "../../components/Board";
import update from "immutability-helper";

const TestCard = ({ activity, cardIndex, collectionIndex, moveCard, saveCardOrder, cardsMovable }) => {
    return (
        <Card
            id={activity.id}
            cardIndex={cardIndex}
            listIndex={collectionIndex}
            saveCardOrder={saveCardOrder}
            moveCard={moveCard}
            cardsMovable={cardsMovable}>
            <div>test card {activity.id}</div>
        </Card>
    );
};

const isCardChanged = (prevProps, nextProps) => {
    // return true if props are equal
    return (
        prevProps.collectionIndex === nextProps.collectionIndex &&
        prevProps.cardIndex === nextProps.cardIndex &&
        prevProps.activity === nextProps.activity
    );
};

const MemoizedTestCard = memo(TestCard, isCardChanged);

export const TestList = ({
    collection,
    collectionIndex,
    removeCollectionFromBoard,
    updateCollection,
    saveCardOrder,
    moveCard,
    moveList,
    saveListOrder,
}) => {
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        console.log("Got new activities", collection.activities);
        setActivities(collection.activities);
    }, [collection, collection.activities]);

    return (
        <ListWithTitle
            key={collection.id}
            listIndex={collectionIndex}
            title={collection.name}
            saveListTitle={() => console.log("save list title")}
            id={collection.id}
            cardCount={activities.length}
            cardsMovable={true}
            addCard={<div>Add Card</div>}
            saving={collection.saving}
            menuItems={<></>}
            saveCardOrder={saveCardOrder}
            moveCard={moveCard}
            moveList={moveList}
            saveListOrder={saveListOrder}>
            {activities.map((activity, activityIndex) => (
                <MemoizedTestCard
                    activity={activity}
                    cardIndex={activityIndex}
                    collectionIndex={collectionIndex}
                    saveCardOrder={saveCardOrder}
                    moveCard={moveCard}
                    key={activity.id}
                    cardsMovable={true}
                />
            ))}
        </ListWithTitle>
    );
};

const isListChanged = (prevProps, nextProps) => {
    return prevProps.collection === nextProps.collection || prevProps.listIndex === nextProps.collectionIndex;
};

const MemoizedTestList = memo(TestList, isListChanged);

const TestBoard = () => {
    const [collections, setCollections] = useState([
        {
            id: 1,
            name: "Collection 1",
            activities: [
                { id: "a1", name: "Activity 1" },
                { id: "a2", name: "Activity 2" },
                { id: "a3", name: "Activity 3" },
            ],
        },
    ]);

    const moveCard = useCallback((fromCollectionIndex, fromActivityIndex, toCollectionIndex, toActivityIndex) => {
        console.log("Moving card", fromCollectionIndex, fromActivityIndex, toCollectionIndex, toActivityIndex);

        // use immutability helper to update collections
        if (fromCollectionIndex === toCollectionIndex) {
            console.log("Moving within same collection", fromCollectionIndex, toActivityIndex);
            setCollections((prevColletions) =>
                update(prevColletions, {
                    [fromCollectionIndex]: {
                        activities: {
                            $splice: [
                                [fromActivityIndex, 1],
                                [toActivityIndex, 0, prevColletions[fromCollectionIndex].activities[fromActivityIndex]],
                            ],
                        },
                    },
                })
            );
        } else {
            console.log("Moving between collections");
            setCollections((prevColletions) =>
                update(prevColletions, {
                    [fromCollectionIndex]: {
                        activities: {
                            $splice: [[fromActivityIndex, 1]],
                        },
                    },
                    [toCollectionIndex]: {
                        activities: {
                            $splice: [
                                [toActivityIndex, 0, prevColletions[fromCollectionIndex].activities[fromActivityIndex]],
                            ],
                        },
                    },
                })
            );
        }
    });

    function removeCollectionFromBoard(collection) {
        setCollections((prevCollections) => prevCollections.filter((c) => c.id !== collection.id));
    }

    const isSuperset = (superset, subset) => {
        return subset.every((val) => superset.includes(val)) && superset.length > subset.length;
    };

    const saveCardOrder = () => {};

    const moveList = useCallback((fromListIndex, toListIndex) => {
        console.log("Moving list", fromListIndex, toListIndex);
        setCollections((prevCollections) =>
            update(prevCollections, {
                $splice: [
                    [fromListIndex, 1],
                    [toListIndex, 0, prevCollections[fromListIndex]],
                ],
            })
        );
    });

    const saveListOrder = () => {};

    return (
        <Board addList={<div>AddList</div>}>
            {collections.map((collection, collectionIndex) => (
                <TestList
                    collection={collection}
                    collectionIndex={collectionIndex}
                    updateCollection={(newCollection) => {
                        setCollections((prevCollections) => {
                            const index = prevCollections.findIndex((c) => c.id === newCollection.id);
                            return update(prevCollections, {
                                [index]: {
                                    $set: newCollection,
                                },
                            });
                        });
                    }}
                    removeCollectionFromBoard={removeCollectionFromBoard}
                    key={collection.id}
                    saveCardOrder={saveCardOrder}
                    moveCard={moveCard}
                    moveList={moveList}
                    saveListOrder={saveListOrder}
                />
            ))}
        </Board>
    );
};

export default TestBoard;
