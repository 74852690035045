import { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import useTask from "../hooks/useTask";
import "./ActivityHelpModal.css";
import { HelpText } from "./HelpText";
import { ZataLoader } from "./ZataLoader";

export const ActivityHelp = ({ activity, setActivity }) => {
    const [activityHelpAPI, callActivityHelpAPI] = useAPI({ loading: false });
    const [helpText, setHelpText] = useState(activity.help);
    const [taskResult] = useTask(activityHelpAPI.response?.help_task_id);
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        if (!helpText) {
            checkForHelp();
        }
    }, [activity]);

    function checkForHelp(refresh = false) {
        callActivityHelpAPI(
            "GET",
            `/api/activities/${activity.id}?include_help=true&refresh_help=${refresh}&native_language=${nativeLanguage}`
        );
    }

    useEffect(() => {
        if (taskResult && taskResult.task.status === "COMPLETED") {
            callActivityHelpAPI(
                "GET",
                `/api/activities/${activity.id}?include_help=true&native_language=${nativeLanguage}`
            );
        }
    }, [taskResult]);

    useEffect(() => {
        if (activityHelpAPI.response?.help_task_id) {
            console.log("Starting help polling");
            let debounce = setTimeout(() => {
                checkForHelp(false);
            }, 1000);

            return () => clearTimeout(debounce);
        } else if (activityHelpAPI.response?.help) {
            setHelpText(activityHelpAPI.response.help);
        }
    }, [activityHelpAPI.response]);

    return (
        <>
            {helpText && <HelpText content={helpText} activityId={activity.id} />}

            {((activityHelpAPI.loading && !activityHelpAPI.response?.help) ||
                activityHelpAPI.response?.help_task_id) && <ZataLoader />}
        </>
    );
};
