import { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLanguage";
import useAPI from "../hooks/useAPI";
import { useQuery } from "../hooks/useQuery";
import { Dropdown } from "semantic-ui-react";

const SelectActivityCollection = ({
    selectedActivityCollection,
    setSelectedActivityCollection,
    disabled,
    compact = false,
}) => {
    const [activityCollections, setActivityCollections] = useState([]);
    const { language, nativeLanguage } = useLanguage();
    const [activityCollectionsAPI, callActivityCollectionsAPI] = useAPI();

    useEffect(() => {
        if (nativeLanguage) {
            callActivityCollectionsAPI(
                "GET",
                `/api/activity_collections?language=${language}&native_language=${nativeLanguage}`
            );
        }
    }, [language, nativeLanguage]);

    useEffect(() => {
        if (activityCollectionsAPI.response) {
            setActivityCollections(activityCollectionsAPI.response.filter((c) => c.tag !== "anytime"));
        }
    }, [activityCollectionsAPI.response]);

    return (
        <Dropdown
            className="button icon acitivty-collection-dropdown"
            floating
            primary
            compact={compact}
            options={activityCollections.map((c) => ({
                key: c.id,
                text: c.name_native ? c.name_native : c.name,
                value: c.id,
            }))}
            onChange={(e, { value }) => {
                setSelectedActivityCollection(activityCollections.find((c) => c.id === value));
            }}
            value={selectedActivityCollection ? selectedActivityCollection.id : activityCollections[0]?.id}
            button
            disabled={disabled}
        />
    );
};

export default SelectActivityCollection;
