import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { WalkthroughPopup } from "../components/Walkthrough";
import { strings } from "../utils/i18n.utils";
import useAuth from "../hooks/useAuth";

export const WalkthroughProviderContext = React.createContext({
    clozeView: null,
    hintView: null,
    promptView: null,
    tabooWordView: null,
    grammarThumbsDownView: null,
    grammarView: null,
    doneView: null,
    archiveView: null,
    skipView: null,
    textEditorView: null,
});

export default function WalkthroughProvider({ children }) {
    const { user } = useAuth();

    const clozeView = useInView();
    const hintView = useInView();
    const promptView = useInView();
    const tabooWordView = useInView();
    const grammarThumbsDownView = useInView();
    const grammarView = useInView();
    const doneView = useInView();
    const archiveView = useInView();
    const skipView = useInView();
    const textEditorView = useInView();

    const currentExplanationIndexRef = useRef(0);
    const [currentExplanationIndex, setCurrentExplanationIndex] = useState(0);
    const [explanations, setExplanations] = useState([]);

    function hasFeature(key) {
        return user.features !== null && user.features.includes(key);
    }

    useEffect(() => {
        if (hintView.inView && !hasFeature("completed_cloze_walkthrough")) {
            let newExplanations = [
                /*
                <WalkthroughPopup
                    view={clozeView}
                    text={strings.walkthrough_cloze}
                    closeExplanation={closeExplanation}
                    onboardKey={"completed_cloze_walkthrough"}
                />,
                <WalkthroughPopup
                    view={archiveView}
                    text={strings.walkthrough_cloze_archive}
                    closeExplanation={closeExplanation}
                />,
                <WalkthroughPopup
                    view={skipView}
                    text={strings.walkthrough_cloze_skip}
                    position={"top right"}
                    closeExplanation={closeExplanation}
                    last={true}
                />,
                */
            ];
            setExplanations(newExplanations);
        }
    }, [hintView.entry]);

    /*
    useEffect(() => {
        if (promptView.inView && !hasFeature("completed_open_question_walkthrough")) {
            let newExplanations = [
                <WalkthroughPopup view={doneView} text={strings.walkthrough_discussion_done_talking} closeExplanation={closeExplanation} last={true}  onboardKey={"completed_open_question_walkthrough"}/>,
            ]
            setExplanations(newExplanations)
        }
    }, [promptView.entry])
    */

    useEffect(() => {
        if (tabooWordView.inView && !hasFeature("completed_taboo_walkthrough")) {
            let newExplanations = [
                /*
                <WalkthroughPopup
                    view={promptView}
                    text={strings.walkthrough_taboo}
                    closeExplanation={closeExplanation}
                    onboardKey={"completed_taboo_walkthrough"}
                />,
                <WalkthroughPopup
                    view={promptView}
                    text={strings.walkthrough_taboo_end}
                    closeExplanation={closeExplanation}
                    last={true}
                />,
                */
            ];
            setExplanations(newExplanations);
        }
    }, [tabooWordView.entry]);
    /*
    useEffect(() => {
        if (promptView.inView && hasFeature("completed_twenty_questions_walkthrough")) {
            let newExplanations = [
                <WalkthroughPopup view={promptView} text={strings.walkthrough_twenty_questions} closeExplanation={closeExplanation}
                    onboardKey={"completed_twenty_questions_walkthrough"} last={true}/>,
                <WalkthroughPopup view={promptView} text={strings.walkthrough_twenty_questions_end} closeExplanation={closeExplanation}
                    last={true}/>,
            ]
            setExplanations(newExplanations)
        }
    }, [promptView.inView])
    */
    /*
    useEffect(() => {
        if (textEditorView.inView && !hasFeature("completed_grammar_walkthrough=True")) {
            let newExplanations = [
                <WalkthroughPopup
                    view={textEditorView}
                    text={strings.walkthrough_grammar_apply_correction}
                    closeExplanation={closeExplanation}
                    last={true}
                    onboardKey={"completed_grammar_walkthrough"}
                />,
            ];
            setExplanations(newExplanations);
        }
    }, [grammarThumbsDownView.entry, grammarView.entry]);
    */
    /*
    useEffect(() => {
        if (promptView.inView && !hasFeature("completed_lesson_walkthrough")) {
            let newExplanations = [
                <WalkthroughPopup view={promptView} text={strings.walkthrough_lesson} closeExplanation={closeExplanation} onboardKey={"completed_lesson_walkthrough"}/>,
                <WalkthroughPopup view={promptView} text={strings.walkthrough_lesson_2} closeExplanation={closeExplanation}/>,
            ]
            setExplanations(newExplanations)
        }
    }, [promptView.inView])
    */

    const closeExplanation = useCallback((showNextExplanation) => {
        if (showNextExplanation) {
            currentExplanationIndexRef.current += 1;
            setCurrentExplanationIndex(currentExplanationIndexRef.current);
        } else {
            setExplanations([]);
            setCurrentExplanationIndex(0);
        }
    });

    const contextValue = {
        clozeView,
        hintView,
        promptView,
        tabooWordView,
        grammarThumbsDownView,
        grammarView,
        doneView,
        archiveView,
        skipView,
        textEditorView,
    };

    return (
        <WalkthroughProviderContext.Provider value={contextValue}>
            {currentExplanationIndex < explanations.length && explanations[currentExplanationIndex]}

            {children}
        </WalkthroughProviderContext.Provider>
    );
}
