import { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import MessageLimitIndicator from "./MessageLimitIndicator";
import { SpeechInputBar } from "./SpeechInputBar";
import { SendIcon } from "./SvgIcons";
import { TextEditor } from "./TextEditor";
import useAuth from "../hooks/useAuth";
import { useAudio } from "../hooks/useAudio";

export const PromptInputBar = ({
    resetCount,
    textEditorRef,
    language,
    nativeLanguage,
    isAllMessagesLoaded,
    activityId,
    submit,
    onSpeechSubmit,
    onChange,
    disabled,
    onFocus,
    grammarCorrections,
    ignoredGrammarCorrections,
    setIgnoredGrammarCorrections,
    autoCapitalize,
    onApplyCorrection,
    sendCorrections,
    replacedValue,
    lastMessage,
    textMode,
    sendCommand,
    loadingPrompt,
}) => {
    const warning_limit = 350;
    const max_characters = 500;

    const [text, setText] = useState("");
    const [messageTooLong, setMessageTooLong] = useState(false);
    const { languageSettings } = useAuth();
    const [primaryButtons, setPrimaryButtons] = useState([]);
    const { queueAudioToPlay } = useAudio();

    const internalOnChange = (text) => {
        setText(text);
        onChange && onChange(text);
    };

    useEffect(() => {
        if (lastMessage?.options) {
            setPrimaryButtons(lastMessage.options.filter((option) => option.primary));
        } else {
            setPrimaryButtons([]);
        }
    }, [lastMessage]);

    function onPrimaryOptionlick(option) {
        if (option.command) {
            sendCommand(option.command, option.label, lastMessage.action_id);
        } else {
            if (option.phrase_id) {
                queueAudioToPlay(`/api/phrases/${option.phrase_id}/audio`, true);
            }

            submit(option.label);
        }
        setPrimaryButtons(null);
    }

    if (loadingPrompt) {
        return null;
    }

    return (
        <>
            {text.length >= warning_limit && (
                <MessageLimitIndicator
                    percentComplete={(text.length * 100) / max_characters}
                    remaining={max_characters - text.length}
                    message={strings.message_too_long}
                    setMessageTooLong={setMessageTooLong}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    justifyContent: "center",
                }}>
                {primaryButtons !== null && primaryButtons.length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}>
                        {primaryButtons.map((option) => (
                            <Button
                                key={"command-" + option.label}
                                onClick={() => onPrimaryOptionlick(option)}
                                disabled={disabled}
                                icon={option.icon}
                                primary
                                content={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: option.label,
                                        }}></span>
                                }></Button>
                        ))}
                    </div>
                )}
                {primaryButtons !== null && primaryButtons.length === 0 && (
                    <>
                        <div
                            className="editor-wrapper"
                            ref={textEditorRef}
                            style={{ flexGrow: 1, display: textMode ? null : "none" }}>
                            <Segment className="editor-segment">
                                <TextEditor
                                    resetCount={resetCount}
                                    placeholder={strings.type_answer_placeholder}
                                    initialText={replacedValue}
                                    language={language}
                                    nativeLanguage={nativeLanguage}
                                    activityId={activityId}
                                    onSubmit={submit}
                                    onChange={internalOnChange}
                                    grammarCorrections={grammarCorrections}
                                    ignoredGrammarCorrections={ignoredGrammarCorrections}
                                    setIgnoredGrammarCorrections={setIgnoredGrammarCorrections}
                                    sendCorrections={sendCorrections}
                                    autoCapitalize={autoCapitalize}
                                    onFocus={onFocus}
                                    onApplyCorrection={onApplyCorrection}
                                />
                            </Segment>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                <div
                                    style={{
                                        flexGrow: 4,
                                    }}></div>
                                <Button
                                    primary
                                    onClick={() => submit(text)}
                                    disabled={disabled || messageTooLong}
                                    style={{
                                        padding: "0.5rem",
                                        borderRadius: "50%",
                                        marginBottom: "0rem",
                                        margin: 0,
                                        width: "44px",
                                    }}>
                                    <SendIcon />
                                </Button>
                            </div>
                        </div>

                        {!textMode && (
                            <SpeechInputBar
                                onSpeechSubmit={onSpeechSubmit}
                                currentMessage={lastMessage}
                                language={language}
                                nativeLanguage={nativeLanguage}
                                autoTranscribe={languageSettings?.hands_free_mode_enabled}
                                appIsReady={isAllMessagesLoaded && !disabled}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
