import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./utils/report-web-vitals.utils";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

let height = window.visualViewport.height;
if (window.visualViewport) {
    function viewportResize(e) {
        const composerVH = window.visualViewport.height,
            doc = document.documentElement;

        if (composerVH !== height) {
            console.log("Resize from, to", window.visualViewport.height, window.innerHeight, height, new Date(), e);
            height = composerVH;
            // This delay makes the action a little bit smoother as it gives
            // the keyboard time to expand before adjusting the screen
            setTimeout(() => {
                let topHeight = window.getComputedStyle(document.getElementById("topbar")).height;
                console.log("Top height", topHeight);

                doc.style.setProperty("--composer-vh", `${window.visualViewport.height}px`);
                doc.style.setProperty(
                    "--composer-top-offset",
                    `calc(100svh - ${window.visualViewport.height}px - ${topHeight})`
                );
                doc.style.setProperty(
                    "--composer-bottom-offset",
                    `${window.innerHeight - window.visualViewport.height}px`
                );
            }, 0);
        }
    }
    window.visualViewport.addEventListener("resize", viewportResize);
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("content")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
