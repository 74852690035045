import { Segment } from "semantic-ui-react";
import { ContentWithLeft } from "../components/Flex";
import Banner from "../components/Banner";

function About() {
    return (
        <div className="vertically-centered">
            <Banner
                title="A language tutor for everyone"
                subtitle="Whether your language learning goal is to connect with others, get a job, open yourself to new experiences, or just learn for fun, we want to help you get to your destination &mdash; not just keep a 🔥 streak."
            />

            <Segment>
                <h1>Team</h1>
                <ContentWithLeft
                    left={
                        <img
                            src="/team/dan.jpeg"
                            alt="Dan Diephouse"
                            className={"avatar"}
                            style={{ float: "left", marginRight: "1rem" }}
                        />
                    }>
                    <p>
                        Jetway is the creation of Dan Diephouse. Originally learning French, so he could work remotely
                        from Paris, it spiraled out of control into several other languages when he discovered the
                        worlds other languages opened up. When he's not working on Jetway, he's the
                        co-founder/co-winemaker at <a href="https://corollarywines.com">Corollary Wines</a> or biking in
                        the mountains of Oregon.
                    </p>
                </ContentWithLeft>
                <ContentWithLeft
                    left={
                        <img
                            src="/team/jiamin.png"
                            alt="Carmen Ren"
                            className={"avatar"}
                            style={{ float: "left", marginRight: "1rem" }}
                        />
                    }>
                    <p>
                        Jiamin leads UX design at jetway. Passionate about languages, she has been reflecting on how our
                        words shape our thoughts, emotions and relationships. Since moving to New York, she has missed
                        the sounds of Cantonese, Mandarin, Hakka from home and the meows of her cat Xiuxiu.
                    </p>
                </ContentWithLeft>
            </Segment>
            <Segment>
                <h1>Get involved</h1>
                <p>
                    We are a self-funded, small team and we are currently looking for volunteers to help us add
                    languages. If this interests you, please reach out <a href="mailto:info@jetway.ai">over email</a> or
                    on <a href="https://discord.gg/A3nSQEQZ6f">Discord</a>.
                </p>
            </Segment>
        </div>
    );
}

export default About;
