import { Button, Divider, Label, Segment } from "semantic-ui-react";
import SegmentWithHeader from "../components/SegmentWithHeader";
import { Tile, Tilebar } from "../components/Tilebar";
import EmojiIcon from "../components/EmojiIcon";
import { ZataLoader } from "../components/ZataLoader";
import { Chat, ChatMessageLeft, ChatMessageRight, ChatRowLeft, ChatRowRight } from "../components/Chat";

function Components() {
    return (
        <div>
            <h1>Heading1</h1>
            <h2>Heading3</h2>
            <h3>Heading3</h3>
            <p>Paragraph text</p>

            <Segment>This is a Segment.</Segment>
            <SegmentWithHeader header={"Header"}>This is a Segment with a header.</SegmentWithHeader>

            <div>
                <h1>Buttons</h1>
                <Button content="Primary Button" primary />
                <Button content="Secondary Button" />
                <Button content="Active Button" active />
            </div>
            <Divider hidden />
            <div>
                <h1>Small + Compact buttons</h1>
                <Button content="Primary Button" primary compact size="small" />
                <Button content="Secondary Button" compact size="small" />
                <Button content="Active Button" compact size="small" active />
            </div>

            <Divider hidden />
            <div>
                <h1>Tilebar</h1>
                <Segment>
                    <Tilebar showTileOutlines={false}>
                        <Tile title="Tile 1" icon={<EmojiIcon emoji="🎯" />}>
                            <Label size="small" compact basic>
                                👋 Label
                            </Label>
                        </Tile>
                        <Tile title="Tile 2" icon={<EmojiIcon emoji="🏋️‍♀️" />} />
                    </Tilebar>
                </Segment>
            </div>
            <Divider hidden />

            <h1>Chat</h1>
            <Chat>
                <ChatRowLeft>
                    <ChatMessageLeft>Chat message left</ChatMessageLeft>
                </ChatRowLeft>
                <ChatRowLeft className="chat-bubble-last">
                    <ChatMessageLeft>Chat message left</ChatMessageLeft>
                </ChatRowLeft>
                <ChatRowRight>
                    <ChatMessageRight>Chat message right</ChatMessageRight>
                </ChatRowRight>

                <ChatRowLeft>
                    <ChatMessageLeft>Chat message left</ChatMessageLeft>
                </ChatRowLeft>
                <ChatRowLeft className="chat-bubble-last">
                    <ChatMessageLeft>Chat message left</ChatMessageLeft>
                </ChatRowLeft>
                <ChatRowRight>
                    <ChatMessageRight>Chat message right</ChatMessageRight>
                </ChatRowRight>
            </Chat>
            <Divider hidden />

            <div>
                <h1>Loader</h1>
                <ZataLoader message="Loading message" />
            </div>
        </div>
    );
}

export default Components;
