import { Progress } from "semantic-ui-react";
import useAuth from "../hooks/useAuth";
import { Row } from "./Flex";
import { strings } from "../utils/i18n.utils";

const DailyProgressBar = ({ progress }) => {
    const { statistics, languageSettings } = useAuth();

    return (
        <div>
            {statistics.points >= languageSettings.daily_points_goal ? (
                <p>{strings.daily_goal_reached}</p>
            ) : (
                <Row>
                    <Progress
                        value={statistics.points}
                        total={languageSettings.daily_points_goal}
                        size="tiny"
                        style={{
                            marginBottom: "0rem",
                            display: "inline-block",
                        }}
                    />
                    <span style={{ fontSize: "0.8125rem" }}>
                        {((statistics.points * 100) / languageSettings.daily_points_goal).toFixed()} %{" "}
                        {strings.of_your_daily_goal}
                    </span>
                </Row>
            )}
        </div>
    );
};

export default DailyProgressBar;
