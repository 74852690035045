import { Select } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";

const LevelSelector = ({ defaultLevel, onChange }) => {
    return (
        <Select
            basic
            compact
            value={defaultLevel}
            onChange={(e, { value }) => onChange && onChange(value)}
            options={[
                { key: "A1", text: strings.a1_description_and_cefr, value: "A1" },
                { key: "A2", text: strings.a2_description_and_cefr, value: "A2" },
                { key: "B1", text: strings.b1_description_and_cefr, value: "B1" },
                { key: "B2", text: strings.b2_description_and_cefr, value: "B2" },
            ]}
        />
    );
};

export default LevelSelector;
