import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAPI from "../hooks/useAPI.js";
import useAuth from "../hooks/useAuth.js";
import { strings } from "../utils/i18n.utils.js";

const languages = {
    "en-US": "en",
    "en-GB": "en",
};

function browserLanguage() {
    const language = navigator.language;

    return languages[language] || language;
}

export const LanguageProviderContext = React.createContext({
    language: null,
    nativeLanguage: browserLanguage() || "en",
    setLanguage: () => null,
});

const LanguageProvider = ({ children }) => {
    const { language, nativeLanguage } = useParams();
    const [updateUserAPI, callUpdateUserAPI] = useAPI();
    const { user, setUser, setStatistics, setLanguageSettings } = useAuth();
    const [_language, _setLanguage] = useState();
    const [_nativeLanguage, _setNativeLanguage] = useState(
        nativeLanguage || user?.current_native_language || browserLanguage() || "en"
    );

    useEffect(() => {
        // Got a new URL param, use that
        console.log("Got new language", language);
        if (language) {
            _setLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        console.log("Got new native language", nativeLanguage);
        // Got a new URL param, use that
        if (nativeLanguage) {
            _setNativeLanguage(nativeLanguage);
            strings.setLanguage(nativeLanguage);
        }
    }, [nativeLanguage]);

    useEffect(() => {
        // Update the user remotely with the new language info if it changes
        console.log("Got language change so let's update the user", user, _language, _nativeLanguage);
        if (
            user &&
            _language &&
            _nativeLanguage &&
            (user.current_language !== _language || user.current_native_language !== _nativeLanguage)
        ) {
            callUpdateUserAPI("PUT", `/api/user`, {
                current_language: _language,
                current_native_language: _nativeLanguage,
            });
        }
    }, [_language, _nativeLanguage]);

    useEffect(() => {
        if (updateUserAPI.response) {
            setUser(updateUserAPI.response.user);
            setStatistics(updateUserAPI.response.statistics);
            setLanguageSettings(updateUserAPI.response.language_settings);
        }
    }, [updateUserAPI.response]);

    const contextValue = {
        language: _language || user?.current_language,
        nativeLanguage: _nativeLanguage || user?.current_native_language || browserLanguage() || "en",
        setLanguage: _setLanguage,
    };

    return <LanguageProviderContext.Provider value={contextValue}>{children}</LanguageProviderContext.Provider>;
};
export default LanguageProvider;
