import { useEffect, useState } from "react";
import { Divider, Input } from "semantic-ui-react";
import ActivityBoard from "../components/ActivityBoard";
import { HomeTopBarPortal } from "../components/HomeTopBar";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";

const HomeContent = () => {
    const { language, nativeLanguage } = useLanguage();
    const [activitiesAPI, callActivitiesAPI] = useAPI();

    const [collections, setCollections] = useState([]);

    const { setStatistics } = useAuth();

    useEffect(() => {
        callActivitiesAPI("GET", `/api/languages/${language}/${nativeLanguage}/home?include_activities=true`);
    }, [language, nativeLanguage]);

    useEffect(() => {
        if (activitiesAPI.response && activitiesAPI.response.activity_collections.length > 0) {
            const newCollections = [];
            for (let group of activitiesAPI.response.activity_collections) {
                if (group.tag !== "anytime") {
                    newCollections.push(group);
                }
            }
            setCollections(newCollections);
            setStatistics(activitiesAPI.response.statistics);
        }
    }, [activitiesAPI.response, language, nativeLanguage]);

    if (activitiesAPI.loading) {
        return (
            <>
                <Divider hidden />
                <ZataLoader message={strings.activities_loading} />
            </>
        );
    }

    return <ActivityBoard collections={collections} setCollections={setCollections} />;
};

export const AddActivityCollection = ({ addActivityCollection, onFinish }) => {
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            addActivityCollection(event.target.value).then(() => onFinish());
        }
    };
    return (
        <Input
            type="text"
            placeholder="Notebook name"
            autoFocus
            onKeyPress={handleKeyPress}
            onBlur={(e) => {
                if (e.target.value) {
                    addActivityCollection(e.target.value).then(() => onFinish());
                }
            }}
            style={{
                width: "100%",
            }}
        />
    );
};

export const Home = () => {
    return (
        <>
            <HomeTopBarPortal />

            <HomeContent />
        </>
    );
};
