import { Button } from "semantic-ui-react";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

function Landing() {
    useTitle(
        "Jetway - Go anywhere with your AI language tutor",
        null,
        "Study any topic with personalized chats, exercises, and recommendations."
    );

    return (
        <>
            <div className="landing-content" id="landing-content">
                <div className="vertically-centered">
                    <div className={"split-screen"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                // center the text
                            }}>
                            <div
                                style={{
                                    padding: "2rem",
                                    backgroundColor: "var(--secondary-background-color)",
                                    borderRadius: "0.823rem",
                                }}>
                                <h1>{strings.tagline}</h1>
                                <p style={{ maxWidth: "390px", marginBottom: "1.8rem", textAlign: "center" }}>
                                    {strings.product_description}
                                </p>
                                <Button
                                    content={strings.get_started}
                                    as={"a"}
                                    href={`/login`}
                                    primary
                                    active
                                    className="wide"
                                    size={"large"}
                                />
                            </div>
                        </div>
                        <div className="screenshot-holder">
                            <img src="./product/home.png" className="screenshot" />
                        </div>
                    </div>
                </div>

                <p>
                    <a href="/overview">{strings.learn_more}</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="/teachers">For Teachers</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a
                        href="https://medium.com/jetway/organize-immerse-review-repeat-f20a9ad4bdf8"
                        target="_blank"
                        rel="noreferrer">
                        What's new
                    </a>
                </p>
            </div>
        </>
    );
}

export default Landing;
