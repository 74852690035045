import { useState } from "react";
import { Input, Loader } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";

const EditableText = ({ text: initialText, onChange }) => {
    const [editingTitle, setEditingTitle] = useState(false);
    const [text, setText] = useState(initialText);
    const [saving, setSaving] = useState(false);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setEditingTitle(false);
            saveChange(text);
        }
    };

    const saveChange = (newText) => {
        setText(newText);
        const promise = onChange(newText);
        if (promise) {
            setSaving(true);
            promise.then(() => {
                setSaving(false);
            });
        }
    };

    return (
        <>
            {saving && <Loader size="tiny" message={strings.saving} inline={true} />}

            {!saving && editingTitle && (
                <Input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    autoFocus
                    onBlur={(e) => {
                        setEditingTitle(false);
                        saveChange(text);
                    }}
                />
            )}
            {!saving && !editingTitle && <div onClick={() => setEditingTitle(true)}>{text}</div>}
        </>
    );
};
export default EditableText;
