import { Archive, Barbell, Check, Plus, Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Tab } from "semantic-ui-react";
import ActivityCollectionDeleteDialog from "../components/ActivityCollectionDeleteDialog";
import ActiivtyCollectionShareButton from "../components/ActivityCollectionShareButton";
import ActivityCollectionTitle from "../components/ActivityCollectionTitle";
import { Activity, ActivitySkills } from "../components/ActivityDetails";
import { EmptyState } from "../components/EmptyState";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import useNotifications from "../hooks/useNotifications";
import { strings } from "../utils/i18n.utils";
import useTitle from "../hooks/useTitle";
import LoadableButton from "../components/LoadableButton";
import SegmentWithHeader from "../components/SegmentWithHeader";

const ActivityCollection = ({ collection, setCollection }) => {
    const { user } = useAuth();
    const [activityTreeAPI, callActivityTreeAPI] = useAPI({ loading: false });
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const { language, nativeLanguage } = useLanguage();
    const [, callUpdateCollectionAPI] = useAPI();
    const [archiveCollectionAPI, callArchiveCollectionAPI] = useAPI();
    const history = useHistory();

    function addActivityToUserTree() {
        callActivityTreeAPI("POST", `/api/activity_collections/${collection.id}/add`);
    }

    function deleteActivityCollection() {
        callActivityTreeAPI("DELETE", `/api/activity_collections/${collection.id}`);
    }

    useEffect(() => {
        if (activityTreeAPI.response) {
            setCollection({ ...collection, is_added_to_users_tree: activityTreeAPI.response.is_added_to_users_tree });
        }
    }, [activityTreeAPI.response]);

    const archiveCollection = () => {
        callUpdateCollectionAPI("PUT", `/api/activity_collections/${collection.id}`, {
            archived: !collection.archived,
        });
        setCollection({ ...collection, archived: !collection.archived });
    };

    const saveCollectionName = (name) => {
        const promise = callArchiveCollectionAPI("PUT", `/api/activity_collections/${collection.id}`, {
            name: name,
        });
        setCollection({ ...collection, name: name });

        return promise;
    };

    const panes = [
        {
            menuItem: strings.activities,
            render: () => (
                <>
                    {collection.activities.map((activity, activityIndex) => (
                        <Activity
                            key={activity.id}
                            activity={activity}
                            collection={collection}
                            showActivityType={true}
                        />
                    ))}
                </>
            ),
        },
        {
            menuItem: strings.skills,
            render: () => (
                <>
                    <SegmentWithHeader header={strings.skills_core}>
                        <ActivitySkills skills={collection.skills} />
                    </SegmentWithHeader>
                    <SegmentWithHeader header={strings.skills_saved}>
                        <ActivitySkills skills={collection.skills_saved} />
                    </SegmentWithHeader>
                    <SegmentWithHeader header={strings.skills_from_corrections}>
                        <ActivitySkills skills={collection.skills_from_corrections} />
                    </SegmentWithHeader>
                </>
            ),
        },
    ];

    return (
        <div>
            <ActivityCollectionTitle collection={collection} saveCollectionName={saveCollectionName} />

            <div style={{ marginBottom: "1rem" }}>
                <Button
                    icon={<Barbell />}
                    content={strings.activity_practice}
                    primary
                    onClick={deleteActivityCollection}
                    disabled={activityTreeAPI.loading}
                    compact
                />
                {user && collection.user_id !== user.id && collection.is_added_to_users_tree && (
                    <Button
                        icon={<Check />}
                        content={strings.activity_collection_remove_from_board}
                        primary
                        onClick={deleteActivityCollection}
                        disabled={activityTreeAPI.loading}
                        compact
                    />
                )}
                {user && collection.user_id !== user.id && !collection.is_added_to_users_tree && (
                    <Button
                        icon={<Plus />}
                        content={strings.activity_collection_add_to_board}
                        primary
                        onClick={addActivityToUserTree}
                        disabled={activityTreeAPI.loading}
                        compact
                    />
                )}

                <ActiivtyCollectionShareButton collection={collection} content={strings.share} compact={true} />

                {user && collection.user_id === user.id && !collection.archived && (
                    <LoadableButton
                        icon={<Archive />}
                        onClick={archiveCollection}
                        compact
                        content={strings.archive}
                        loading={archiveCollectionAPI.loading}
                    />
                )}

                {user && collection.user_id === user.id && collection.archived && (
                    <>
                        <LoadableButton
                            onClick={archiveCollection}
                            compact
                            content={strings.restore}
                            loading={archiveCollectionAPI.loading}
                        />
                        <Button icon={<Trash />} onClick={() => setDeleteDialogOpen(true)} compact />
                    </>
                )}
            </div>

            <Tab panes={panes} menu={{ secondary: true, pointing: true }} />

            <ActivityCollectionDeleteDialog
                collection={collection}
                onDelete={() => history.push(`/languages/${language}/${nativeLanguage}/home`)}
                isOpen={isDeleteDialogOpen}
                setOpen={setDeleteDialogOpen}
            />
        </div>
    );
};

const ActivityCollectionScreen = () => {
    const { activityCollectionId } = useParams();
    const { nativeLanguage } = useLanguage();
    const { addNotification } = useNotifications();

    const [activityCollectionAPI, callActivityCollectionAPI] = useAPI();
    const { setPageTitle } = useTitle();

    const errorHandler = (error) => {
        if (error.status === 404) {
            return;
        }
        addNotification("api", strings.error_api, error.message);
    };
    useEffect(() => {
        callActivityCollectionAPI(
            "GET",
            `/api/activity_collections/${activityCollectionId}?native_language=${nativeLanguage}&include_skills=True`,
            null,
            null,
            errorHandler
        );
    }, [activityCollectionId]);

    useEffect(() => {
        if (activityCollectionAPI.response) {
            setPageTitle(activityCollectionAPI.response.name_native);
        }
    }, [activityCollectionAPI.response]);

    if (activityCollectionAPI.loading) {
        return <ZataLoader />;
    }

    if (!activityCollectionAPI.error && !activityCollectionAPI.response)
        return <ZataLoader message={strings.loading} />;

    if (activityCollectionAPI.error && activityCollectionAPI.error.status === 404) {
        return (
            <>
                <EmptyState
                    message={
                        <>
                            <h1>{strings.activity_collection_not_found}</h1>{" "}
                            <p>{strings.activity_collection_not_found_details}</p>
                        </>
                    }
                />
            </>
        );
    }

    if (!activityCollectionAPI.response) {
        return <div />;
    }

    return (
        <ActivityCollection
            collection={activityCollectionAPI.response}
            setCollection={(c) => activityCollectionAPI.setResponse(c)}
        />
    );
};

export default ActivityCollectionScreen;
