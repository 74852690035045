import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import useTitle from "../hooks/useTitle";
import { timeSince } from "../utils/time.utils";
import "./Notes.css";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { strings } from "../utils/i18n.utils";

const Notes = () => {
    useTitle(strings.notes, strings.notes);

    const [notesAPI, callNotesAPI] = useAPI();
    const [notes, setNotes] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const { language } = useLanguage();
    const limit = 20;
    const history = useHistory();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        setNotes([]);
        callNotesAPI("GET", `/api/notes?language=${language}&limit=${limit}`);
    }

    function loadMore() {
        callNotesAPI("GET", `/api/notes?language=${language}&offset=${notes.length}&limit=${limit}`);
    }

    useEffect(() => {
        if (notesAPI.response) {
            setNotes([...notes, ...notesAPI.response]);

            if (notesAPI.response.length < limit) {
                setHasMore(false);
            }
        }
    }, [notesAPI.response]);

    if (notesAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <div className="note-list">
            <Button onClick={() => history.push(`/notes/new`)} content={"Add Note"} />
            <InfiniteScroll
                dataLength={notes.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<ZataLoader />}
                endMessage={<p>{strings.notes_empty_state}</p>}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {notes.map((note) => (
                    <a key={note.id} href={`/notes/${note.id}`} className="note">
                        <div className="note-title">{note.title || strings.notes_untitled_note}</div>
                        <div className="note-time-since">{timeSince(note.updated)}</div>
                    </a>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default Notes;
