import { Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { Row } from "../components/Flex";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "../hooks/useLanguage";

const ActivityVocabScreen = () => {
    const [vocabAPI, callVocabAPI] = useAPI();
    const { activityId } = useParams();
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        callVocabAPI("GET", `/api/activities/${activityId}/vocab?native_language=${nativeLanguage}`);
    }, [activityId]);

    if (!vocabAPI.loading && !vocabAPI.response) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Activity Vocab</h1>
            {vocabAPI.response?.map((vocab) => (
                <Segment>
                    <Row>
                        <span>{vocab.phrase.text}</span>
                        <span>{vocab.flashcard_count}</span>
                        <span>{vocab.probability_known}</span>
                    </Row>
                </Segment>
            ))}
        </div>
    );
};

export default ActivityVocabScreen;
