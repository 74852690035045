import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Label, Progress, Segment } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { StackableRow } from "./Flex";
import "./PracticeActivityStatistics.css";
import { ZataLoader } from "./ZataLoader";
import { strings } from "../utils/i18n.utils";

const PracticeActivityStatistic = ({ strength, label }) => {
    const getStrengthLabel = (strength) => {
        if (strength === null || strength === undefined) {
            return strings.skill_level_unknown;
        }

        const STRENGTH_LABELS = [
            strings.skill_level_1,
            strings.skill_level_2,
            strings.skill_level_3,
            strings.skill_level_4,
        ];
        const STRENGTH_THRESHOLDS = [0.25, 0.5, 0.75];

        for (let i = 0; i < STRENGTH_THRESHOLDS.length; i++) {
            if (strength < STRENGTH_THRESHOLDS[i]) {
                return STRENGTH_LABELS[i];
            }
        }

        return STRENGTH_LABELS[STRENGTH_LABELS.length - 1];
    };

    return (
        <div>
            <Label style={{ width: "100%" }}>
                <div className="practice-activity-statistic-header">{label + ": " + getStrengthLabel(strength)}</div>
                {strength ? <Progress percent={strength * 100} size="tiny" /> : <Progress percent={0} size="tiny" />}
            </Label>
        </div>
    );
};

export const PracticeActivityStatistics = ({ statistics }) => {
    return (
        <StackableRow columns={3} className={"practice-activity-statistics"}>
            <PracticeActivityStatistic
                strength={statistics.reading_statistics?.strength}
                label={strings.skill_reading}
            />
            <PracticeActivityStatistic
                strength={statistics.listening_statistics?.strength}
                label={strings.skill_listening}
            />
            <PracticeActivityStatistic strength={statistics.output_statistics?.strength} label={strings.skill_output} />
        </StackableRow>
    );
};

const Statistics = () => {
    const { activityCollectionId, activityId } = useParams();

    const [statisticsAPI, callStatisticsAPI] = useAPI();

    useEffect(() => {
        if (activityCollectionId) {
            callStatisticsAPI("GET", `/api/activity_collections/${activityCollectionId}/practice_statistics`);
        } else {
            callStatisticsAPI("GET", `/api/activities/${activityId}/practice_statistics`);
        }
    }, []);

    if (statisticsAPI.loading) {
        return <ZataLoader />;
    }

    return (
        <Segment>
            <PracticeActivityStatistics statistics={statisticsAPI.response} />

            <p>Vocab stats</p>
            <p>
                Vocab Min:{statisticsAPI.response.vocab_statistics.vocab_difficulty_minimum}
                <br />
                Vocab Median:{statisticsAPI.response.vocab_statistics.vocab_difficulty_median}
                <br />
                Vocab Max:{statisticsAPI.response.vocab_statistics.vocab_difficulty_maximum}
                <br />
                Vocab Total:{statisticsAPI.response.vocab_statistics.vocab_with_flashcard_reviews} /
                {statisticsAPI.response.vocab_statistics.vocab_total}
                <br />
            </p>
            {}
            {Object.keys(statisticsAPI.response.flashcard_statistics).map((type) => (
                <div>
                    <h2>{type}</h2>
                    <p>
                        Vocab: {statisticsAPI.response.flashcard_statistics[type].vocab_ability} /{" "}
                        {statisticsAPI.response.global_flashcard_statistics[type].vocab_ability}
                    </p>
                    <p>Percentage correct: {statisticsAPI.response.flashcard_statistics[type].percentage_correct}</p>
                </div>
            ))}
        </Segment>
    );
};

export default Statistics;
