import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { Button, Container, Divider, Input, Label } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { useQuery } from "../hooks/useQuery";
import { allStrings, strings } from "../utils/i18n.utils";
import { ClickableSegment } from "../components/ClickableSegment";
import { ZataLoader } from "../components/ZataLoader";
import "./Start.css";
import ButtonBar from "../components/ButtonBar";
import LoadableButton from "../components/LoadableButton";
import SelectableLabel from "../components/SelectableLabel";
import { toCoolEmojis } from "../utils/emoji.utils";

const nativeLanguages = {
    en: "I speak English",
    es: "Hablo español",
    fr: "Je parle français",
    de: "Ich spreche Deutsch",
    it: "Parlo italiano",
    "pt-pt": "Eu falo português (Europeu)",
    "pt-br": "Eu falo português (Brasileiro)",
    uk: "Я розмовляю українською",
    ru: "Я говорю по-русски",
};

export const StartProgressBar = ({ steps, activeStep }) => {
    const percentDone = (activeStep / steps) * 100;

    return createPortal(
        <Container>
            <div className="ui tiny progress" data-percent={`${percentDone}`}>
                <div className="bar" style={{ width: `${percentDone}%` }}></div>
                <div className="label">
                    {activeStep + 1} / {steps}
                </div>
                <div style={{ marginBottom: "2rem" }}></div>
            </div>
        </Container>,
        document.getElementById("topbar")
    );
};

export const SelectNativeLanguage = ({ next, prev }) => {
    const [nativeLanguage, setNativeLanguage] = useState(null);
    const { user, setUser } = useAuth();

    useEffect(() => {
        if (nativeLanguage) {
            strings.setLanguage(nativeLanguage);
            user.current_native_language = nativeLanguage;
            setUser(user);
            next();
        }
    }, [nativeLanguage]);

    return (
        <div>
            {Object.keys(nativeLanguages).map((key) => (
                <ClickableSegment onClick={() => setNativeLanguage(key)}>{nativeLanguages[key]}</ClickableSegment>
            ))}

            <NextPrevButtons onPrev={prev} />
        </div>
    );
};

const SelectLanguage = ({ next, prev }) => {
    const [language, setLanguage] = useState(null);
    const { user, setUser } = useAuth();

    useEffect(() => {
        if (language) {
            user.current_language = language;
            setUser(user);
            next();
        }
    }, [language]);

    return (
        <div>
            <p>
                <b>{strings.i_want_to_learn}.</b>
            </p>
            {!language &&
                Object.keys(nativeLanguages)
                    .filter((key) => key !== user.current_native_language && !["uk"].includes(key))
                    .map((key) => <ClickableSegment onClick={() => setLanguage(key)}>{strings[key]}</ClickableSegment>)}

            {language && <ClickableSegment onClick={() => setLanguage(null)}>{strings[language]}</ClickableSegment>}

            <NextPrevButtons onPrev={prev} />
        </div>
    );
};

const SelectLevel = ({ next, prev }) => {
    const { user, setLanguageSettings } = useAuth();
    const [levels, setLevels] = useState([]);
    const [settingsAPI, callSettingsAPI] = useAPI({ loading: false });

    useEffect(() => {
        setLevels([
            { key: "A1", message: strings.level_a1_description },
            { key: "A2", message: strings.level_a2_description },
            { key: "B1", message: strings.level_b1_description },
            { key: "B2", message: strings.level_b2_description },
        ]);
    }, []);

    function setLevel(level) {
        user.level = level;
        callSettingsAPI("PUT", `/api/languages/${user.current_language}/${user.current_native_language}/settings`, {
            self_reported_level: level,
        });
        next();
    }

    useEffect(() => {
        if (settingsAPI.response) {
            setLanguageSettings(settingsAPI.response);
        }
    }, [settingsAPI.response]);

    return (
        <div>
            <p>
                <b>
                    {strings.formatString(
                        strings.level_question,
                        user.current_language + "_with_article" in allStrings[user.current_native_language]
                            ? allStrings[user.current_native_language][user.current_language + "_with_article"]
                            : allStrings[user.current_native_language][user.current_language]
                    )}
                </b>
            </p>
            {levels.map((level) => (
                <ClickableSegment onClick={() => setLevel(level.key)} key={level.key}>
                    <b>{allStrings[user.current_native_language][level.key.toLowerCase() + "_description_and_cefr"]}</b>
                    <p>{level.message}</p>
                </ClickableSegment>
            ))}

            <NextPrevButtons onPrev={prev} />
        </div>
    );
};

const SetName = ({ prev, next }) => {
    const [userAPI, callUserAPI] = useAPI({ loading: false });
    const { user, setUser, setLanguageSettings, setStatistics } = useAuth();
    const [name, setName] = useState(user.name);

    useEffect(() => {
        user.name = name;
        setUser(user);
    }, [name]);

    const saveUser = () => {
        callUserAPI("PUT", "/api/user", {
            current_language: user.current_language,
            current_native_language: user.current_native_language,
            name: user.name,
        });
    };

    useEffect(() => {
        if (userAPI.response) {
            setUser(userAPI.response.user);
            setStatistics(userAPI.response.statistics);
            setLanguageSettings(userAPI.response.language_settings);
            next();
        }
    }, [userAPI.response]);

    return (
        <div>
            <div style={{ marginBottom: "0.5rem" }}>
                <label>{strings.my_name_is}</label>
            </div>

            <Input value={name} onChange={(e) => setName(e.target.value)} autoFocus />

            <NextPrevButtons onNext={saveUser} isNextLoading={userAPI.loading} onPrev={prev} />
        </div>
    );
};

const SelectTopics = ({ next, prev }) => {
    const [recommendationsAPI, callRecommendationsAPI] = useAPI();
    const [saveRecommendationsAPI, callSaveRecommendationsAPI] = useAPI();
    const { language, nativeLanguage } = useLanguage();
    const [selectedRecommendations, setSelectedRecommendations] = useState([]);

    function selectRecommendation(recommendation) {
        if (selectedRecommendations.includes(recommendation)) {
            setSelectedRecommendations(selectedRecommendations.filter((r) => r !== recommendation));
        } else {
            setSelectedRecommendations([...selectedRecommendations, recommendation]);
        }
    }

    useEffect(() => {
        callRecommendationsAPI("GET", `/api/languages/${language}/${nativeLanguage}/recommendations`);
    }, [language, nativeLanguage]);

    const saveRecommendations = () => {
        callSaveRecommendationsAPI("POST", "/api/recommendations/accept", {
            recommendation_ids: selectedRecommendations.map((r) => r.id),
            native_language: nativeLanguage,
        });
    };

    useEffect(() => {
        if (saveRecommendationsAPI.response) {
            next();
        }
    }, [saveRecommendationsAPI.response]);

    return (
        <div>
            <div style={{ marginBottom: "0.5rem" }}>
                <label>{strings.start_select_recommendations}</label>
            </div>
            <Divider />
            {recommendationsAPI.loading && <ZataLoader />}

            <ButtonBar centered>
                {recommendationsAPI.response?.map((r) => (
                    <SelectableLabel
                        key={r.id}
                        basic={true}
                        size={"medium"}
                        label={
                            <span>
                                {toCoolEmojis(r.emoji)} {r.topic_native ? r.topic_native : r.topic}
                            </span>
                        }
                        onClick={() => selectRecommendation(r)}
                        selected={selectedRecommendations.includes(r)}
                    />
                ))}
            </ButtonBar>

            <NextPrevButtons
                onDone={saveRecommendations}
                onPrev={prev}
                isDoneDisabled={selectedRecommendations.length < 1}
                isDoneLoading={saveRecommendationsAPI.loading}
            />
        </div>
    );
};

const NextPrevButtons = ({ onNext, isNextDisabled, isNextLoading, onDone, isDoneLoading, isDoneDisabled, onPrev }) => {
    return (
        <>
            <Divider />

            <div>
                {onPrev && <Button onClick={onPrev} content={strings.previous} />}
                {onNext && (
                    <LoadableButton
                        primary
                        onClick={onNext}
                        content={strings.next}
                        loading={isNextLoading}
                        disabled={isNextDisabled}
                    />
                )}

                {onDone && (
                    <LoadableButton
                        primary
                        onClick={onDone}
                        content={strings.done}
                        loading={isDoneLoading}
                        disabled={isDoneDisabled}
                    />
                )}
            </div>
        </>
    );
};

export const Start = () => {
    const [step, setStep] = useState(0);
    const history = useHistory();
    const { user } = useAuth();
    const query = useQuery();

    const next = () => {
        if (step === 4) {
            const redirectTo = query.get("redirectTo");
            if (redirectTo) {
                history.push(redirectTo);
            } else {
                history.push(`/languages/${user.current_language}/${user.current_native_language}/home`);
            }
        } else {
            setStep(step + 1);
        }
    };

    const prev = () => {
        setStep(step - 1);
    };

    const stepComponents = [
        <SelectNativeLanguage next={next} />,
        <SelectLanguage next={next} prev={prev} />,
        <SelectLevel next={next} prev={prev} />,
        <SetName next={next} prev={prev} />,
        <SelectTopics next={next} prev={prev} />,
    ];

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <StartProgressBar steps={stepComponents.length} activeStep={step} />

            {stepComponents[step]}
        </Container>
    );
};

export const SelectLevelStandalone = () => {
    const history = useHistory();
    const { language, nativeLanguage } = useLanguage();

    function next() {
        history.push(`/languages/${language}/${nativeLanguage}/home`);
    }

    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <SelectLevel next={next} />
        </Container>
    );
};
