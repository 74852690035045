import React, { useEffect, useState } from "react";
import { StatisticsBar, StatisticsRow, StreakIcon } from "../components/StatisticsBar";

import { useLanguage } from "../hooks/useLanguage";

export function StreakBar({ progress: initialProgress }) {
    const [progress, setProgress] = useState([]);
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        if (!initialProgress) {
            return;
        }

        // get last 7 days
        let newProgress = initialProgress.slice(initialProgress.length - 7);

        newProgress = newProgress.map((stats, index) => {
            // Day in the format "2021-01-01"
            // We have to reformat it because JavaScript dates are fuuuucked and it gets interpreted as one day earlier
            const date = new Date(stats.day.replace(/-/g, "/"));
            const dayName = date.toLocaleString(nativeLanguage, { weekday: "long" });

            return {
                statistics: stats,
                dayName: dayName,
            };
        });
        console.log("Progress", newProgress);

        setProgress(newProgress);
    }, []);

    return (
        <StatisticsBar>
            <StatisticsRow>
                {progress.map((p, index) => (
                    <div
                        style={{
                            flexBasis: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.88rem",
                        }}>
                        <div>{p.dayName.substring(0, 1)}</div>
                        <div>
                            <StreakIcon
                                type={
                                    p.statistics.streak_freeze
                                        ? "freeze"
                                        : p.statistics.points_goal_met
                                        ? "streak"
                                        : index === 6
                                        ? "today"
                                        : null
                                }
                            />
                        </div>
                    </div>
                ))}
            </StatisticsRow>
        </StatisticsBar>
    );
}
