import { Button, Modal } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import { Trash } from "@phosphor-icons/react";
import LoadableButton from "./LoadableButton";

const ActivityDeleteDialog = ({ activity, onDelete, setOpen, isOpen }) => {
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });

    function cancel() {
        setOpen(false);
    }

    function deleteActivity() {
        if (activity.id) {
            callDeleteAPI("DELETE", `/api/activities/${activity.id}?hard=true`);
        } else {
            onDelete(activity);
        }
    }

    useEffect(() => {
        if (deleteAPI.response) {
            setOpen(false);
            onDelete(activity);
        }
    }, [deleteAPI.response]);

    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={isOpen}>
            <Modal.Header>{strings.activity_delete_header}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>{strings.formatString(strings.activity_delete_warning, activity.name_native)}</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={cancel} disabled={deleteAPI.loading}>
                    Cancel
                </Button>
                <LoadableButton
                    content={strings.delete}
                    icon={<Trash />}
                    onClick={deleteActivity}
                    loading={deleteAPI.loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ActivityDeleteDialog;
