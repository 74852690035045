import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";

export const ModalInput = ({ label, trigger, input, onSave, saving, saveComplete }) => {
    const [open, setOpen] = useState(false);

    function close(save) {
        save && onSave && onSave();

        if (!save) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (saveComplete) {
            setOpen(false);
        }
    }, [saveComplete]);

    return (
        <Modal trigger={trigger} open={open} onClose={() => close(false)} onOpen={() => setOpen(true)} size="tiny">
            <Modal.Header>
                {strings.edit} {label}
            </Modal.Header>
            <Modal.Content>{input}</Modal.Content>
            <Modal.Actions>
                <Button onClick={() => close(false)} disabled={saving}>
                    {strings.cancel}
                </Button>
                <Button
                    onClick={() => close(true)}
                    disabled={saving}
                    content={saving ? strings.saving : strings.save}
                    icon="check"
                    primary
                />
            </Modal.Actions>
        </Modal>
    );
};
