import emojiRegex from "emoji-regex";
import React from "react";

export function replaceEmojiWithSpan(text, color = false) {
    /* Turn the text into HTML where each emoji is wrapped in a span */

    return (
        text &&
        text.replace &&
        text.replace(emojiRegex(), (match) => {
            return `<img class="emoji" src="/noto-svg/u${match.codePointAt(0).toString(16)}.svg" alt="${match}" />`;
        })
    );
}

export function toCoolEmojis(text, color = true) {
    return React.createElement("span", {
        dangerouslySetInnerHTML: { __html: replaceEmojiWithSpan(text, color) },
    });
}
