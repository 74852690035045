import { ArrowClockwise, Barbell, CaretDown, ListBullets, Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Button, Dropdown, Segment } from "semantic-ui-react";
import EditableText from "../components/EditableText";
import LoadableButton from "../components/LoadableButton";
import { TextEditor } from "../components/TextEditor";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import { useLanguage } from "../hooks/useLanguage";
import { useQuery } from "../hooks/useQuery";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import "./Note.css";
import { ActivitySkills } from "../components/ActivityDetails";
import ButtonBar from "../components/ButtonBar";
import { isMobile } from "../utils/platform.utils";

const SelectActivityCollectionDropdown = ({ selectedActivityCollectionId, setActivityCollection }) => {
    const { language, nativeLanguage } = useLanguage();
    const [activityCollectionsAPI, callActivityCollectionsAPI] = useAPI();
    const [selectedActivityCollection, setSelectedActivityCollection] = useState();

    useEffect(() => {
        callActivityCollectionsAPI(
            "GET",
            `/api/activity_collections?language=${language}&native_language=${nativeLanguage}`
        );
    }, [language]);

    useEffect(() => {
        if (activityCollectionsAPI.response) {
            const selected = activityCollectionsAPI.response.find((c) => c.id === selectedActivityCollectionId);
            setSelectedActivityCollection(selected);
        }
    }, [activityCollectionsAPI.response, selectedActivityCollectionId]);

    if (activityCollectionsAPI.loading) {
        return <Button disabled={true} content={strings.loading} loading={true} />;
    }

    return (
        <Dropdown
            trigger={
                <Button
                    compact
                    icon={<CaretDown />}
                    content={selectedActivityCollection ? selectedActivityCollection.name : "Select list..."}
                />
            }
            icon={null}
            options={activityCollectionsAPI.response
                ?.filter((collection) => collection.tag !== "anytime")
                .map((collection) => ({
                    key: collection.id,
                    text: collection.name_native ? collection.name_native : collection.name,
                    value: collection.id,
                }))}
            onChange={(e, { value }) => {
                setActivityCollection(activityCollectionsAPI.response.find((c) => c.id === value));
            }}
            value={selectedActivityCollectionId}
        />
    );
};

const NoteToolbar = ({ note, activity, setNote, setActivity }) => {
    const history = useHistory();
    const { language, nativeLanguage } = useLanguage();
    const [taskResult] = useTask(activity?.task_id);
    const [updateActivityAPI, callUpdateActivityAPI] = useAPI({ loading: false });
    const [deleteNoteAPI, callDeleteNoteAPI] = useAPI({ loading: false });

    useEffect(() => {
        if (taskResult?.result) {
            setActivity(taskResult.result);
            setNote(taskResult.result.note);
        }
    }, [taskResult]);

    function updateActivityCollection(collection) {
        setActivity({ ...activity, activity_collection_id: collection.id });
        callUpdateActivityAPI("PUT", `/api/activities/${activity.id}?native_language=${nativeLanguage}`, {
            activity_collection_id: collection.id,
        });
    }

    function deleteNote() {
        callDeleteNoteAPI("DELETE", `/api/notes/${note.id}`).then(() => {
            history.push(`/notes`);
        });
    }

    return (
        <ButtonBar>
            <SelectActivityCollectionDropdown
                selectedActivityCollectionId={activity?.activity_collection_id}
                setActivityCollection={updateActivityCollection}
            />
            <Button
                compact
                content={isMobile.any() ? null : strings.activity_practice}
                icon={<Barbell />}
                onClick={() => history.push(`/languages/${language}/${nativeLanguage}/chat?activityId=${activity?.id}`)}
                disabled={!activity}
            />
            <Button
                compact
                icon={<ListBullets />}
                onClick={() => history.push(`/activities/${activity?.id}`)}
                disabled={!activity}
            />
            <Button compact icon={<Trash />} onClick={deleteNote} />
        </ButtonBar>
    );
};

const NoteAnalysis = ({ note, analyzeNote, analyzing, activity }) => {
    return (
        <Segment>
            <p>
                <i>{strings.notes_skills_and_vocabulary}</i>
            </p>
            {activity?.task_id && <p>{strings.notes_analyzing}</p>}

            {activity && !activity.task_id && activity.skills?.length > 0 ? (
                <ActivitySkills skills={activity.skills} />
            ) : (
                <p>{strings.notes_analyze_empty_state}</p>
            )}
            <ButtonBar>
                <LoadableButton
                    icon={<ArrowClockwise />}
                    content={strings.analyze}
                    onClick={analyzeNote}
                    disabled={analyzing || activity?.task_id}
                    compact
                    size={"tiny"}
                />
            </ButtonBar>
        </Segment>
    );
};

const Note = ({ note: initialNote }) => {
    const [note, setNote] = useState(initialNote);
    const [activity, setActivity] = useState();
    const { setPageTitle, setNavigationTitle } = useTitle();
    const [updateNoteAPI, callUpdateNoteAPI] = useAPI({ loading: false });
    const [analyzeNoteAPI, callAnalyzeNoteAPI] = useAPI({ loading: false });
    const { nativeLanguage } = useLanguage();

    useEffect(() => {
        setNavigationTitle(
            <EditableText text={note.title || "New note"} onChange={(value) => setNote({ ...note, title: value })} />
        );
    }, []);

    useEffect(() => {
        setPageTitle(note.title);
    }, [note.title]);

    useEffect(() => {
        if (note.activity) {
            setActivity(note.activity);
        }
    }, [note.activity]);

    useEffect(() => {
        if (note === initialNote || analyzeNoteAPI.loading) {
            return;
        }

        const debounce = setTimeout(() => {
            callUpdateNoteAPI("PUT", `/api/notes/${note.id}?native_language=${nativeLanguage}`, note);
        }, 1000);
        return () => clearTimeout(debounce);
    }, [note]);

    function analyzeNote() {
        callAnalyzeNoteAPI("PUT", `/api/notes/${note.id}?native_language=${nativeLanguage}&analyze=true`, note);
    }

    useEffect(() => {
        if (analyzeNoteAPI.response) {
            setNote(analyzeNoteAPI.response);
            setActivity(analyzeNoteAPI.response.activity);
        }
    }, [analyzeNoteAPI.response]);

    return (
        <div className="note">
            <NoteToolbar activity={activity} note={note} setActivity={setActivity} setNote={setNote} />

            <TextEditor
                key={note.id}
                placeholder={"Write your note here..."}
                onChange={(value) => {
                    if (value === note.text) {
                        return;
                    }
                    setNote({ ...note, text: value });
                }}
                initialText={note.text}
                style={{ height: "100%" }}
            />
            <NoteAnalysis
                activity={activity}
                analyzeNote={analyzeNote}
                note={note}
                analyzing={analyzeNoteAPI.loading}
            />
        </div>
    );
};

const NoteScreen = () => {
    const [noteAPI, callNoteAPI] = useAPI();
    const { noteId } = useParams();
    const { language, nativeLanguage } = useLanguage();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        if (noteId) {
            callNoteAPI("GET", `/api/notes/${noteId}?native_language=${nativeLanguage}`);
        } else {
            callNoteAPI("POST", `/api/notes?native_language=${nativeLanguage}`, {
                language: language,
                text: "",
                activity_collection_id: query.get("activityCollectionId"),
            });
        }
    }, [noteId]);

    useEffect(() => {
        if (noteAPI.response && !noteId) {
            // Update the URL to reflect the new note ID without reloading the page
            history.replace(`/notes/${noteAPI.response.id}`);
        }
    }, [noteAPI.response]);

    if (noteAPI.loading) {
        return <ZataLoader />;
    }

    if (noteAPI.response && noteId) {
        return <Note note={noteAPI.response} />;
    }
};

export default NoteScreen;
