import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Message } from "semantic-ui-react";

import { createPortal } from "react-dom";

export const UpdateApp = () => {
    const history = useHistory();

    const [updateRegistration, setUpdateRegistration] = useState(null);

    useEffect(() => {
        history.listen((location, action) => {
            // check for sw updates on page change
            navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
        });
    }, []);

    function updateApp() {
        if (updateRegistration.waiting) {
            // let waiting Service Worker know it should became active
            updateRegistration.waiting.postMessage("SKIP_WAITING");
        }
    }

    useEffect(() => {
        // check if the browser supports serviceWorker at all
        if ("serviceWorker" in navigator) {
            // wait for the page to load
            window.addEventListener("load", async () => {
                // register the service worker from the file specified
                const registration = await navigator.serviceWorker.register("/serviceWorker.js");

                // ensure the case when the updatefound event was missed is also handled
                // by re-invoking the prompt when there's a waiting Service Worker
                if (registration.waiting) {
                    // disabling this so we don't get a refresh on first load - DD
                    // setUpdateRegistration(registration);
                }

                // detect Service Worker update available and wait for it to become installed
                registration.addEventListener("updatefound", () => {
                    if (registration.installing) {
                        // wait until the new Service worker is actually installed (ready to take over)
                        registration.installing.addEventListener("statechange", () => {
                            if (registration.waiting) {
                                // if there's an existing controller (previous Service Worker), show the prompt
                                if (navigator.serviceWorker.controller) {
                                    setUpdateRegistration(registration);
                                } else {
                                    // otherwise it's the first install, nothing to do
                                    console.log("Service Worker initialized for the first time");
                                }
                            }
                        });
                    }
                });

                // detect controller change and refresh the page
                navigator.serviceWorker.addEventListener("controllerchange", () => {
                    window.location.reload();
                });
            });
        }
    }, []);

    if (!updateRegistration) return null;

    return createPortal(
        <Container className="notifications">
            <Message>
                <p>A new version of Jetway is available.</p>
                <div style={{ textAlign: "right" }}>
                    <Button primary onClick={updateApp}>
                        Update Now
                    </Button>
                </div>
            </Message>
        </Container>,
        document.getElementById("topbar")
    );
};
