import { useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";

const languages = {
    de: "german",
    es: "spanish",
    en: "english",
    fr: "french",
    it: "italian",
};

export const Youglish = ({ word, language }) => {
    const widgetRef = useRef();

    const onYouglishAPIReady = () => {
        let widget = new window.YG.Widget("widget-1", {
            width: 640,
            components: 9, //search box & caption
            events: {
                /*'onFetchDone': onFetchDone,
                'onVideoChange': onVideoChange,
                'onCaptionConsumed': onCaptionConsumed*/
            },
        });
        // 4. process the query
        widget.fetch(word, languages[language]);
        widgetRef.current = widget;
    };

    useEffect(() => {
        var tag = document.createElement("script");

        tag.src = "https://youglish.com/public/emb/widget.js";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouglishAPIReady = onYouglishAPIReady;
    });

    function next() {
        widgetRef.current.next();
    }

    function pause() {
        widgetRef.current.pause();
    }

    function previous() {
        widgetRef.current.previous();
    }

    return (
        <div>
            <div id="widget-1" />
            <center>
                <Button onClick={previous} icon="step backward"></Button>
                <Button onClick={pause} icon="pause"></Button>
                <Button onClick={next} icon="step forward"></Button>
            </center>
        </div>
    );
};

export const youglishUrl = (language, word) => {
    return `https://youglish.com/pronounce/${word}/${languages[language]}`;
};

export const YouglishButton = ({ word, language, style }) => {
    return (
        <Button
            onClick={() => window.open(`https://youglish.com/pronounce/${word}/${languages[language]}`)}
            style={style}>
            {strings.youglish_view}
        </Button>
    );
};
