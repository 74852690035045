const getAvgHex = (color, total) =>
    Math.round(color / total)
        .toString(16)
        .padStart(2, 0);

const avgColor = (emoji) => {
    let totalPixels = 0;
    const colors = {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 0,
    };
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = "30px Arial";
    ctx.fillText(emoji, 0, 28);
    const { data: imageData } = ctx.getImageData(0, 0, 30, 30);
    for (let i = 0; i < imageData.length; i += 4) {
        let [r, g, b, a] = imageData.slice(i, i + 4);
        if (a > 50) {
            totalPixels += 1;
            colors.red += r;
            colors.green += g;
            colors.blue += b;
            colors.alpha += a;
        }
    }
    const r = getAvgHex(colors.red, totalPixels);
    const g = getAvgHex(colors.green, totalPixels);
    const b = getAvgHex(colors.blue, totalPixels);

    return "#" + r + g + b;
};

const BACKGROUND_COLORS = ["e3f2ff"];

export const bestBackgroundColor = (emoji) => {
    // Find the best background color given the primary color from the emoji
    // and the colors we have available.
    const primary = avgColor(emoji).substr(1);

    const primaryHue = parseInt(primary, 16) / 0xfffff;
    const best = BACKGROUND_COLORS.reduce(
        (best, color) => {
            const contrastHue = parseInt(color, 16) / 0xfffff;
            const diff = Math.abs(contrastHue - primaryHue);
            if (diff < best.diff) {
                return { diff, color };
            }
            return best;
        },
        { diff: 1, color: BACKGROUND_COLORS[0] }
    ).color;

    return "#" + BACKGROUND_COLORS[0];
};
