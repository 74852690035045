import React, { useEffect, useRef, useState } from "react";
import useAPI from "../hooks/useAPI";
import Phrase from "./Phrase";
import { ZataLoader } from "./ZataLoader";
import { Popup } from "semantic-ui-react";
import { useEventListener } from "../hooks/useEventListener";
import { useLanguage } from "../hooks/useLanguage";

export const LazyPhrase = ({ text, activityId, hideWords, wordsUnhidden }) => {
    const [phrase, setPhrase] = useState(null);
    const [words, setWords] = useState(null);
    const [loadPhraseAPI, callLoadPhraseAPI] = useAPI();
    const [openWord, setOpenWord] = useState(false);
    const [position, setPosition] = useState("bottom center");
    const { language, nativeLanguage } = useLanguage();
    const [showWords, setShowWords] = useState(false);

    const ref = useRef(null);

    useEffect(updatePosition, [ref]);
    useEventListener("resize", updatePosition, window.visualViewport);

    useEffect(() => {
        // Split the text based on spaces and punctuation into an array where each member is like this:
        // { text: "word", start: 0, end: 4, punctuation: false}
        const parsedWords = [];
        const wordRegex = /[\w\u00C0-\u00FF]+/g;
        let match;
        while ((match = wordRegex.exec(text))) {
            parsedWords.push({
                text: match[0],
                start: match.index,
                end: match.index + match[0].length,
                punctuation: false,
            });
        }

        // split the text based on punctuation
        const punctuationRegex = /[^\w\u00C0-\u00FF]+/g;
        let punctuationMatch;
        while ((punctuationMatch = punctuationRegex.exec(text))) {
            parsedWords.push({
                text: punctuationMatch[0],
                start: punctuationMatch.index,
                end: punctuationMatch.index + punctuationMatch[0].length,
                punctuation: true,
            });
        }

        parsedWords.sort((a, b) => a.start - b.start);

        // calculate spaces between words
        parsedWords.forEach((word, index) => {
            if (index > 0) {
                const previousWord = parsedWords[index - 1];
                word.spaces = word.start - previousWord.end;
            }
        });

        setWords(parsedWords);
    }, [text]);

    function loadPhrase() {
        callLoadPhraseAPI("POST", `/api/phrases`, {
            phrases: [text],
            language: language,
            native_language: nativeLanguage,
            translate_immediately: true,
            source: "help",
        });
    }

    useEffect(() => {
        if (loadPhraseAPI.response) {
            setPhrase(loadPhraseAPI.response[0]);
        }
    }, [loadPhraseAPI.response]);

    function updatePosition() {
        if (!ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        // if within 100 px of bottom of screen use top, otherwise bottom
        const topOrBottom = window.visualViewport.height - rect.bottom < 100 ? "top" : "bottom";
        if (rect.left < 100) {
            setPosition(topOrBottom + " right");
        }
        if (rect.right < 100) {
            setPosition(topOrBottom + " left");
        } else {
            setPosition(topOrBottom + " center");
        }
    }

    function spaces(word) {
        if (word.spaces) {
            return " ".repeat(word.spaces);
        } else {
            return "";
        }
    }

    function blank(word) {
        return React.createElement("span", {
            dangerouslySetInnerHTML: { __html: "&nbsp;".repeat(word.length) },
        });
    }

    useEffect(() => {
        showWords && wordsUnhidden && wordsUnhidden(text);
    }, [showWords]);

    if (!words) {
        return text;
    }

    if (!phrase) {
        return (
            <span className="Phrase" ref={ref}>
                {words.map((word) => {
                    if (word.punctuation) {
                        return word.text;
                    } else {
                        return (
                            <Popup
                                pinned
                                on={"click"}
                                open={openWord === word}
                                onOpen={(e) => {
                                    if (!hideWords || showWords) {
                                        setOpenWord(word);
                                        loadPhrase();
                                    }
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onClose={() => setOpenWord(null)}
                                position={position}
                                trigger={
                                    <span>
                                        {spaces(word)}
                                        <span
                                            className={!word.punctuation ? "Word" : null}
                                            onClick={() => setShowWords(true)}>
                                            {showWords || !hideWords ? word.text : blank(word.text)}
                                        </span>
                                    </span>
                                }
                                content={<ZataLoader />}
                            />
                        );
                    }
                })}
            </span>
        );
    }

    return (
        <Phrase
            phrase={phrase}
            alignments={phrase.alignments}
            translations={phrase.translations}
            translationLanguage={nativeLanguage}
            inline={true}
            openWord={
                phrase.words.filter(
                    (word) => openWord.start >= word.offset && openWord.start <= word.offset + word.text.length
                )[0]
            }
        />
    );
};
