import { Segment } from "semantic-ui-react";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";
import Banner from "../components/Banner";

function Teachers() {
    useTitle("For teachers", null, strings.product_description);

    return (
        <div>
            <Banner
                title="Create activities for you students with one-click"
                subtitle="Jetway generates personalized activities for your students to practice speaking, listening, and writing about any topic."
            />
            <Segment>
                <h3>1. Choose a topic</h3>
                <p>
                    Jetway can create activities for any topic, from the past tense to ordering tacos to the difference
                    between "ser y estar" in Spanish.
                </p>
            </Segment>
            <Segment>
                <h3>2. Create your activities</h3>
                <p>
                    Jetway builds a set of language skills that underpin the exercises and discussions. If they're not
                    quite what you want to focus on, adjust your topic. If they're too hard or easy, adjust the
                    difficulty level.
                </p>
                <Screenshot src="/product/teachers/create.png" alt="Create your activities" />
            </Segment>
            <Segment>
                <h3>3. Share with others</h3>
                <p>
                    Click the share button to copy a link to the activities to your clipboard. Share this link with one
                    or more students
                </p>
                <Screenshot src="/product/teachers/share.png" alt="Share your activities" />
            </Segment>
        </div>
    );
}

export default Teachers;

const Screenshot = ({ src, alt }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="screenshot" style={{ maxWidth: "80%" }}>
                <img src={src} alt={alt} style={{ width: "100%" }} />
            </div>
        </div>
    );
};
