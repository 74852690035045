import React, { useState } from "react";

export const TitleProviderContext = React.createContext({
    navigationTitle: null,
    setNavigationTitle: () => null,
    image: null,
    setImage: () => null,
    backPath: null,
    setBackPath: () => null,
    pageTitle: null,
    setPageTitle: () => null,
    description: null,
    setDescription: () => null,
});

export default function TitleProvider({ children }) {
    const [navigationTitle, setNavigationTitle] = useState();
    const [pageTitle, setPageTitle] = useState();
    const [image, setImage] = useState();
    const [backPath, setBackPath] = useState(null);
    const [description, setDescription] = useState([]);

    const contextValue = {
        navigationTitle,
        setNavigationTitle,
        image,
        setImage,
        backPath,
        setBackPath,
        pageTitle,
        setPageTitle,
        description,
        setDescription,
    };

    return <TitleProviderContext.Provider value={contextValue}>{children}</TitleProviderContext.Provider>;
}
